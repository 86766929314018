import React, {memo} from 'react';
import {Source, Layer} from 'react-map-gl';
import {useSelector} from 'react-redux';

import {
  getSelectionMarkerSettings,
  getSelectionMarkerSourceSettings,
} from '@/selectors';

function LayerCustomMarker() {
  const markerSourceSettings = useSelector(getSelectionMarkerSourceSettings);
  const makerLayerSettings = useSelector(getSelectionMarkerSettings);

  return (
    <Source {...markerSourceSettings}>
      <Layer {...makerLayerSettings} />
    </Source>
  );
}

export default memo(LayerCustomMarker);
