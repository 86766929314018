import PropTypes from 'prop-types';
import {Auth0Provider} from '@auth0/auth0-react';

import {AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE} from '@config';

import {useQuery} from '@/hooks/useQuery';
import {useRouter} from '@/hooks/useRouter';

function AuthProvider({children}) {
  const query = useQuery();
  const {pushRoute} = useRouter();

  const queryParams = {
    invitation: query.get('invitation'),
    organization: query.get('organization'),
  };

  const onRedirectCallback = (appState) => {
    pushRoute(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        ...(queryParams.invitation && {invitation: queryParams.invitation}),
        ...(queryParams.organization && {
          organization: queryParams.organization,
        }),
      }}>
      {children}
    </Auth0Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
