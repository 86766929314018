import {convertDistance, getBrowserLanguage} from '@utils/utils';
import {
  SET_USER,
  SET_NEW_LOCATION_TO_SAVE,
  TOGGLE_LOCATIONS_POPUP_STATUS,
  TOGGLE_ADDING_LOCATION_STATUS,
  SET_USER_MAP_STYLE,
  SET_USER_LANGUAGE,
  SET_INSPECTING_LOCATION_STATUS,
  SET_SAVING_LOCATION_STATUS,
  SET_SELECTED_LOCATION,
  SET_CLICKED_LOCATION_ID,
  SET_USER_LOADING,
  SET_THREAT_ANALYTICS_AREA,
  SET_UNITS,
  SET_USER_SETTINGS,
} from '@/store/modules/user/actionTypes';
import {RESET_STORE} from '@/store/modules/actionTypes';
import {THREAT_RADIUS_LIMITS} from '@/components/map/constants';

export const initialState = {
  // merge
  settings: {
    mapStyle: 'map',
    language: getBrowserLanguage(),
    isLoading: true,
    analyticCharts: [
      {
        id: 'chart-event-summary',
        metric: 'count',
      },
      {
        id: 'chart-event-breakdown',
        metric: 'all',
      },
      {
        id: 'chart-event-trend',
        metric: 'historical',
        period: 'monthly',
      },
      {
        id: 'chart-period-average',
        period: 'month',
      },
      {
        id: 'chart-change-trend',
        period: 'monthly',
        metric: 'periodOverPeriod',
        normalized: false,
        source: 5,
      },
      {
        id: 'chart-source-trend',
        period: 'monthly',
      },
      {
        id: 'chart-change-overview',
        period: 'monthly',
        metric: 'periodOverPeriod',
        normalized: false,
        source: 5,
      },
    ],
    myLocationsAnalysis: {
      change: {
        aggregation: 'yearly',
        period: 'yearOverYear',
      },
      listType: 'savedLocations',
      locationBreakdown: ['type'],
    },
  },
  profile: {
    id: null,
    authId: null,
    role: '',
    photo: '',
    firstName: '',
    lastName: '',
    email: '',
    units: 'mi',
  },
  // move to modal
  isPopupOpen: false,
  // move to map
  isAddingLocation: false,
  isInspectingLocation: false,
  isSavingLocation: false,
  newLocation: {},
  selectedLocation: {},
  clickedLocation: {},
  threatAnalyticsArea: {
    latitude: 0,
    longitude: 0,
    radius: convertDistance(THREAT_RADIUS_LIMITS.mi.main, 'm', 'mi'),
    display: false,
    shape: 'circle',
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SETTINGS: {
      return {
        ...state,
        settings: action.payload,
      };
    }
    case SET_UNITS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          units: action.payload,
        },
      };
    }
    case SET_USER_LOADING: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: action.payload,
        },
      };
    }
    case SET_THREAT_ANALYTICS_AREA: {
      return {
        ...state,
        threatAnalyticsArea: {
          ...state.threatAnalyticsArea,
          ...action.payload,
        },
      };
    }
    case SET_USER: {
      const {...profile} = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          ...profile,
        },
        settings: {
          ...action.payload.settings,
        },
        threatAnalyticsArea: {
          ...state.threatAnalyticsArea,
          radius:
            profile.units === state.profile.units
              ? state.threatAnalyticsArea.radius
              : convertDistance(
                  THREAT_RADIUS_LIMITS[profile.units].main,
                  'm',
                  profile.units,
                ),
        },
      };
    }
    case TOGGLE_LOCATIONS_POPUP_STATUS:
      return {
        ...state,
        isPopupOpen: !!action.payload,
      };
    case SET_NEW_LOCATION_TO_SAVE:
      return {
        ...state,
        newLocation: action.payload || {},
      };
    case TOGGLE_ADDING_LOCATION_STATUS:
      return {
        ...state,
        isAddingLocation: !!action.payload,
      };
    case SET_USER_MAP_STYLE:
      return {
        ...state,
        settings: {
          ...state.settings,
          mapStyle: action.payload,
        },
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        settings: {
          ...state.settings,
          language: action.payload,
        },
      };
    case SET_INSPECTING_LOCATION_STATUS:
      return {
        ...state,
        isInspectingLocation: !!action.payload,
      };
    case SET_SAVING_LOCATION_STATUS:
      return {
        ...state,
        isSavingLocation: !!action.payload,
      };
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload || {},
      };
    case SET_CLICKED_LOCATION_ID:
      return {
        ...state,
        clickedLocation: action.payload || {},
      };
    case RESET_STORE:
      return {
        ...state,
        ...initialState,
        profile: state.profile,
        settings: state.settings,
        savedLocations: state.savedLocations,
      };
    default:
      return state;
  }
};

export default userReducer;
