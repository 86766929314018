import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {useMemo} from 'react';
import {DownloadSimple} from '@phosphor-icons/react';
import {useTranslation} from '@/hooks/useTranslation';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import BaseDonutChart from '@/components/common/charts/BaseDonutChart';
import BaseLegend from '@/components/common/charts/BaseLegend';
import {styles} from '@/components/saved-locations/charts/BreakdownByLocation.styles';
import BaseCard from '@/components/common/cards/BaseCard';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import {chartIds} from '@/components/saved-locations/constants';
import {useLocationsBreakdown} from '@/hooks/charts/useLocationsBreakdown';
import {CHART_DISPLAY_OPTIONS} from '@/hooks/constants';

function BreakdownByLocation({option}) {
  const {getI18N} = useTranslation();
  const {copyImageLabel, downloadImageLabel} = getI18N('chartOptions');
  const {breakdownByLocationType, breakdownByLocationTag} =
    getI18N('savedLocations');
  const chartId =
    option === 'tag' ? chartIds.breakdownByTag : chartIds.breakdownByType;

  const title = useMemo(
    () =>
      option === CHART_DISPLAY_OPTIONS.TYPE
        ? breakdownByLocationType
        : breakdownByLocationTag,
    [option],
  );

  const {copyImage, downloadImage} = useChartDownloads({id: chartId, title});
  const {data, legend, colorAccessor, isFetching} = useLocationsBreakdown({
    option,
  });

  return (
    <BaseCard
      id={chartId}
      title={title}
      headerActions={
        <ChartOptionsDropdown
          icon={DownloadSimple}
          options={[
            {
              label: copyImageLabel,
              action: copyImage,
            },
            {
              label: downloadImageLabel,
              action: downloadImage,
            },
          ]}
        />
      }>
      <Box sx={styles.innerBox}>
        <Box>
          <BaseDonutChart
            data={{[title]: data}}
            colorAccessor={colorAccessor}
            height={300}
            isLoading={isFetching}
          />
        </Box>
        <Box sx={styles.legendBox}>
          <BaseLegend
            sx={styles.legend}
            isLoading={isFetching}
            labels={legend}
          />
        </Box>
      </Box>
    </BaseCard>
  );
}

BreakdownByLocation.propTypes = {
  option: PropTypes.oneOf(Object.values(CHART_DISPLAY_OPTIONS)).isRequired,
};

export default BreakdownByLocation;
