export const getWeightByQuantity = (eventCount) => {
  const expression = ['interpolate', ['linear'], ['zoom']];
  let weight = 3;
  let delta = 2;

  if (eventCount > 15000) {
    delta = 2.1;
  } else if (eventCount > 9000) {
    delta = 2.2;
  } else if (eventCount > 4000) {
    delta = 2.4;
  } else if (eventCount > 1000) {
    delta = 2.8;
  } else if (eventCount > 200) {
    delta = 3.3;
  } else if (eventCount > 50) {
    delta = 4;
  } else if (eventCount > 10) {
    delta = 4.8;
  } else if (eventCount > 1) {
    delta = 6;
  }

  for (let zoom = 9; zoom < 16; zoom += 1) {
    expression.push(zoom, Number(weight.toFixed(2)));
    weight += delta;
  }

  return expression;
};

export const getRadiusByQuantity = (eventCount) => {
  const expression = ['interpolate', ['linear'], ['get', 'w']];
  let radius = 0.1;
  let delta = 0.1;

  if (eventCount > 15000) {
    delta = 0.2;
  } else if (eventCount > 9000) {
    delta = 0.3;
  } else if (eventCount > 4000) {
    delta = 0.4;
  } else if (eventCount > 1000) {
    delta = 0.6;
  } else if (eventCount > 200) {
    delta = 1;
  } else if (eventCount > 50) {
    delta = 1.8;
  } else if (eventCount > 10) {
    delta = 2.4;
  } else if (eventCount > 1) {
    delta = 3;
  }

  for (let severity = 1; severity < 11; severity += 1) {
    expression.push(severity, Number(radius.toFixed(2)));
    radius += delta;
  }

  return expression;
};
