import {useSelector} from 'react-redux';

import {getSummaries} from '@/selectors';
import {
  API_COUNTRY_CATEGORIES,
  API_COUNTRY_QUINTILES,
  API_COUNTRY_SCORES,
} from '@/hooks/api/constants';
import {useApi} from '@/hooks/api/useApi';

export const useCountry = () => {
  const {useGetQuery} = useApi();
  const {countrySummary} = useSelector(getSummaries);

  const {data: countryCategories, isFetching: isFetchingCategories} =
    useGetQuery({
      path: API_COUNTRY_CATEGORIES,
      config: {
        enabled: true,
      },
    });

  const {data: countryScore, isFetching: isFetchingCountryScore} = useGetQuery({
    path: API_COUNTRY_SCORES.replace('{id}', countrySummary?.id),
    config: {
      enabled: Boolean(countrySummary),
    },
  });

  const {data: countryQuintiles, isFetching: isFetchingCountryQuintiles} =
    useGetQuery({
      path: API_COUNTRY_QUINTILES.replace('{id}', countrySummary?.id),
      config: {
        enabled: Boolean(countrySummary),
      },
    });

  return {
    countryCategories,
    isFetchingCategories,
    countrySummary,
    countryScore,
    isFetchingCountryScore,
    countryQuintiles,
    isFetchingCountryQuintiles,
  };
};
