import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setTiles} from '@/store/modules/map/actions';

import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {setViewportBoundingBox} from '@/store/modules/filters/actions';

import {getDebugMode} from '@/selectors';

export const useMapDrag = () => {
  const dispatch = useDispatch();
  const {getTilesInViewPort} = useMapboxGL();
  const debugMode = useSelector(getDebugMode);

  const handleDrag = useCallback(() => {
    if (debugMode) {
      dispatch(setTiles(getTilesInViewPort()));
    }
  }, [dispatch, debugMode, getTilesInViewPort]);

  const handleDragEnd = useCallback(
    (event) => {
      const map = event.target;
      dispatch(setViewportBoundingBox(map.getBounds()));
    },
    [dispatch],
  );

  return {handleDrag, handleDragEnd};
};
