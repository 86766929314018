import {useDispatch} from 'react-redux';
import {setUser, setUserSettings} from '@store/modules/user/actions';
import {useHttpServices} from '@hooks/useHttpServices';
import {
  API_USER,
  API_SAVED_LOCATIONS,
  API_USER_UPLOAD_PHOTO,
  API_KEY,
  API_SAVED_LOCATIONS_REPORT,
  API_REPORTS,
  API_SAVED_LOCATIONS_TAGS,
  API_USER_SETTINGS,
  API_LOCATION,
} from '@hooks/api/constants';
import {
  setSavedLocations,
  setTags,
} from '@/store/modules/saved-locations/actions';

export const useApi = () => {
  const dispatch = useDispatch();
  const {useGet, usePost, usePatch, usePut, useDelete, useGetQuery} =
    useHttpServices();

  const getLocation = useGet({
    path: API_LOCATION,
  });

  const patchUserSettings = usePatch({
    path: API_USER_SETTINGS,
    config: {
      onSuccess: (settings) => dispatch(setUserSettings(settings)),
    },
  });

  const patchUser = usePatch({
    path: API_USER,
    config: {
      onSuccess: (user) => dispatch(setUser(user)),
    },
  });

  const getSavedLocations = useGet({
    path: API_SAVED_LOCATIONS,
    config: {
      onSuccess: (locations) => dispatch(setSavedLocations(locations.data)),
    },
  });

  const getSavedLocation = useGet({
    path: API_SAVED_LOCATIONS,
  });

  const postUserLocation = usePost({
    path: API_SAVED_LOCATIONS,
  });

  const postUserPhoto = usePost({
    path: API_USER_UPLOAD_PHOTO,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const putSavedLocation = usePut({
    path: API_SAVED_LOCATIONS,
  });

  const deleteUserLocation = useDelete({
    path: API_SAVED_LOCATIONS,
  });

  const getChartDownloads = useGet({
    headers: {
      Accept: 'text/csv',
    },
  });

  const postAPIKey = usePost({
    path: API_KEY,
  });

  const deleteAPIKey = useDelete({
    path: API_KEY,
  });

  const getSavedLocationsReport = useGet({
    path: API_SAVED_LOCATIONS_REPORT,
    headers: {
      accept: 'text/csv',
    },
  });

  const postReport = usePost({
    path: API_REPORTS,
  });

  const getTags = useGet({
    path: API_SAVED_LOCATIONS_TAGS,
    config: {
      onSuccess: (tags) => dispatch(setTags(tags)),
    },
  });

  return {
    getLocation,
    getTags,
    postReport,
    postAPIKey,
    deleteAPIKey,
    patchUser,
    getSavedLocations,
    postUserLocation,
    postUserPhoto,
    putSavedLocation,
    deleteUserLocation,
    getSavedLocation,
    getChartDownloads,
    getSavedLocationsReport,
    patchUserSettings,
    useGetQuery,
  };
};
