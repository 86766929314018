import {
  SWITCH_SIDE_NAV_STATUS,
  SET_ANALYTICS_NAV_STATUS,
  SET_ANALYTICS_HEIGHT,
  SET_ANALYTICS_WIDTH,
  SET_SAVED_LOCATIONS_FILTERS_STATUS,
  SET_MY_LOCATIONS_ANALYSIS_FILTERS_STATUS,
} from '@/store/modules/drawer/actionTypes';
import {RESET_STORE} from '@/store/modules/actionTypes';

export const initialState = {
  filtersOpen: false,
  analyticsOpen: false,
  analyticsWidth: '100vw',
  analyticsHeight: '50vh',
  savedLocationsFiltersOpen: false,
  isMyLocationsAnalysisFiltersOpen: false,
};

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SAVED_LOCATIONS_FILTERS_STATUS:
      return {
        ...state,
        savedLocationsFiltersOpen:
          action.payload !== undefined
            ? action.payload
            : !state.savedLocationsFiltersOpen,
      };
    case SET_MY_LOCATIONS_ANALYSIS_FILTERS_STATUS:
      return {
        ...state,
        isMyLocationsAnalysisFiltersOpen:
          action.payload !== undefined
            ? action.payload
            : !state.isMyLocationsAnalysisFiltersOpen,
      };
    case SET_ANALYTICS_NAV_STATUS:
      return {
        ...state,
        analyticsOpen:
          action.payload !== undefined ? action.payload : !state.analyticsOpen,
      };
    case SWITCH_SIDE_NAV_STATUS:
      return {
        ...state,
        filtersOpen:
          action.payload !== undefined ? action.payload : !state.filtersOpen,
      };
    case SET_ANALYTICS_HEIGHT: {
      return {
        ...state,
        analyticsHeight: action.payload,
      };
    }
    case SET_ANALYTICS_WIDTH: {
      return {
        ...state,
        analyticsWidth: action.payload,
      };
    }
    case RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default drawerReducer;
