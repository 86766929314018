import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {sankey} from 'd3-sankey';
import {LinkHorizontal, BarRounded} from '@visx/shape';
import {Text} from '@visx/text';
import {ParentSize} from '@visx/responsive';
import {useTheme} from '@mui/material';
import BaseSkeleton from '@common/BaseSkeleton';
import {useChartTheme} from '@/hooks/charts/useChartTheme';

const numberFormat = Intl.NumberFormat('en', {notation: 'compact'});

// Constants
const marginY = 8;
const marginX = 0;
const nodeWidth = 8;

// D3 Sankey Layout
const layout = sankey()
  .nodeId((d) => d.name)
  .nodeWidth(nodeWidth)
  .nodePadding(nodeWidth + 4)
  .nodeSort(null);

// Resizable Exported Chart Component
function SankeyChart({
  showBackground,
  height = 400,
  width,
  data = {nodes: [], links: []},
  isLoading,
}) {
  return (
    <ParentSize>
      {(parent) => {
        const w = width || parent.width;
        const h = height || parent.height;
        if (isLoading)
          return (
            <BaseSkeleton
              width={`${w.toString()}px`}
              height={`${h.toString()}px`}
            />
          );
        if (!data?.nodes?.length || !data?.links?.length) return null;
        return (
          <SankeyChartCore
            showBackground={showBackground}
            width={w}
            height={h}
            data={data}
          />
        );
      }}
    </ParentSize>
  );
}

// Core Chart Component
function SankeyChartCore({showBackground, height, width, data}) {
  const {nodes, links} = useMemo(
    () => ({
      nodes: data.nodes.map((n) => ({...n})),
      links: data.links.map((l) => ({...l})),
    }),
    [data.nodes],
  );

  const theme = useTheme();
  const {theme: chartTheme} = useChartTheme(nodes.length);
  const [selected, setSelected] = useState('');
  const [hovered, setHovered] = useState(null);
  // Compute graph layout
  const graph = useMemo(() => {
    const sortedNodes = [...nodes].sort(
      (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity),
    );

    return layout.extent([
      [marginX, marginY],
      [width - marginX, height - marginY],
    ])({
      nodes: sortedNodes,
      links: links.map((l) => ({...l, value: Number(l.value)})),
    });
  }, [height, width, nodes, links]);

  // Color nodes based on chart theme or node color
  const nodeColors = useMemo(() => {
    const sortedNodes = nodes.sort((a, b) => {
      if (a.depth === b.depth) return a.y0 - b.y0;
      return a.depth - b.depth;
    });

    return sortedNodes.reduce((colors, n, i) => {
      const nodeId = layout.nodeId()(n);
      if (n.color) {
        return {...colors, [nodeId]: n.color};
      }
      return {
        ...colors,
        [nodeId]: chartTheme.colors[i % chartTheme.colors.length],
      };
    }, {});
  }, [nodes]);

  // Get max depth of nodes
  const maxDepth = useMemo(
    () => Math.max(...graph.nodes.map((n) => n.depth)),
    [graph],
  );

  // Handle node selection
  const handleSelect = useCallback(
    (node) => {
      const id = layout.nodeId()(node);
      if (selected === id) setSelected('');
      else setSelected(id);
    },
    [selected],
  );

  // Array links connected to selected node
  const activeLinks = useMemo(() => {
    if (selected === '') return new Set();
    return new Set(
      graph.links.filter(
        (l) =>
          layout.nodeId()(l.source) === selected ||
          layout.nodeId()(l.target) === selected,
      ),
    );
  }, [selected, graph]);

  const getNodeText = (n) => {
    let txt = n.name;
    if (n.value) {
      txt += ` (${numberFormat.format(n.value)})`;
    }
    return txt;
  };

  const strokeOpacity = (link, hovered, activeLinks) => {
    if (activeLinks.has(link) || hovered === link.index) return 0.9;
    return 0.4;
  };

  return (
    <svg height={height} width={width}>
      {showBackground && (
        <rect
          width={width}
          height={height}
          fill={theme.palette.background.dark}
          rx={14}
        />
      )}
      <g>
        {graph.links.map((link) => (
          <LinkHorizontal
            key={`${layout.nodeId()(link.source)}-${layout.nodeId()(
              link.target,
            )}-${link.index}`}
            data={link}
            source={(l) => ({x: l.source.x1, y: l.y0})}
            target={(l) => ({x: l.target.x0, y: l.y1})}
            x={(n) => n.x}
            y={(n) => n.y}
            fill="none"
            onMouseEnter={() => setHovered(link.index)}
            onMouseLeave={() => setHovered(null)}
            stroke={nodeColors[layout.nodeId()(link.source)]}
            strokeWidth={link.width}
            strokeOpacity={strokeOpacity(link, hovered, activeLinks)}
          />
        ))}
      </g>
      <g>
        {graph.nodes.map((node) => {
          const nodeId = layout.nodeId()(node);
          return (
            <BarRounded
              key={nodeId}
              x={node.x0}
              height={node.y1 - node.y0 + nodeWidth}
              width={node.x1 - node.x0}
              y={node.y0 - nodeWidth / 2}
              onClick={() => handleSelect(node)}
              radius={nodeWidth / 2}
              fill={nodeColors[nodeId]}
              cursor="pointer"
              all
            />
          );
        })}
      </g>
      <g>
        {graph.nodes.map((node) => (
          <Text
            key={layout.nodeId()(node)}
            dx={node.depth === maxDepth ? node.x0 - 5 : node.x1 + 5}
            dy={node.y0 + (node.y1 - node.y0) / 2}
            verticalAnchor="middle"
            textAnchor={node.depth === maxDepth ? 'end' : 'start'}
            fontSize={chartTheme.svgLabelSmall.fontSize}
            fontWeight={chartTheme.svgLabelSmall.fontWeight}
            fill={theme.palette.text.primary}>
            {getNodeText(node)}
          </Text>
        ))}
      </g>
    </svg>
  );
}

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        order: PropTypes.number,
      }),
    ).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  showBackground: PropTypes.bool,
};

SankeyChartCore.propTypes = propTypes;
SankeyChart.propTypes = {
  isLoading: PropTypes.bool,
  ...propTypes,
};

export default SankeyChart;
