import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import {Star} from '@phosphor-icons/react';

import {
  getLocationFilterOptions,
  SAVED_LOCATION_TYPES,
} from '@saved-locations/constants';
import {LOCATION_TYPE_ICONS} from '@saved-locations/LocationTypeIcons';
import {styles} from '@saved-locations/SavedLocationModal.styles';
import {getModal, getSavedLocations, getUser} from '@/selectors';
import {convertDistance} from '@/utils/utils';

import BaseModal from '@/components/common/modals/BaseModal';
import BaseTextField from '@/components/common/inputs/BaseTextField';
import BaseButton from '@/components/common/buttons/BaseButton';
import BaseChipLabel from '@/components/common/inputs/BaseChipLabel';
import BaseAutocomplete from '@/components/common/inputs/BaseAutocomplete';
import BaseChipRadioGroup from '@/components/common/inputs/BaseChipRadioGroup';
import BaseSlider from '@/components/common/inputs/BaseSlider';
import BaseSwitch from '@/components/common/inputs/BaseSwitch';
import BaseInputLabel from '@/components/common/inputs/BaseInputLabel';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';

// Hooks
import {useMixpanel} from '@/hooks/useMixpanel';
import {useApi} from '@/hooks/api/useApi';
import {useSnackbar} from '@/hooks/useSnackbar';
import {useMUIForm} from '@/hooks/useMUIForm';
import {useFeatureAccess} from '@/hooks/useFeatureAccess';
import {useTranslation} from '@/hooks/useTranslation';
import {setThreatAnalyticsArea} from '@/store/modules/user/actions';
import {setRecentSearches} from '@/store/modules/modal/actions';
import {THREAT_RADIUS_LIMITS} from '@/components/map/constants';

function SavedLocationModal({
  open = false,
  savedLocation = {},
  defaultExpanded = true,
  handleClose = () => {},
  onSuccess = () => {},
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {getI18N, getSavedTranslations} = useTranslation();
  const {track} = useMixpanel();
  const {getFeatureAccess} = useFeatureAccess();
  const {showSnackbar} = useSnackbar();
  const {
    putSavedLocation,
    postUserLocation,
    deleteUserLocation,
    getTags: getUserTags,
  } = useApi();

  const [newTag, setNewTag] = useState('');

  const user = useSelector(getUser);
  const {tags: userTags} = useSelector(getSavedLocations);
  const {recentSearches} = useSelector(getModal);

  const {
    cancelButtonText,
    saveButton,
    deleteButton,
    locationNameLabel,
    displayAddressLabel,
    customerIdLabel,
    locationTypeLabel,
    latitudeLabel,
    longitudeLabel,
    locationTypePlaceholder,
    createdLocationLabel,
    updatedLocationLabel,
    deletedLocationLabel,
    collapseModalLabel,
    expandModalLabel,
    tagsLabel,
    priorityRatingLabel,
    addNewTag,
    visibilityOptions: visibilityOptionLabels,
    visibilityLabel,
    none: noneLabel,
  } = getI18N('savedLocations');

  const {tabRadius: radiusLabel} = getI18N('threatAnalytics');
  const [isExpanded, setIsExpanded] = useState(true);

  const isVisibilityDisabled = getFeatureAccess(
    'shared-saved-locations',
    'disable',
  );

  // Form Validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    latitude: Yup.number()
      .required('Latitude is required')
      .min(-90, 'Latitude must be between -90 and 90')
      .max(90, 'Latitude must be between -90 and 90'),
    longitude: Yup.number()
      .required('Longitude is required')
      .min(-180, 'Longitude must be between -180 and 180')
      .max(180, 'Longitude must be between -180 and 180'),
    radius: Yup.number()
      .required('Radius is required')
      .min(
        THREAT_RADIUS_LIMITS[user.units].min,
        `Radius must be between ${THREAT_RADIUS_LIMITS[user.units].min} and ${THREAT_RADIUS_LIMITS[user.units].max}`,
      )
      .max(
        THREAT_RADIUS_LIMITS[user.units].max,
        `Radius must be between ${THREAT_RADIUS_LIMITS[user.units].min} and ${THREAT_RADIUS_LIMITS[user.units].max}`,
      ),
    address: Yup.string().optional(),
    addressType: Yup.string().required('Address Type is required'),
    customerLocationId: Yup.string().optional(),
    isPrivate: Yup.boolean().required(),
    tags: Yup.array()
      .max(15, 'You’ve reached the maximum number of tags for one location')
      .optional()
      .test(
        'tags-length',
        'Each tag should be a maximum of 30 characters',
        (tags) => {
          if (!tags) return true;
          return tags.every((tag) => tag.length <= 30);
        },
      ),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    reset,
  } = useMUIForm(
    useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        title: '',
        latitude: '',
        longitude: '',
        address: '',
        addressType: 'other',
        customerRating: 0,
        customerLocationId: '',
        isPrivate: true,
        radius: 0,
        tags: [],
      },
    }),
  );

  const resetModalState = () =>
    reset({
      name: savedLocation?.name || '',
      latitude: savedLocation?.latitude || '',
      longitude: savedLocation?.longitude || '',
      address: savedLocation?.address || '',
      addressType: savedLocation?.addressType || 'other',
      customerRating: savedLocation?.customerRating || 0,
      customerLocationId: savedLocation?.customerLocationId || '',
      isPrivate: savedLocation?.isPrivate ?? true,
      tags: savedLocation?.tags?.map((t) => t.name) || [],
      radius: convertDistance(
        savedLocation?.radiusMeters || 0,
        user.units,
      ).toFixed(2),
    });

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  useEffect(() => {
    resetModalState();
  }, [savedLocation]);

  const refetchTags = () => {
    if (!getFeatureAccess('saved-locations-tags', 'hide')) getUserTags.mutate();
  };

  const allTags = useMemo(
    () => userTags?.map(({name}) => name) || [],
    [userTags],
  );

  // Update Location Handler
  const updateSavedLocation = (data) => {
    track('Updated Saved Location', {
      oldLocation: savedLocation,
      newLocation: data,
    });

    putSavedLocation.mutate(
      {
        query: savedLocation.id,
        body: {
          ...data,
          radiusMeters: convertDistance(data.radius, 'm', user.units),
        },
      },
      {
        onSuccess: (location) => {
          dispatch(
            setThreatAnalyticsArea({
              latitude: location.latitude,
              longitude: location.longitude,
              radius: location.radiusMeters,
            }),
          );
          showSnackbar({
            iconColor: 'success',
            icon: 'check',
            message: updatedLocationLabel,
          });
          refetchTags();
          onSuccess(location);
          handleClose();
        },
      },
    );
  };

  // Update Location Handler
  const createSavedLocation = (data) => {
    track('Create Saved Location', {
      newLocation: data,
    });

    postUserLocation.mutate(
      {
        body: {
          ...data,
          radiusMeters: convertDistance(data.radius, 'm', user.units),
        },
      },
      {
        onSuccess: (location) => {
          showSnackbar({
            iconColor: 'success',
            icon: 'check',
            message: createdLocationLabel,
          });
          refetchTags();
          onSuccess(location);
          handleClose();
        },
      },
    );
  };

  // Delete Location Handler
  const deleteSavedLocation = () => {
    track('Delete Saved Location', {
      location: savedLocation,
    });

    deleteUserLocation.mutate(
      {
        query: savedLocation.id,
      },
      {
        onSuccess: () => {
          showSnackbar({
            iconColor: 'success',
            icon: 'check',
            message: deletedLocationLabel,
          });
          onSuccess();
          handleClose();

          // Remove location from recent searches
          const newRecentSearches = recentSearches.filter(
            (search) => search.id !== savedLocation.id,
          );
          dispatch(setRecentSearches(newRecentSearches));
        },
      },
    );
  };

  // Handle Save
  const onSubmit = (data) => {
    const location = {...data};

    if (location.customerRating <= 0) {
      location.customerRating = null;
    }
    if (savedLocation.id != null) {
      updateSavedLocation(location);
    } else {
      createSavedLocation(location);
    }
  };

  const renderTagsOptions = (props, option) => {
    const isExistingOption = allTags.includes(option);
    return (
      <>
        {!isExistingOption && (
          <Box
            component="li"
            sx={{color: theme.palette.text.secondary}}
            {...props}>
            {!isExistingOption && `${addNewTag} "${option}"`}
          </Box>
        )}
        {isExistingOption && (
          <Box component="li" {...props}>
            {option}
          </Box>
        )}
      </>
    );
  };

  const renderTags = (value, getTagProps) =>
    value?.map((option, index) => (
      <BaseChipLabel
        key={option}
        label={option}
        size="small"
        {...getTagProps({index})}
      />
    ));

  const tagSuggestions = useMemo(
    () =>
      Array.from(new Set([...allTags, newTag])).filter(
        (option) => option !== '' && option !== null,
      ) || [],
    [allTags, newTag],
  );

  const addressTypeSuggestions = useMemo(
    () =>
      getLocationFilterOptions(SAVED_LOCATION_TYPES).map((option) => ({
        value: option.id,
        label: getSavedTranslations(option.name),
        icon: LOCATION_TYPE_ICONS[option.id] || Star,
      })),
    [],
  );

  const handleCloseAndResetState = () => {
    resetModalState();
    handleClose();
  };

  return (
    <BaseModal
      open={open}
      handleCloseButton={handleCloseAndResetState}
      onClose={handleClose}>
      <Grid container spacing={1} sx={{paddingBottom: '30px'}}>
        <Grid item xs={12}>
          <BaseTextField
            fullWidth
            id="name"
            name="name"
            required
            label={locationNameLabel}
            variant="standard"
            size="medium"
            errors={errors.name}
            {...register('name')}
          />
        </Grid>
        {isExpanded && (
          <>
            <Grid item xs={12}>
              <BaseTextField
                fullWidth
                id="customerLocationId"
                name="customerLocationId"
                label={customerIdLabel}
                variant="standard"
                size="medium"
                errors={errors.customerLocationId}
                {...register('customerLocationId')}
              />
            </Grid>
            <Grid item xs={12}>
              <BaseTextField
                fullWidth
                id="address"
                name="address"
                label={displayAddressLabel}
                variant="standard"
                size="medium"
                errors={errors.address}
                {...register('address')}
              />
            </Grid>
            <Grid item xs={6}>
              <BaseTextField
                fullWidth
                id="latitude"
                name="latitude"
                required
                label={latitudeLabel}
                variant="standard"
                size="medium"
                errors={errors.latitude}
                {...register('latitude')}
              />
            </Grid>
            <Grid item xs={6}>
              <BaseTextField
                fullWidth
                id="longitude"
                name="longitude"
                required
                label={longitudeLabel}
                variant="standard"
                size="medium"
                errors={errors.longitude}
                {...register('longitude')}
              />
            </Grid>
            <Grid item xs={6}>
              <BaseTextField
                fullWidth
                id="radius"
                name="radius"
                required
                label={radiusLabel}
                variant="standard"
                size="medium"
                errors={errors.radius}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {user.units}
                    </InputAdornment>
                  ),
                }}
                {...register('radius')}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <BaseInputLabel label={visibilityLabel} />
              <BaseSwitch
                id="isPrivate"
                name="isPrivate"
                control={control}
                disabled={isVisibilityDisabled}
                checkedLabel={visibilityOptionLabels.private}
                uncheckedLabel={visibilityOptionLabels.shared}
                sx={styles.baseVisibilitySwitch(
                  theme,
                  visibilityOptionLabels.shared,
                  visibilityOptionLabels.private,
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <BaseChipRadioGroup
                control={control}
                size="small"
                id="addressType"
                name="addressType"
                label={locationTypeLabel}
                placeholder={locationTypePlaceholder}
                variant="standard"
                errors={errors.addressType}
                options={addressTypeSuggestions}
              />
            </Grid>
            <Grid item xs={6} sx={{marginTop: '-24px'}}>
              <FeatureAccessWrapper feature="saved-locations-priority">
                <BaseSlider
                  marks={[
                    {value: 0, label: noneLabel},
                    {value: 1},
                    {value: 2},
                    {value: 3},
                    {value: 4},
                    {value: 5, label: '5'},
                  ]}
                  min={0}
                  max={5}
                  track={false}
                  valueLabelFormat={(value) =>
                    value === 0 ? noneLabel : value
                  }
                  fullWidth
                  control={control}
                  label={priorityRatingLabel}
                  id="customerRating"
                  name="customerRating"
                  {...register('customerRating')}
                />
              </FeatureAccessWrapper>
            </Grid>
            <Grid item xs={12}>
              <FeatureAccessWrapper feature="saved-locations-tags">
                <BaseAutocomplete
                  control={control}
                  id="tags"
                  name="tags"
                  multiple
                  freeSolo
                  label={tagsLabel}
                  errors={errors.tags}
                  helperText={getSavedTranslations(
                    'Max number of tags allowed per location: 15',
                  )}
                  onInputChange={(value) => setNewTag(value)}
                  renderTags={renderTags}
                  renderOption={renderTagsOptions}
                  options={tagSuggestions}
                />
              </FeatureAccessWrapper>
            </Grid>
          </>
        )}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {!defaultExpanded && (
          <Link
            component="button"
            underline="hover"
            onClick={() => setIsExpanded(!isExpanded)}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
              }}>
              {isExpanded ? collapseModalLabel : expandModalLabel}
            </Typography>
          </Link>
        )}
        {savedLocation?.id !== undefined && (
          <BaseButton
            color="error"
            variant="text"
            onClick={() => deleteSavedLocation()}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
              }}>
              {deleteButton.text}
            </Typography>
          </BaseButton>
        )}
        <Box sx={{flexGrow: 1}} />
        <BaseButton
          variant="outlined"
          onClick={handleCloseAndResetState}
          sx={{
            marginLeft: 1,
          }}>
          {cancelButtonText}
        </BaseButton>
        <BaseButton
          variant="contained"
          //   disabled={isLoading || isSavedLocationSame()}
          onClick={handleSubmit(onSubmit)}
          sx={{
            marginLeft: 1,
          }}>
          {saveButton.text}
        </BaseButton>
      </Box>
    </BaseModal>
  );
}

SavedLocationModal.propTypes = {
  open: PropTypes.bool,
  savedLocation: PropTypes.object,
  defaultExpanded: PropTypes.bool,
  handleClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default SavedLocationModal;
