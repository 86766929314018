import MUIyellow from '@mui/material/colors/yellow';

const yellow = {
  ...MUIyellow,
  /**
   * Custom: ![yellow 100](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  100: '#fef5cc',
  /**
   * Custom: ![yellow 1000](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  1000: '#7F6C1A',
};

export default yellow;
