import {useSelector} from 'react-redux';
import get from 'lodash/get';

import {base_languages as I18N} from '@languages';
import {getUserLocale} from '@/selectors';

export const useTranslation = () => {
  const locale = useSelector(getUserLocale);

  const getI18N = (path) => get(I18N[locale], path);

  const getEnglishTranslation = (path) => get(I18N.en_US, path);

  const getThreatTypesTranslation = (type) => {
    const translation = getI18N('filters.categories');
    return translation[type];
  };

  const getSourceTypesTranslation = (type) => {
    const translation = getI18N('filters.sources');
    return translation[type];
  };

  const getTimeSliceTranslation = (value) => {
    const translation = getI18N('filters.timeslices');
    return translation[value];
  };

  const getWeekdayTranslations = (value) => {
    const translation = getI18N('filters.weekdays');
    return translation[value];
  };

  const getTranslationKey = (translation, value) =>
    Object.keys(translation).find((key) => translation[key] === value);

  const translateValue = (language, translation, value) => {
    const translationKey = getTranslationKey(language, value);
    return translation[translationKey];
  };

  const getTranslation = (path, value) => {
    const english = getEnglishTranslation(path);
    const translation = getI18N(path);

    return translateValue(english, translation, value);
  };

  const getAnalyticsTranslation = (value) =>
    getTranslation('threatAnalytics', value);

  const getTooltipTranslation = (value) => getI18N('tooltips')[value];

  const getSavedTranslations = (value) =>
    getTranslation('savedLocations', value);

  const getSortTranslations = (value) =>
    getTranslation('savedLocations.sortOptions', value);

  const getHelpTranslations = (value) => getTranslation('help', value);

  const getUserTranslations = (value) => getTranslation('user', value);

  return {
    locale,
    getTranslation,
    getI18N,
    getEnglishTranslation,
    getThreatTypesTranslation,
    getSourceTypesTranslation,
    getAnalyticsTranslation,
    getSavedTranslations,
    getHelpTranslations,
    getTimeSliceTranslation,
    getTooltipTranslation,
    getWeekdayTranslations,
    getUserTranslations,
    getSortTranslations,
  };
};
