import {RESET_STORE} from '@store/modules/actionTypes';
import {SET_COUNTRY_SUMMARY} from '@/store/modules/summaries/actionTypes';

export const initialState = {
  countrySummary: null,
};

const summariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_SUMMARY:
      return {
        ...state,
        countrySummary: action.payload,
      };
    case RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default summariesReducer;
