// Packages
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';

// State Handlers
import {getFilters, getLocationThreatTypes} from '@/selectors';

// Hooks
import {useTranslation} from '@/hooks/useTranslation';
import {useMixpanel} from '@/hooks/useMixpanel';

// Components
import FilterItem from '@/components/filters/FilterItem';
import BaseSwitch from '@/components/common/inputs/BaseSwitch';
import {setGTMStatus} from '@/store/modules/filters/actions';
import BaseTooltip from '@/components/common/BaseTooltip';
import {styles} from '@/components/filters/FilterGlobalThreatsModel.styles';

function FilterGlobalThreatsModel() {
  const dispatch = useDispatch();
  const {track} = useMixpanel();
  const {getI18N} = useTranslation();

  const {isGTM} = useSelector(getFilters);
  const locationThreatTypes = useSelector(getLocationThreatTypes);

  const {gtmSwitchLabel} = getI18N('filters');
  const {gtmAvailable, gtmUnavailable} = getI18N('tooltips');

  const handleGlobalThreatsModel = useCallback(() => {
    dispatch(setGTMStatus());
    track('GTM status', {status: isGTM});
  }, [dispatch, isGTM]);

  const isGTMavailable = useMemo(
    () => locationThreatTypes.length > 0,
    [locationThreatTypes],
  );

  useEffect(() => {
    dispatch(setGTMStatus(isGTMavailable));
  }, [dispatch, isGTMavailable]);

  return (
    <FilterItem>
      <Box sx={styles.root}>
        <BaseSwitch
          checked={isGTM && isGTMavailable}
          disabled={!isGTMavailable}
          onChange={handleGlobalThreatsModel}
          label={gtmSwitchLabel}
        />
        <BaseTooltip
          message={isGTMavailable ? gtmAvailable : gtmUnavailable}
          iconSize={16}
        />
      </Box>
    </FilterItem>
  );
}

export default FilterGlobalThreatsModel;
