import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material';
import BaseIconButton from '@/components/common/buttons/BaseIconButton';

const useStyles = () => ({
  root: {
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
  },
});

function BaseIconButtonGroup({
  sx = {},
  value = '',
  onChange = () => {},
  size = 32,
  items = [],
}) {
  const styles = useStyles();
  const theme = useTheme();

  const backgroundColor = sx.backgroundColor || theme.palette.background.light;

  return (
    <Box
      sx={{
        ...styles.root,
        ...sx,
        backgroundColor,
        height: `${size.toString()}px`,
      }}>
      {items.map((item) => (
        <BaseIconButton
          key={item.value}
          icon={item.icon}
          tooltip={item.tooltip}
          size={size}
          sx={{
            backgroundColor,
          }}
          onClick={() => {
            onChange(item.value);
          }}
          color={value === item.value ? theme.palette.primary.main : null}
        />
      ))}
    </Box>
  );
}

BaseIconButtonGroup.propTypes = {
  sx: PropTypes.any,
  value: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.number,
  items: PropTypes.array.isRequired,
};

export default BaseIconButtonGroup;
