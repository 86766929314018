import {useLocation, useHistory} from 'react-router-dom';

export const useRouter = () => {
  const location = useLocation();
  const history = useHistory();

  const pushRoute = (route) => history.push(route);
  const isRoute = (route) => location.pathname === route;
  const reload = () => history.go(0);
  const replaceRoute = (route) => history.push(route);

  const isHomeRoute = isRoute('/');
  const isMapRoute = isRoute('/map');
  const isUserRoute = isRoute('/user');
  const isReportRoute = location.pathname.includes('report');
  const isSavedRoute = isRoute('/my-locations/list');
  const isMyLocationsAnalysisRoute = isRoute('/my-locations/analysis');

  const pushHomeRoute = () => pushRoute('/');
  const pushMapRoute = () => pushRoute('/map');
  const pushUserRoute = () => pushRoute('/user');
  const pushDashRoute = () => pushRoute('/my-locations/list');
  const pushMyLocationsAnalysis = () => pushRoute('/my-locations/analysis');

  return {
    history,
    reload,
    route: location.pathname,
    isHomeRoute,
    isMapRoute,
    isUserRoute,
    isReportRoute,
    isSavedRoute,
    isMyLocationsAnalysisRoute,
    pushHomeRoute,
    pushMapRoute,
    pushUserRoute,
    pushDashRoute,
    pushMyLocationsAnalysis,
    replaceRoute,
    pushRoute,
  };
};
