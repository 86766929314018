import {useSentry} from '@hooks/useSentry';
import {useSnackbar} from '@hooks/useSnackbar';
import {useAuth0Service} from '@hooks/useAuth0Service';
import {useTranslation} from '@hooks/useTranslation';
import {useCallback} from 'react';

export const useErrorHandler = () => {
  const {handleAuth0LogOut} = useAuth0Service();
  const {captureExceptionSentry} = useSentry();
  const {showSnackbar} = useSnackbar();
  const {getI18N} = useTranslation();

  const {clientError, serverError, ...httpCodesLanguage} =
    getI18N('httpErrorMessages');

  const handleGlobalError = (message, error) => {
    captureExceptionSentry(error);
    console.error(message, error);
    return error;
  };

  /**
   * Show snackbar for appropiate errors
   * @param {object} response - Response object
   * @param {object} response.status - Status code
   * @param {object} response.data - Response data
   * @param {object} response.data.message - Response message
   * @returns {void}
   * */
  const showHttpSnackbars = (response) => {
    const {method} = response.config;
    const {status} = response;
    const message = response.data?.message;
    if (status === 400 && ['delete', 'post', 'put', 'patch'].includes(method)) {
      showSnackbar({
        icon: 'warning',
        iconColor: 'warning',
        message: httpCodesLanguage[status].replace(
          '{message}',
          (typeof message === 'string' ? message : message[0]) ?? clientError,
        ),
      });
    } else if (status === 403) {
      showSnackbar({
        message: httpCodesLanguage[status],
        icon: 'warning',
        iconColor: 'warning',
      });
    } else if (status === 429) {
      showSnackbar({
        message: httpCodesLanguage[status],
        icon: 'warning',
        iconColor: 'warning',
      });
    } else if (status === 500) {
      showSnackbar({
        message: httpCodesLanguage[status],
        icon: 'warning',
        iconColor: 'error',
      });
    } else if (status > 500) {
      showSnackbar({
        message: serverError,
        icon: 'warning',
        iconColor: 'error',
      });
    }
  };

  /**
   * Handle HTTP errors
   * @param {object} error - Error object
   * @param {object} error.response - Response object
   * @param {object} error.request - Request object
   * @param {object} error.status - Status code
   *
   * @returns {void}
   */
  const handleHttpError = (error) => {
    // Handle response errors, request errors, and other errors
    const res = error.response;
    const req = error.request;
    if (res) {
      // The request was made and the server responded with a status code

      // Temp supress population errors
      if (res?.config?.url?.includes('population')) {
        return;
      }

      // Show snackbar for appropiate errors
      showHttpSnackbars(res);

      // If user is not authenticated, logout
      if (res.status === 401) {
        handleAuth0LogOut();
      }

      // Capture 403, 429, and 5xx errors with Sentry
      if (error.status === 403 || error.status === 429 || error.status >= 500) {
        captureExceptionSentry(error);
      }
    } else if (req) {
      // The request was made but no response was received
      captureExceptionSentry(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      captureExceptionSentry(error);
    }
  };

  const handleMapError = useCallback(
    ({error}) => {
      const errorMessage = error.message || error.stack || 'Map error';
      showSnackbar({
        icon: 'warning',
        iconColor: 'error',
        message: errorMessage,
      });
      return handleGlobalError('react-map-gl:', errorMessage);
    },
    [showSnackbar, handleGlobalError],
  );

  return {
    handleHttpError,
    handleGlobalError,
    handleMapError,
  };
};
