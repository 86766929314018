import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {MAPBOX_PUBLIC_KEY, MAP_BOX_ICON_SPRITE} from '@config';
import {getIsMapReady, getUserAddingLocationStatus} from '@/selectors';

import {useRouter} from '@/hooks/useRouter';
import {useMapMouseMove} from '@/hooks/map/useMapMouseMove';
import {useMapMouseOut} from '@/hooks/map/useMapMouseOut';
import {useMouseDown} from '@/hooks/map/useMouseDown';

export const useMapMouse = () => {
  const {isMapRoute} = useRouter();
  const {handleMouseHover} = useMapMouseMove();
  const {handleMouseOutMap} = useMapMouseOut();
  const {handleMouseHold} = useMouseDown();

  const isAddingLocation = useSelector(getUserAddingLocationStatus);
  const isMapReady = useSelector(getIsMapReady);

  const [mouseEnter, setMouseEnter] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseOut, setMouseOut] = useState(false);

  const handleMouseMove = useCallback(
    (event) => {
      const {
        point,
        target: map,
        lngLat: {lat: latitude, lng: longitude},
      } = event;
      const features = map.queryRenderedFeatures(point);
      if (mouseDown) {
        handleMouseHold({features, event: {latitude, longitude}});
      } else {
        handleMouseHover({features, event: {latitude, longitude}});
      }
    },
    [mouseDown, handleMouseHover, handleMouseHold],
  );

  const handleMouseOut = useCallback(() => {
    setMouseOut(true);
    handleMouseOutMap();
  }, [handleMouseOutMap]);

  const handleMouseIn = useCallback(() => {
    setMouseOut(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setMouseEnter(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setMouseEnter(false);
  }, []);

  const handleMouseDown = useCallback(() => {
    setMouseDown(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setMouseDown(false);
  }, []);

  const cursor = useMemo(() => {
    if (!isMapReady && isMapRoute) {
      return 'progress';
    }

    if (mouseDown) {
      return 'grab';
    }

    if (isAddingLocation) {
      return `url(${MAP_BOX_ICON_SPRITE}?access_token=${MAPBOX_PUBLIC_KEY}) 12 28, auto`;
    }

    if (mouseEnter) {
      return 'pointer';
    }

    return 'auto';
  }, [mouseEnter, mouseDown, isAddingLocation, isMapReady, isMapRoute]);

  return {
    cursor,
    mouseDown,
    mouseEnter,
    mouseOut,
    handleMouseMove,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseUp,
    handleMouseDown,
    handleMouseIn,
    handleMouseOut,
  };
};
