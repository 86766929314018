export const useStyles = () => ({
  root: (theme) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.dark,
    width: '100vw',
    height: '100vh',
  }),
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: '900px',
    height: '100%',
    paddingX: 2,
  },
  imageBox: {
    marginBottom: 14,
  },
  titleText: {
    marginBottom: 2,
  },
  subtitleText: {
    fontWeight: 'normal',
    marginBottom: 4,
  },
  primaryButton: {
    marginBottom: 5,
  },
  secondaryActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    gap: 1,
  },
  secondaryActionButton: (theme) => ({
    color: theme.palette.text.secondary,
  }),
});
