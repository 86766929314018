import React, {memo} from 'react';
import PropTypes from 'prop-types';

import {useTheme} from '@mui/material';
import {Plus, Minus} from '@phosphor-icons/react';
import Box from '@mui/material/Box';

import {useMapZoom} from '@/hooks/map/useMapZoom';
import {useTranslation} from '@/hooks/useTranslation';
import BaseIconButton from '@/components/common/buttons/BaseIconButton';
import {styles} from '@/components/map/actions/ActionZoom.styles';

function ActionZoom({sx}) {
  const theme = useTheme();
  const {getI18N} = useTranslation();
  const {handleZoomIn, handleZoomOut} = useMapZoom();
  const {zoomIn, zoomOut} = getI18N('actionZoom');

  return (
    <Box
      sx={(theme) => ({
        ...(typeof sx === 'function' ? sx(theme) : sx),
        ...styles.root(theme),
      })}>
      <BaseIconButton
        icon={Plus}
        color={theme.palette.text.primary}
        sx={styles.zoomInButton}
        tooltip={zoomIn}
        tooltipPlacement="left"
        onClick={handleZoomIn}
      />
      <BaseIconButton
        icon={Minus}
        color={theme.palette.text.primary}
        sx={styles.zoomOutButton}
        tooltip={zoomOut}
        tooltipPlacement="left"
        onClick={handleZoomOut}
      />
    </Box>
  );
}

ActionZoom.propTypes = {
  sx: PropTypes.any,
};

export default memo(ActionZoom);
