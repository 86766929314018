import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import {Text} from '@visx/text';
import {scaleLog} from '@visx/scale';
import {ParentSize} from '@visx/responsive';
import {useTheme} from '@mui/material';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import BaseSkeleton from '@/components/common/BaseSkeleton';

function BaseWordCloud({
  width,
  height = 200,
  words,
  type = 'archimedean',
  minFontSize = 10,
  maxFontSize = 50,
  showBackground,
  isLoading,
}) {
  const wordsData = useMemo(() => words.map((w) => ({...w})), [words]);

  const theme = useTheme();
  const {
    theme: {colors},
  } = useChartTheme(wordsData.length);

  const fontScale = scaleLog({
    domain: [
      Math.min(...wordsData.map((w) => w.value)),
      Math.max(...wordsData.map((w) => w.value)),
    ],
    range: [minFontSize, maxFontSize],
  });
  const fontSizeSetter = (datum) => fontScale(datum.value);

  const fixedValueGenerator = () => 0.5;

  return (
    <ParentSize>
      {(parent) => {
        const w = width || parent.width;
        const h = height || parent.height;
        if (isLoading)
          return (
            <BaseSkeleton
              width={`${w.toString()}px`}
              height={`${h.toString()}px`}
            />
          );
        return (
          <svg width={w} height={h}>
            {showBackground && (
              <rect
                width={w}
                height={h}
                fill={theme.palette.background.dark}
                rx={14}
              />
            )}
            <Wordcloud
              words={wordsData}
              width={w}
              height={h}
              fontSize={fontSizeSetter}
              font="Impact"
              padding={0}
              spiral={type}
              rotate={0}
              random={fixedValueGenerator}>
              {(cloudWords) =>
                cloudWords.map((w, i) => (
                  <Text
                    key={w.text}
                    fill={w.color || colors[i % colors.length]}
                    textAnchor="middle"
                    transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                    fontSize={w.size}
                    fontFamily={w.font}>
                    {w.text}
                  </Text>
                ))
              }
            </Wordcloud>
          </svg>
        );
      }}
    </ParentSize>
  );
}

BaseWordCloud.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  words: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['archimedean', 'rectangular']),
  minFontSize: PropTypes.number,
  maxFontSize: PropTypes.number,
  showBackground: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default BaseWordCloud;
