import PropTypes from 'prop-types';

import {useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';

import {ParentSize} from '@visx/responsive';
import {Pie} from '@visx/shape';
import {useTooltip, TooltipWithBounds} from '@visx/tooltip';
import {Group} from '@visx/group';
import BaseSkeleton from '@/components/common/BaseSkeleton';

const BaseChartTooltip = styled(TooltipWithBounds)(({theme}) => ({
  backgroundColor: `${theme.palette.background.default} !important`,
  borderRadius: '4px !important',
  padding: '0px !important',
  zIndex: '15000 !important',
}));

function BaseDonutChart({
  data = {},
  title,
  height = 200,
  width,
  donutThickness = 40,
  colorAccessor = () => {},
  showPercentages = false,
  isLoading = true,
}) {
  const theme = useTheme();
  const {
    tooltipOpen,
    tooltipData,
    tooltipTop,
    tooltipLeft,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const chartData = Object.values(data)[0];
  const tooltipValue = Object.keys(data)[0];

  if (isLoading) {
    return <BaseSkeleton height={height} />;
  }

  return (
    <ParentSize>
      {(parent) => {
        const svgHeight = height ?? parent.height;
        const svgWidth = width ?? parent.width;
        const radius = Math.min(svgWidth, svgHeight) / 2;

        return (
          <Box sx={{position: 'relative'}}>
            <svg width={svgWidth} height={svgHeight}>
              <Group top={svgHeight / 2} left={svgWidth / 2}>
                <Pie
                  data={chartData}
                  pieValue={(d) => d.value}
                  outerRadius={radius}
                  innerRadius={radius - donutThickness}
                  pieSort={null}>
                  {({arcs, path}) => (
                    <g>
                      {arcs.map((arc, i) => {
                        const centroid = path.centroid(arc);
                        const angle = Math.atan2(centroid[1], centroid[0]);
                        const offset = 100;

                        let tooltipX =
                          radius +
                          Math.cos(angle) * (radius + 55 + donutThickness / 2);
                        let tooltipY =
                          radius +
                          Math.sin(angle) * (radius - 55 + donutThickness / 2);

                        if (Math.cos(angle) > 0) {
                          tooltipX += offset;
                        } else {
                          tooltipX -= offset;
                        }

                        if (Math.sin(angle) > 0) {
                          tooltipY += offset;
                        } else {
                          tooltipY -= offset;
                        }

                        return (
                          <g
                            key={`pie-arc-${i}`}
                            onMouseLeave={hideTooltip}
                            onMouseEnter={() =>
                              showTooltip({
                                tooltipData: arc.data,
                                tooltipLeft: tooltipX,
                                tooltipTop: tooltipY,
                              })
                            }>
                            <path
                              className={`arc${i}`}
                              d={path(arc)}
                              fill={colorAccessor(arc.data)}
                            />
                            {arc.data.percentage > 1 && (
                              <text
                                x={centroid[0]}
                                y={centroid[1]}
                                dy=".3em"
                                textAnchor="middle"
                                fontSize="12"
                                fill={colorAccessor(arc.data, 'text')}>
                                {showPercentages
                                  ? `${arc.data.percentage}%`
                                  : arc.data.value}
                              </text>
                            )}
                          </g>
                        );
                      })}
                    </g>
                  )}
                </Pie>
                <text
                  x={0}
                  y={0}
                  textAnchor="middle"
                  dy=".3em"
                  fontSize="0.875rem"
                  fill={theme.palette.text.primary}>
                  {title}
                </text>
              </Group>
            </svg>
            {tooltipOpen && (
              <BaseChartTooltip top={tooltipTop} left={tooltipLeft}>
                <Box
                  sx={{
                    paddingX: '16px',
                    paddingY: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Typography
                    variant="caption"
                    color={theme.palette.text.primary}
                    fontWeight="bold">
                    {showPercentages
                      ? `${tooltipData.percentage}%`
                      : tooltipData.value}
                  </Typography>
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        width: '11px',
                        height: '11px',
                        marginRight: '8px',
                        backgroundColor: colorAccessor(tooltipData),
                        borderRadius: '4px',
                      }}
                    />
                    <Typography
                      variant="caption"
                      color={theme.palette.text.secondary}>
                      <strong>{tooltipValue}:</strong>
                      &nbsp;
                      {tooltipData.label}
                    </Typography>
                  </Box>
                </Box>
              </BaseChartTooltip>
            )}
          </Box>
        );
      }}
    </ParentSize>
  );
}

BaseDonutChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  donutThickness: PropTypes.number,
  title: PropTypes.string,
  data: PropTypes.object,
  colorAccessor: PropTypes.func,
  showPercentages: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default BaseDonutChart;
