import grey from '@styles/colors/grey';
import {baseTheme} from '@styles/theme/base';

export const darkTheme = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    text: {
      primary: grey[50],
      secondary: grey[600],
      disabled: grey[800],
    },
    background: {
      default: grey[2000],
      paper: grey[2000],
      light: grey[900],
      dark: grey[1000],
      transparent: 'rgba(0, 0, 0, 0)',
      blur: 'rgba(0, 0, 0, 0.5)',
      contrast: {
        light: grey[400],
        dark: grey[800],
      },
    },
    common: {
      black: grey[2000],
      white: grey[50],
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: false,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: baseTheme.palette.secondary.dark,
          color: baseTheme.palette.secondary.contrastText,
        },
        arrow: {
          color: baseTheme.palette.secondary.dark,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: grey[1000],
          '& .Mui-selected': {
            backgroundColor: `${grey[900]}!important`,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        defaultInfo: {
          backgroundColor: `${baseTheme.palette.primary.main} !important`,
        },
        outlinedInfo: {
          borderColor: `${baseTheme.palette.secondary.main} !important`,
          '& .MuiChip-label': {
            color: `${baseTheme.palette.secondary.main} !important`,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          '& .MuiListItemIcon-root': {
            color: grey[600],
          },
          '&:hover': {
            backgroundColor: grey[1000],
            color: grey[50],
            '& .MuiListItemIcon-root': {
              color: grey[50],
            },
          },
          '&.Mui-selected .MuiListItemIcon-root': {
            color: grey[50],
          },
        },
      },
    },
  },
};
