import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  API_LOCATION_VIEWPORT,
  API_THREAT_VIEWPORT_COUNT,
  API_LOCATION_SUMMARY,
  API_COUNTRY_SUMMARY_VIEWPORT,
} from '@hooks/api/constants';
import {useApi} from '@hooks/api/useApi';
import {useMixpanel} from '@hooks/useMixpanel';
import {
  getSelectedCategories,
  getSelectedMonths,
  getSelectedTimeslices,
  getLayers,
  getViewportBoundingBox,
  getFiltersLoading,
  getIsFlying,
  getViewTypeWorld,
  getFromDate,
  getToDate,
  getPrevSelectedMonths,
  getPrevSelectedTimeSlices,
  getPrevSelectedCategories,
  getPrevFromDate,
  getPrevToDate,
  getSelectedSources,
  getPrevSelectedSources,
  getLocation,
  getLocationLoading,
  getViewTypeCity,
  getActiveCity,
  getViewTypeCountry,
} from '@/selectors';
import {
  setCrimeFilters,
  setLocationFilters,
  setFiltersLoading,
} from '@/store/modules/filters/actions';
import {setCrimeEventsCount} from '@/store/modules/map/actions';
import {useMoment} from '@/hooks/useMoment';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {setCountrySummary} from '@/store/modules/summaries/actions';

export const useSummary = ({enabled}) => {
  const dispatch = useDispatch();
  const {getISOfromDate, getISOtoDate, didFilterDatesChanged} = useMoment();
  const {useGetQuery} = useApi();
  const {track} = useMixpanel();
  const {triggerMapRepaint} = useMapboxGL();

  const sources = useSelector(getSelectedSources);
  const categories = useSelector(getSelectedCategories);
  const timeSlices = useSelector(getSelectedTimeslices);
  const months = useSelector(getSelectedMonths);
  const {districts: isDistrictsLayer} = useSelector(getLayers);
  const isFlying = useSelector(getIsFlying);
  const viewportBoundingBox = useSelector(getViewportBoundingBox);
  const filtersLoading = useSelector(getFiltersLoading);
  const isWorldView = useSelector(getViewTypeWorld);
  const activeCity = useSelector(getActiveCity);
  const {name: locationName} = useSelector(getLocation);
  const fromDate = useSelector(getFromDate);
  const toDate = useSelector(getToDate);
  const prevCategories = useSelector(getPrevSelectedCategories);
  const prevMonths = useSelector(getPrevSelectedMonths);
  const prevTimeSlices = useSelector(getPrevSelectedTimeSlices);
  const prevFromDate = useSelector(getPrevFromDate);
  const prevToDate = useSelector(getPrevToDate);
  const prevSources = useSelector(getPrevSelectedSources);
  const locationLoading = useSelector(getLocationLoading);
  const {getZoom} = useMapboxGL();
  const isCityView = useSelector(getViewTypeCity);
  const isCountryView = useSelector(getViewTypeCountry);

  const isLocationSelected = activeCity != null;
  const isRequestEnabled =
    !isWorldView && !isFlying && !filtersLoading && !locationLoading;

  const {data: summary, isFetching: isFetchingSummary} = useGetQuery({
    path: API_LOCATION_SUMMARY.replace('{id}', activeCity),
    config: {
      enabled: isRequestEnabled && isLocationSelected && enabled,
    },
  });

  useEffect(() => {
    if (!isEmpty(summary) && !isFetchingSummary) {
      dispatch(setLocationFilters(summary));
      dispatch(setCrimeFilters());
    }
  }, [dispatch, summary, isFetchingSummary]);

  const {data: viewportSummary, isFetching: isFetchingViewportSummary} =
    useGetQuery({
      path: API_LOCATION_VIEWPORT,
      params: {
        swLongitude: viewportBoundingBox?._sw?.lng,
        swLatitude: viewportBoundingBox?._sw?.lat,
        neLongitude: viewportBoundingBox?._ne?.lng,
        neLatitude: viewportBoundingBox?._ne?.lat,
        zoom: getZoom(),
      },
      config: {
        enabled:
          isCityView &&
          isRequestEnabled &&
          !isLocationSelected &&
          !isEmpty(viewportBoundingBox) &&
          getZoom() != null &&
          enabled,
      },
    });

  useEffect(() => {
    if (!isEmpty(viewportSummary) && !isFetchingViewportSummary) {
      dispatch(setLocationFilters(viewportSummary));
      dispatch(setCrimeFilters());
    }
  }, [dispatch, viewportSummary, isFetchingViewportSummary]);

  const {data: countrySummary, isFetching: isFetchingCountrySummary} =
    useGetQuery({
      path: API_COUNTRY_SUMMARY_VIEWPORT,
      params: {
        swLongitude: viewportBoundingBox?._sw?.lng,
        swLatitude: viewportBoundingBox?._sw?.lat,
        neLongitude: viewportBoundingBox?._ne?.lng,
        neLatitude: viewportBoundingBox?._ne?.lat,
      },
      config: {
        enabled:
          (isCityView || isCountryView) &&
          isRequestEnabled &&
          !isEmpty(viewportBoundingBox) &&
          enabled,
      },
    });

  useEffect(() => {
    if (!isFetchingCountrySummary) {
      dispatch(setCountrySummary(countrySummary));
    }
  }, [dispatch, countrySummary, isFetchingCountrySummary]);

  useEffect(() => {
    dispatch(setFiltersLoading(isFetchingViewportSummary || isFetchingSummary));
    if (isFetchingViewportSummary || isFetchingSummary) {
      dispatch(setCrimeEventsCount(0));
    }
  }, [dispatch, isFetchingViewportSummary, isFetchingSummary]);

  const {data: viewportCount, isFetching: isFetchingViewportCount} =
    useGetQuery({
      path: API_THREAT_VIEWPORT_COUNT,
      params: {
        swLongitude: viewportBoundingBox?._sw?.lng,
        swLatitude: viewportBoundingBox?._sw?.lat,
        neLongitude: viewportBoundingBox?._ne?.lng,
        neLatitude: viewportBoundingBox?._ne?.lat,
        fromDate: getISOfromDate(),
        toDate: getISOtoDate(),
        sources: JSON.stringify(sources),
        categories: JSON.stringify(categories),
        daytimes: JSON.stringify(timeSlices),
        months: JSON.stringify(months),
        zoom: getZoom(),
      },
      config: {
        enabled:
          isCityView &&
          isRequestEnabled &&
          !isEmpty(viewportBoundingBox) &&
          !isDistrictsLayer &&
          getZoom() != null &&
          enabled,
      },
    });

  useEffect(() => {
    if (viewportCount && !isFetchingViewportCount) {
      dispatch(setCrimeEventsCount(viewportCount));
    }
  }, [dispatch, viewportCount, isFetchingViewportCount]);

  useEffect(() => {
    if (!isFlying && !isWorldView) {
      track('Filters Applied Event', {
        location: locationName || 'Free Roaming',
        oldFilters: {
          sources: prevSources,
          categories: prevCategories,
          timeSlices: prevTimeSlices,
          months: prevMonths,
          fromDate: prevFromDate?.toISOString(),
          toDate: prevToDate?.toISOString(),
        },
        filters: {
          sources,
          categories,
          timeSlices,
          months,
          fromDate: getISOfromDate(),
          toDate: getISOtoDate(),
        },
      });
    }
  }, [
    sources,
    categories,
    timeSlices,
    months,
    fromDate,
    toDate,
    isFlying,
    isWorldView,
    locationName,
  ]);

  useEffect(() => {
    if (didFilterDatesChanged()) {
      triggerMapRepaint('threat-points-source');
    }
  }, [fromDate, toDate]);
};
