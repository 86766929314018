import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTranslation} from '@/hooks/useTranslation';
import BaseTooltip from '@/components/common/BaseTooltip';
import {styles} from '@/components/common/typography/BetaLabel.styles';

function BetaLabel({
  tooltip = 'This is a Beta feature',
  onClick = () => {},
  sx = {},
}) {
  const {getI18N} = useTranslation();

  const label = getI18N('betaLabel');
  const betaTooltip = getI18N('tooltips.betaLabel');

  return (
    <Box
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}
      onClick={onClick}>
      {tooltip && (
        <BaseTooltip message={betaTooltip} icon={false}>
          <Typography sx={styles.text}>{label}</Typography>
        </BaseTooltip>
      )}
    </Box>
  );
}

export default BetaLabel;

BetaLabel.propTypes = {
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
