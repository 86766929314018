import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTranslation} from '@/hooks/useTranslation';
import BaseTooltip from '@/components/common/BaseTooltip';
import {styles} from '@/components/common/typography/EnterpriseLabel.styles';

function EnterpriseLabel({
  tooltip = 'This feature will be included in Base Operations premium tier and it will soon be available for premium accounts only.',
  onClick = () => {},
}) {
  const {getI18N} = useTranslation();

  const enterprise = getI18N('enterpriseLabel');

  return (
    <Box sx={styles.root} onClick={onClick}>
      {tooltip && (
        <BaseTooltip message={tooltip} icon={false}>
          <Typography sx={styles.text}>{enterprise}</Typography>
        </BaseTooltip>
      )}
    </Box>
  );
}

export default EnterpriseLabel;

EnterpriseLabel.propTypes = {
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
};
