export const styles = {
  root: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.dark,
    borderRadius: '6px',
  }),
  zoomInButton: {
    backgroundColor: 'transparent',
    borderRadius: '6px 6px 0 0',
  },
  zoomOutButton: {
    backgroundColor: 'transparent',
    borderRadius: '0 0 6px 6px',
  },
};
