import {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Box} from '@mui/material';
import {useTranslation} from '@/hooks/useTranslation';
import BaseWordCloud from '@/components/common/charts/BaseWordCloud';
import BaseLegend from '@/components/common/charts/BaseLegend';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import {getThreatCategories} from '@/selectors';

function ThreatWordCloud({
  data = {chartData: []},
  isLoading = false,
  showLegend = false,
  height,
  width,
}) {
  const {getChartColors} = useChartTheme();
  const {locale, getThreatTypesTranslation} = useTranslation();
  const [disabled, setDisabled] = useState([]);
  const threatCategories = useSelector(getThreatCategories);

  // Get subcategories based on chart data
  const subCategories = useMemo(() => {
    if (!data?.chartData || !threatCategories) return [];
    const categoryIds = data.chartData
      .sort((d) => d.value)
      .map(({categoryId}) => categoryId);

    return categoryIds.map((categoryId) => {
      const category = threatCategories.find(
        (category) => category?.id === categoryId,
      );
      return category;
    });
  }, [data, threatCategories]);

  // Get parent categories based on subcategories
  const parentCategories = useMemo(() => {
    if (!threatCategories || subCategories.length === 0) return [];

    // Get unique parent ids
    const parentIds = Array.from(
      new Set(subCategories.map((category) => category?.parentId)),
    ).filter((parentId) => parentId);

    // Get parent categories
    return parentIds.map((parentId) => {
      const category = threatCategories.find(
        (category) => category?.id === parentId,
      );
      return category;
    });
  }, [threatCategories, subCategories]);

  // Get colors for each category (parent and subcategories)
  const categoryColors = useMemo(() => {
    if (parentCategories.length === 0 || !data?.chartData) return [];

    const themeColors = getChartColors(parentCategories.length);

    // Create object with category id as key and color as value
    const colors = {};
    parentCategories.forEach((p, index) => {
      colors[p.id] = themeColors[index % themeColors.length];
    });
    subCategories.forEach((c) => {
      colors[c.id] = colors[c.parentId];
    });

    return colors;
  }, [getChartColors, threatCategories, data, parentCategories, subCategories]);

  const labels = useMemo(() => {
    if (parentCategories.length === 0 || !data?.chartData) return [];

    // Get label values and colors
    return parentCategories.map((parent) => ({
      value: parent.id,
      label: getThreatTypesTranslation(parent.id),
      color: categoryColors[parent.id],
    }));
  }, [data, parentCategories, categoryColors]);

  const formattedData = useMemo(() => {
    if (!categoryColors || !data?.chartData) return [];
    const {chartData} = data;

    return (
      chartData.map(({categoryId, count}) => ({
        text: getThreatTypesTranslation(categoryId),
        value: count,
        color: categoryColors[categoryId],
        parentId: subCategories.find((category) => category?.id === categoryId)
          ?.parentId,
      })) || []
    );
  }, [data, locale, categoryColors]);

  const filterData = useMemo(() => {
    if (formattedData.length === 0) return [];

    return formattedData.filter(({parentId}) => !disabled.includes(parentId));
  }, [formattedData, disabled]);

  const handleLegendClick = (value) => {
    if (disabled.includes(value)) {
      setDisabled(disabled.filter((v) => v !== value));
    } else {
      setDisabled([...disabled, value]);
    }
  };

  return (
    <Box>
      <BaseWordCloud
        height={height}
        width={width}
        words={filterData}
        isLoading={isLoading}
      />
      {showLegend && (
        <BaseLegend
          isLoading={isLoading}
          labels={labels}
          disabled={disabled}
          onClick={handleLegendClick}
        />
      )}
    </Box>
  );
}

ThreatWordCloud.propTypes = {
  height: PropTypes.number,
  showLegend: PropTypes.bool,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  width: PropTypes.number,
};

export default ThreatWordCloud;
