import {useDispatch} from 'react-redux';
import {MagnifyingGlass} from '@phosphor-icons/react';

import {displaySearchModal} from '@/store/modules/modal/actions';
import {BaseIconButton} from '@/components/common/buttons';
import {styles} from '@/components/navigation/list/TopNavigation.styles';
import {useTranslation} from '@/hooks/useTranslation';

function ActionSearch() {
  const dispatch = useDispatch();
  const {getI18N} = useTranslation();
  const {search} = getI18N('navigation');

  const handleSearch = () => dispatch(displaySearchModal(true));

  return (
    <BaseIconButton
      icon={MagnifyingGlass}
      sx={styles.rightIcon}
      onClick={handleSearch}
      tooltip={search}
    />
  );
}

export default ActionSearch;
