import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  ADD_TO_SNACKBAR_QUEUE,
  REMOVE_FROM_SNACKBAR_QUEUE,
} from '@/store/modules/snackbar/actionTypes';

export const showSnackbarAction = ({
  id,
  timeoutId,
  icon,
  iconColor,
  message,
  isLoading,
  isOpen,
  actions,
  onClick,
}) => ({
  type: SHOW_SNACKBAR,
  id,
  timeoutId,
  icon,
  iconColor,
  message,
  isLoading,
  isOpen,
  actions,
  onClick,
});

export const hideSnackbarAction = () => ({
  type: HIDE_SNACKBAR,
});

export const addToSnackbarQueueAction = ({
  id,
  icon,
  iconColor,
  message,
  duration,
  actions,
  onClick,
}) => ({
  type: ADD_TO_SNACKBAR_QUEUE,
  id,
  icon,
  iconColor,
  message,
  duration,
  actions,
  onClick,
});

export const removeFromSnackbarQueueAction = (id) => ({
  type: REMOVE_FROM_SNACKBAR_QUEUE,
  id,
});
