export const MIN_WIDTH = '100vw';
export const MAX_WIDTH = '100vw';
export const MAX_HEIGHT = '60vh';
export const MIN_HEIGHT = '40vh';

export const TIME_SERIES_CHARTS = [
  'times-series-analysis-monthly',
  'times-series-analysis-rolling-average',
];

export const GRANULARITY_TABS = [
  {
    label: 'Radius',
    value: 'radius',
  },
  {
    label: 'District',
    value: 'district',
  },
  {
    label: 'City',
    value: 'city',
  },
];

export const ANALYTIC_TYPES_PANELS = [
  {
    label: 'Overview',
    value: 'overview',
  },
  {
    label: 'Trend Analysis',
    value: 'analysis',
  },
  {
    label: 'Change Analysis',
    value: 'changeAnalysis',
  },
  {
    label: 'Threat Breakdown',
    value: 'breakdown',
  },
  {
    label: 'Notable Events',
    value: 'notableEvents',
  },
  {
    label: 'Events',
    value: 'eventList',
  },
  {
    label: 'Data source',
    value: 'source',
  },
  {
    label: 'Comparative',
    value: 'comparative',
  },
  {
    label: 'Country Analysis',
    value: 'countryAnalysis',
  },
];

export const EVENT_LIST_KEY = 'eventList';
