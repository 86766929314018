export const styles = {
  quintilesHeader: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '7px 12px',
    marginBottom: 0.5,
    borderRadius: '4px 4px 0 0',
    backgroundColor: theme.palette.background.contrast.dark,
    paddingRight: 6,
  }),
  compactQuintilesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 0.5,
    borderRadius: '4px 4px 0 0',
  },
};
