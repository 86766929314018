import {useMemo} from 'react';
import {useTranslation} from '@/hooks/useTranslation';

export const useChartTitle = ({title, variants, selections}) => {
  const {getAnalyticsTranslation} = useTranslation();

  const dynamicTitle = useMemo(
    () =>
      variants.reduce((accTitle, variant) => {
        const selection = selections.find(
          (selection) => selection.id === variant.id,
        );
        if (selection) {
          return accTitle.replace(`{${variant.id}}`, variant[selection.value]);
        }
        return accTitle;
      }, title),
    [title, variants, selections],
  );

  return {
    dynamicTitle: getAnalyticsTranslation(dynamicTitle) ?? title,
  };
};
