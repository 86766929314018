import {memo, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {getUnmappedMode, getViewTypeCity, getLocation} from '@/selectors';
import {styles} from '@/components/map/actions/Actions.styles';
import ActionFabUnmapped from '@/components/map/actions/ActionFabUnmapped';
import ActionFabFreeRoaming from '@/components/map/actions/ActionFabFreeRoaming';
import ActionMapSettings from '@/components/map/actions/ActionMapSettings';
import ActionSaveLocation from '@/components/map/actions/ActionSaveLocation';
import ActionZoom from '@/components/map/actions/ActionZoom';

function Actions() {
  const unmappedMode = useSelector(getUnmappedMode);
  const {id: locationId} = useSelector(getLocation);
  const isCityView = useSelector(getViewTypeCity);

  const isFreeRoaming = useMemo(
    () => locationId >= 0 && isCityView && false,
    [locationId, isCityView],
  );

  return (
    <>
      <ActionZoom sx={{...styles.action, bottom: 32, right: 16}} />
      <ActionSaveLocation sx={{...styles.action, bottom: 104, right: 16}} />
      <ActionMapSettings sx={{...styles.action, bottom: 32, right: 56}} />
      {isFreeRoaming && (
        <ActionFabFreeRoaming sx={{...styles.action, top: 0, right: 16}} />
      )}
      {unmappedMode && (
        <ActionFabUnmapped sx={{...styles.action, bottom: 136, right: 16}} />
      )}
    </>
  );
}

export default memo(Actions);
