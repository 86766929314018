import React, {createContext, useState, useMemo} from 'react';
import PropTypes from 'prop-types';

export const MapContext = createContext();

function MapProvider({children}) {
  const [map, setMap] = useState(null);
  const value = useMemo(() => ({map, setMap}), [map]);

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}

MapProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MapProvider;
