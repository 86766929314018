import {useCallback, useMemo} from 'react';

import {
  API_CHANGE_CATEGORY_CONTRIBUTION,
  API_CHANGE_RADIUS_CATEGORY_CONTRIBUTION,
} from '@/hooks/api/constants';
import {useTranslation} from '@/hooks/useTranslation';
import {useMoment} from '@/hooks/useMoment';
import {useChart} from '@/hooks/api/useChart';

export const useChangeCategory = ({
  id = 'change-category-contributions',
  title = 'Change Category Contribution',
  source = 5, // Default to local police, most popular in US
  period = 'period',
  granularity,
  aggregation,
  disabled = false,
  latitude,
  longitude,
  radius,
  fromDate,
  toDate,
  normalized,
}) => {
  const {locale, getThreatTypesTranslation, getI18N} = useTranslation();
  const {moment} = useMoment();
  const {months} = getI18N('filters');
  const {overallCrime} = getI18N('threatAnalytics');

  const path =
    granularity !== 'radius'
      ? API_CHANGE_CATEGORY_CONTRIBUTION
      : API_CHANGE_RADIUS_CATEGORY_CONTRIBUTION;
  const isDisabled = disabled || granularity === 'district';
  const selectedSources = JSON.stringify([Number(source)]);

  // Get monthly period over period change by category
  const {
    data: rawMonthlyPeriodData,
    error: errorMonthlyPeriod,
    isFetching: isFetchingMonthlyPeriod,
    downloadCSV: downloadCSVMonthlyPeriod,
    copyCSV: copyCSVMonthlyPeriod,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    granularity,
    path,
    exactDates: false,
    latitude,
    longitude,
    radius,
    fromDate,
    toDate,
    customParams: {
      timeAggregation: 'monthly',
      sources: selectedSources,
      processType: 'periodOverPeriod',
    },
    disabled: isDisabled,
  });

  // Get quarterly period over period change by category
  const {
    data: rawQuarterlyPeriodData,
    error: errorQuarterlyPeriod,
    isFetching: isFetchingQuarterlyPeriod,
    downloadCSV: downloadCSVQuarterlyPeriod,
    copyCSV: copyCSVQuarterlyPeriod,
  } = useChart({
    id,
    title,
    granularity,
    path,
    exactDates: false,
    latitude,
    longitude,
    radius,
    fromDate,
    toDate,
    customParams: {
      timeAggregation: 'quarterly',
      sources: selectedSources,
      processType: 'periodOverPeriod',
    },
    disabled: isDisabled,
  });

  // Get monthly year over year change by category
  const {
    data: rawMonthlyYearData,
    error: errorMonthlyYear,
    isFetching: isFetchingMonthlyYear,
    downloadCSV: downloadCSVMonthlyYear,
    copyCSV: copyCSVMonthlyYear,
  } = useChart({
    id,
    title,
    granularity,
    path,
    exactDates: false,
    latitude,
    longitude,
    radius,
    fromDate,
    toDate,
    customParams: {
      timeAggregation: 'monthly',
      sources: selectedSources,
      processType: 'yearOverYear',
    },
    disabled: isDisabled,
  });

  // Get quarterly year over year change by category
  const {
    data: rawQuarterlyYearData,
    error: errorQuarterlyYear,
    isFetching: isFetchingQuarterlyYear,
    downloadCSV: downloadCSVQuarterlyYear,
    copyCSV: copyCSVQuarterlyYear,
  } = useChart({
    id,
    title,
    granularity,
    path,
    exactDates: false,
    latitude,
    longitude,
    radius,
    fromDate,
    toDate,
    customParams: {
      timeAggregation: 'quarterly',
      sources: selectedSources,
      processType: 'yearOverYear',
    },
    disabled: isDisabled,
  });

  // Format data for chart
  const formatData = useCallback(
    (rawData) => {
      const data = rawData?.chartData;
      if (!data || data.length === 0) {
        return [];
      }
      return data.map((data) => ({
        x: data.categoryId
          ? getThreatTypesTranslation(data.categoryId)
          : overallCrime,
        y: normalized ? data.normalizedValue : data.percentage,
        date: data.date,
      }));
    },
    [getThreatTypesTranslation, overallCrime, normalized],
  );

  // Format appropriate data based on period and aggregation specificed
  const formatted = useMemo(() => {
    if (aggregation === 'quarterly' && period === 'yearOverYear') {
      return formatData(rawQuarterlyYearData);
    }
    if (aggregation === 'quarterly' && period === 'periodOverPeriod') {
      return formatData(rawQuarterlyPeriodData);
    }
    if (aggregation === 'monthly' && period === 'yearOverYear') {
      return formatData(rawMonthlyYearData);
    }
    return formatData(rawMonthlyPeriodData);
  }, [
    formatData,
    rawMonthlyPeriodData,
    rawQuarterlyPeriodData,
    rawMonthlyYearData,
    rawQuarterlyYearData,
    aggregation,
    period,
    locale,
  ]);

  // Get appropriate download function based on period and aggregation specified
  const downloadCSV = useMemo(() => {
    if (aggregation === 'quarterly' && period === 'yearOverYear') {
      return downloadCSVQuarterlyYear;
    }
    if (aggregation === 'quarterly' && period === 'periodOverPeriod') {
      return downloadCSVQuarterlyPeriod;
    }
    if (aggregation === 'monthly' && period === 'yearOverYear') {
      return downloadCSVMonthlyYear;
    }
    return downloadCSVMonthlyPeriod;
  }, [
    downloadCSVMonthlyPeriod,
    downloadCSVQuarterlyPeriod,
    downloadCSVMonthlyYear,
    downloadCSVQuarterlyYear,
  ]);

  // Get appropriate copy function based on period and aggregation specified
  const copyCSV = useMemo(() => {
    if (aggregation === 'quarterly' && period === 'yearOverYear') {
      return copyCSVQuarterlyYear;
    }
    if (aggregation === 'quarterly' && period === 'periodOverPeriod') {
      return copyCSVQuarterlyPeriod;
    }
    if (aggregation === 'monthly' && period === 'yearOverYear') {
      return copyCSVMonthlyYear;
    }
    return copyCSVMonthlyPeriod;
  }, [
    copyCSVMonthlyPeriod,
    copyCSVQuarterlyPeriod,
    copyCSVMonthlyYear,
    copyCSVQuarterlyYear,
  ]);

  // Get appropriate isFetching based on period and aggregation specified
  const isFetching = useMemo(() => {
    if (aggregation === 'quarterly' && period === 'yearOverYear') {
      return isFetchingQuarterlyYear;
    }
    if (aggregation === 'quarterly' && period === 'periodOverPeriod') {
      return isFetchingQuarterlyPeriod;
    }
    if (aggregation === 'monthly' && period === 'yearOverYear') {
      return isFetchingMonthlyYear;
    }
    return isFetchingMonthlyPeriod;
  }, [
    isFetchingMonthlyPeriod,
    isFetchingQuarterlyPeriod,
    isFetchingMonthlyYear,
    isFetchingQuarterlyYear,
  ]);

  // Get currentDate from data
  const currentDate = useMemo(() => {
    if (!formatted || formatted.length === 0) {
      return moment();
    }
    return moment(formatted[0].date);
  }, [formatted]);

  // Get startDate as month and year from currentDate translated
  const startDate = useMemo(() => {
    if (!currentDate) {
      return '';
    }
    const monthNumber = currentDate.month();
    const year = currentDate.year();
    return `${months[monthNumber + 1]} ${year}`;
  }, [currentDate, months]);

  // Get endDate as month and year from currentDate translated. If aggregation is quarterly, add 2 months
  const endDate = useMemo(() => {
    if (!currentDate) {
      return '';
    }
    let end = currentDate;
    if (aggregation === 'quarterly') {
      end = end.clone().add(2, 'months');
    }

    const monthNumber = end.month();
    const year = end.year();
    return `${months[monthNumber + 1]} ${year}`;
  }, [currentDate, months]);

  // Get overallChange as average of all values
  const overallChange = useMemo(
    () =>
      Number(formatted.find((d) => overallCrime.includes(d.x))?.y.toFixed(2)) ||
      0,
    [formatted, overallCrime],
  );

  // Get yDomain for chart
  const yDomain = useMemo(() => {
    if (!formatted || formatted.length === 0) {
      return ['-2', '2'];
    }

    // Get min & max y values
    let yMin = Math.min(...formatted.map((d) => d.y));
    let yMax = Math.max(...formatted.map((d) => d.y));
    const absoluteMax = Math.max(Math.abs(yMin), Math.abs(yMax));

    // set min and max thresholds to 25% of absolute max
    const yMaxThreshold = absoluteMax * 0.25;
    const yMinThreshold = absoluteMax * -0.25;

    if (yMin > yMinThreshold) {
      yMin = yMinThreshold;
    }
    if (yMax < yMaxThreshold) {
      yMax = yMaxThreshold;
    }

    return [yMin.toString(), yMax.toString()];
  }, [formatted]);
  return {
    data: formatted,
    isFetching,
    errorMonthlyPeriod,
    errorQuarterlyPeriod,
    errorMonthlyYear,
    errorQuarterlyYear,
    yDomain,
    overallChange,
    startDate,
    endDate,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
