import {
  MAP_BOX_USERNAME,
  MAP_BOX_NI_STYLE_ID,
  MAP_BOX_DARK_STYLE_ID,
  MAP_BOX_SATELLITE_STYLE_ID,
} from '@config';
import {RADIUS_FILL_LAYER} from '@/components/map/layers/constants';

export const LAYER_TYPE = {
  WORLD: 1,
  COUNTRY: 2,
  CITY: 3,
};

export const MAPVIEW_LABEL = {
  mapViewOptionNeighborhoods: 'Provinces/Districts',
  mapViewOptionHybrid: 'Crime Heatmap',
  mapViewOptionPoints: 'Crime Incidents',
};

export const SPARSE_DATES = {
  COMPLETE_DATE: 1,
  DATE_ONLY: 2,
  ONLY_YEARS: 3,
  WITH_MONTHS: 4,
  QUARTERLY: 5,
};

export const LAST_QUINTILE = 5;

export const FIRST_QUINTILE = 1;

export const ZERO = 0;

export const INTERACTIVE_LAYERS = [
  'city-markers-layer',
  'saved-locations-markers-layer',
  'custom-marker-layer',
  'country-boundaries-layer',
  'neighborhoods-boundaries-layer',
  'threat-points-layer',
  'change-points-layer',
  RADIUS_FILL_LAYER,
];

export const MAP_STYLES = {
  map: `mapbox://styles/${MAP_BOX_USERNAME}/${MAP_BOX_NI_STYLE_ID}`,
  dark: `mapbox://styles/${MAP_BOX_USERNAME}/${MAP_BOX_DARK_STYLE_ID}`,
  satellite: `mapbox://styles/${MAP_BOX_USERNAME}/${MAP_BOX_SATELLITE_STYLE_ID}`,
};

export const THREAT_RADIUS_LIMITS = {
  mi: {min: 0.1, max: 5, main: 0.5},
  km: {min: 0.1, max: 8, main: 1},
};
