import PropTypes from 'prop-types';
import {Popup} from 'react-map-gl';
import {styled} from '@mui/material/styles';

function PopupBO({
  latitude,
  longitude,
  onClose,
  onOpen,
  focusAfterOpen,
  maxWidth,
  closeButton,
  closeOnClick,
  closeOnMove,
  offset,
  anchor,
  color,
  tipColor,
  ...props
}) {
  return (
    <Popup
      latitude={latitude}
      longitude={longitude}
      closeButton={closeButton}
      closeOnClick={closeOnClick}
      offset={offset}
      anchor={anchor}
      closeOnMove={closeOnMove}
      focusAfterOpen={focusAfterOpen}
      maxWidth={maxWidth}
      onClose={onClose}
      onOpen={onOpen}
      color={color}
      tipColor={tipColor}
      {...props}
    />
  );
}

const BasePopup = styled(PopupBO)(({color, tipColor, theme}) => ({
  '.mapboxgl-popup-content': {
    background: color,
    boxShadow: `0 10px 2px ${theme.palette.common.black}1A`,
    padding: 0,
    cursor: 'default',
    borderRadius: '6px',
  },
  '.mapboxgl-popup-tip': {
    borderTopColor: tipColor || theme.palette.secondary.dark,
    borderBottomColor: tipColor || theme.palette.secondary.dark,
  },
}));

PopupBO.propTypes = {
  children: PropTypes.node,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  onClose: PropTypes.func,
  closeOnClick: PropTypes.bool,
  captureScroll: PropTypes.bool,
  anchor: PropTypes.string,
  dynamicPosition: PropTypes.bool,
  closeButton: PropTypes.bool,
  offset: PropTypes.array,
  onOpen: PropTypes.func,
  closeOnMove: PropTypes.bool,
  focusAfterOpen: PropTypes.bool,
  maxWidth: PropTypes.string,
  color: PropTypes.string,
  tipColor: PropTypes.string,
};

export default BasePopup;
