export const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 3,
  },
  countryName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingLeft: 2,
    fontWeight: 500,
  },
  description: {
    backgroundColor: 'background.dark',
    fontSize: '14px',
  },
  quintileScore: {
    display: 'inline-block',
    alignSelf: 'flex-start',
    marginBottom: 3,
    paddingX: '8px',
    paddingY: '4px',
    borderRadius: '2px',
  },
  scoreSkeleton: {
    marginBottom: 3,
    height: '60px',
  },
  threatScoreRow: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    py: 1,
    marginBottom: 3,
    borderRadius: 2.5,
    backgroundColor: theme.palette.background.light,
  }),
  threatScore: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginRight: 1,
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  settingsCategory: {
    flexBasis: '40%',
  },
  settingsCount: {
    flexBasis: '10%',
  },
  sliderBox: {
    flexBasis: '50%',
  },
  slider: (theme) => ({
    padding: 0,
    '& .MuiSlider-thumb': {
      height: 16,
      width: 16,
      backgroundColor: theme.palette.action.active,
    },
    '& .MuiSlider-valueLabel': {
      display: 'none',
    },
  }),
  detailsSkeleton: {
    display: 'flex',
    alignItems: 'end',
    gap: 2,
  },
  summarySkeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: 1,
    width: '100%',
  },
};
