import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import {thunk} from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import * as Sentry from '@sentry/react';

import {MODE} from '@config';
import reducers from '@/store/reducers';

export const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
let enhancers = [sentryReduxEnhancer];
const middleware = [thunk, routerMiddleware(history)];

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

if (MODE === 'development') {
  enhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);
} else {
  enhancers = compose(applyMiddleware(...middleware), ...enhancers);
}
const store = createStore(createRootReducer(history), enhancers);

export default store;
