import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import {getFilters, getUserSettings} from '@/selectors';
import {styles} from '@/components/analytics/AnalyticsGroup.styles';

function AnalyticsGroup({
  granularity = 'city',
  analyticsType = 'overview',
  isReport = false,
  components,
  sx,
  setActivePanel,
  ...props
}) {
  const {analyticCharts} = useSelector(getUserSettings);
  const {isGTM} = useSelector(getFilters);
  const getDefaultSettings = useCallback(
    (id) => analyticCharts?.find((settings) => settings.id === id),
    [analyticCharts],
  );

  return (
    <Box sx={isReport ? styles.reportGrid : {}} style={isReport ? sx : {}}>
      {components?.map(({id, component: AnalyticsComponent}) => (
        <AnalyticsComponent
          key={id}
          id={id}
          {...props}
          sx={isReport ? sx : styles.root}
          granularity={granularity}
          analyticsType={analyticsType}
          isReport={isReport}
          setActivePanel={setActivePanel}
          settings={getDefaultSettings(id)}
          isGTM={isGTM}
        />
      ))}
    </Box>
  );
}

AnalyticsGroup.propTypes = {
  granularity: PropTypes.string.isRequired,
  analyticsType: PropTypes.string,
  components: PropTypes.array,
  isReport: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  setActivePanel: PropTypes.func,
};

export default AnalyticsGroup;
