import React from 'react';
import {ScaleControl, NavigationControl} from 'react-map-gl';
import {useSelector} from 'react-redux';

import {useTheme} from '@mui/material';

import {getViewTypeWorld} from '@/selectors';
import {useRouter} from '@/hooks/useRouter';
import {styles} from '@/components/map/navigation/MapControls.styles';

function MapControls() {
  const isWorldView = useSelector(getViewTypeWorld);
  const {isMyLocationsAnalysisRoute} = useRouter();
  const theme = useTheme();

  return (
    <>
      {!isWorldView && !isMyLocationsAnalysisRoute && (
        <>
          <ScaleControl />
          <ScaleControl unit="imperial" />
        </>
      )}
      {isMyLocationsAnalysisRoute && (
        <NavigationControl
          position="bottom-right"
          showCompass={false}
          style={styles.zoomControl(theme)}
        />
      )}
    </>
  );
}

export default MapControls;
