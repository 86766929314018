import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import {getDrawer, getUserSettings} from '@/selectors';

import {styles} from '@/components/views/MyLocationsAnalysisPage.styles';
import RankedLocations from '@/components/saved-locations/tables/RankedLocations';
import NumberOfLocationsByChange from '@/components/saved-locations/charts/NumberOfLocationsByChange';
import PercentageOfChangeByLocation from '@/components/saved-locations/charts/PercentageOfChangeByLocation';
import BreakdownByLocation from '@/components/saved-locations/charts/BreakdownByLocation';
import ChangeMagnitudeMap from '@/components/saved-locations/map/ChangeMagnitudeMap';

function MyLocationsAnalysisPage() {
  const {myLocationsAnalysis} = useSelector(getUserSettings);
  const {isMyLocationsAnalysisFiltersOpen: filtersOpen} =
    useSelector(getDrawer);

  const hideChartOption = useCallback(
    (option) => !myLocationsAnalysis?.locationBreakdown.includes(option),
    [myLocationsAnalysis?.locationBreakdown],
  );

  const rankedLocationsMode = useMemo(
    () =>
      myLocationsAnalysis?.listType === 'cities' ? 'cities' : 'savedLocations',
    [myLocationsAnalysis],
  );

  const components = useMemo(
    () => [
      {
        id: 0,
        xs: 12,
        sm: 12,
        md: filtersOpen ? 12 : 6,
        lg: 6,
        xl: 6,
        component: ChangeMagnitudeMap,
      },
      {
        id: 1,
        xs: 12,
        sm: 12,
        md: filtersOpen ? 12 : 6,
        lg: 6,
        xl: 6,
        component: RankedLocations,
        mode: rankedLocationsMode,
      },
      {
        id: 2,
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        component: NumberOfLocationsByChange,
      },
      {
        id: 3,
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        component: PercentageOfChangeByLocation,
        option: 'type',
        hidden: hideChartOption('type'),
      },
      {
        id: 4,
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        component: BreakdownByLocation,
        option: 'type',
        hidden: hideChartOption('type'),
      },
      {
        id: 5,
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        component: PercentageOfChangeByLocation,
        option: 'tag',
        hidden: hideChartOption('tag'),
      },
      {
        id: 6,
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        component: BreakdownByLocation,
        option: 'tag',
        hidden: hideChartOption('tag'),
      },
    ],
    [rankedLocationsMode, hideChartOption, filtersOpen],
  );

  return (
    <Box sx={styles.root}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {components?.map(
            ({
              id,
              xs,
              sm,
              md,
              lg,
              xl,
              padding,
              component: Component,
              hidden,
              ...props
            }) =>
              !hidden && (
                <Grid
                  item
                  key={id}
                  xs={xs}
                  sm={sm}
                  md={md}
                  lg={lg}
                  xl={xl}
                  sx={styles.grid}>
                  <Component key={id} {...props} />
                </Grid>
              ),
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default MyLocationsAnalysisPage;
