import {useCallback} from 'react';

import {hasChangeFeatures, getChangeFeatures} from '@utils/mapUtils';
import {useMixpanel} from '@hooks/useMixpanel';
import {useMapHandlers} from '@hooks/map/useMapHandlers';
import {useSelector} from 'react-redux';
import {getUserSettings} from '@/selectors';
import {CHANGE_MAP_METRICS} from '@/components/saved-locations/constants';

export const useMouseMove = () => {
  const {track} = useMixpanel();
  const {hideMapTooltip, showMapTooltip} = useMapHandlers();
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);

  const handleMouseMove = useCallback(
    (event) => {
      const {point, target: map} = event;

      const features = map.queryRenderedFeatures(point);

      if (hasChangeFeatures(features)) {
        const {
          properties: {n: name, ...properties},
          geometry: {
            coordinates: [longitude, latitude],
          },
        } = getChangeFeatures(features);
        const change = properties[CHANGE_MAP_METRICS[period][aggregation]];
        showMapTooltip(`${name} / ${change.toFixed(1)}%`, latitude, longitude);
        track('Hover change spot on saved locations map', {
          name,
          percentage: `${change.toFixed(1)}%`,
          latitude,
          longitude,
          period,
          aggregation,
        });
      }
      if (!hasChangeFeatures(features)) {
        hideMapTooltip();
      }
    },
    [aggregation, period, hideMapTooltip, showMapTooltip],
  );
  return {handleMouseMove};
};
