export const SET_USER = 'SET_USER';
export const SET_NEW_LOCATION_TO_SAVE = 'SET_NEW_LOCATION_TO_SAVE';
export const TOGGLE_LOCATIONS_POPUP_STATUS = 'TOGGLE_LOCATIONS_POPUP_STATUS';
export const TOGGLE_ADDING_LOCATION_STATUS = 'TOGGLE_ADDING_LOCATION_STATUS';
export const SET_USER_MAP_STYLE = 'SET_USER_MAP_STYLE';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_INSPECTING_LOCATION_STATUS = 'SET_INSPECTING_LOCATION_STATUS';
export const SET_SAVING_LOCATION_STATUS = 'SET_SAVING_LOCATION_STATUS';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_CLICKED_LOCATION_ID = 'SET_CLICKED_LOCATION_ID';
export const SET_THREAT_ANALYTICS_AREA = 'SET_THREAT_ANALYTICS_AREA';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_UNITS = 'SET_UNITS';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
