import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import LocationCity from '@mui/icons-material/LocationCity';
import {Box} from '@mui/material';

import {setUnmappedMode} from '@/store/modules/map/actions';
import {BaseIconButton} from '@/components/common/buttons';

function ActionFabUnmapped({sx}) {
  const dispatch = useDispatch();

  const onFabLegend = () => {
    dispatch(setUnmappedMode(true));
  };

  return (
    <Box
      sx={(theme) => ({
        ...(typeof sx === 'function' ? sx(theme) : sx),
        backgroundColor: theme.palette.background.dark,
        borderRadius: '6px',
      })}>
      <BaseIconButton onClick={onFabLegend}>
        <LocationCity />
      </BaseIconButton>
    </Box>
  );
}

ActionFabUnmapped.propTypes = {
  sx: PropTypes.object,
};

export default ActionFabUnmapped;
