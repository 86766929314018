import {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {vscDarkPlus} from 'react-syntax-highlighter/dist/cjs/styles/prism';

import {useSelector} from 'react-redux';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
} from '@mui/material';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
  citySettings,
  getViewportBoundingBox,
  getTiles,
  getViewType,
  getLocation,
} from '@/selectors';

import {LAYER_TYPE} from '@/components/map/constants';

import BaseSlideModal from '@/components/common/modals/BaseSlideModal';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';

function LegendDebugRow({property, value = '', code, openDefault = true}) {
  const [open, setOpen] = useState(openDefault);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUp data-testid="legend-debug-arrow-up" />
            ) : (
              <KeyboardArrowDown data-testid="legend-debug-arrow-down" />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {property}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {code ? (
              <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
                {value}
              </SyntaxHighlighter>
            ) : (
              <pre
                data-testid="legend-debug-text-pre"
                style={{textAlign: 'center'}}>
                <code>{value}</code>
              </pre>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

LegendDebugRow.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.bool,
  openDefault: PropTypes.bool,
};

const useStyle = () => ({
  table: (theme) => ({
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
});

function LegendDebug() {
  const styles = useStyle();

  const {getZoom} = useMapboxGL();

  const settings = useSelector(citySettings);
  const {name: locationName} = useSelector(getLocation);
  const viewType = useSelector(getViewType);
  const viewportBoundingBox = useSelector(getViewportBoundingBox);
  const tiles = useSelector(getTiles);

  const zoom = useMemo(() => getZoom(), [getZoom]);

  return (
    <BaseSlideModal
      show
      padding={2}
      style={{overflow: 'auto', minWidth: '400px'}}>
      <TableContainer
        component={Paper}
        style={{maxHeight: '500px'}}
        sx={styles.table}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="center">
                Debugger Mode
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewType === LAYER_TYPE.CITY && (
              <LegendDebugRow
                property="current city/country"
                value={locationName || 'none'}
              />
            )}
            <LegendDebugRow property="zoom level" value={zoom} />
            <LegendDebugRow
              property={`Tiles (${tiles.length})`}
              value={JSON.stringify(
                tiles.map(({x, y, z}) => `x: ${x}, y: ${y}, z: ${z}`),
                null,
                1,
              )}
            />
            {viewType === LAYER_TYPE.CITY && (
              <>
                {Object.keys(settings).map(
                  (key) =>
                    settings[key] && (
                      <LegendDebugRow
                        key={key}
                        property={key}
                        value={JSON.stringify(settings[key], null, 1)}
                        code
                      />
                    ),
                )}
              </>
            )}
            <LegendDebugRow
              property="Viewport bounding box"
              value={JSON.stringify(viewportBoundingBox, null, 1)}
              code
            />
          </TableBody>
        </Table>
      </TableContainer>
    </BaseSlideModal>
  );
}

export default LegendDebug;
