import grey from '@styles/colors/grey';
import {baseTheme} from '@styles/theme/base';

export const reportsTheme = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'light',
    text: {
      primary: grey[2000],
      secondary: grey[2000],
      disabled: grey[500],
    },
    background: {
      default: grey[0],
      paper: grey[50],
      dark: grey[50],
      light: grey[50],
      transparent: 'rgba(0, 0, 0, 0)',
      blur: 'rgba(0, 0, 0, 0.5)',
      contrast: {
        light: grey[400],
        dark: grey[400],
      },
    },
    common: {
      black: grey[2000],
      white: grey[0],
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: false,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: baseTheme.palette.secondary.dark,
          color: baseTheme.palette.secondary.contrastText,
        },
        arrow: {
          color: baseTheme.palette.secondary.dark,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: grey[50],
          '& .Mui-selected': {
            backgroundColor: `${grey[900]}!important`,
          },
        },
      },
    },
  },
};
