import {useSelector} from 'react-redux';

import Mixpanel from '@utils/mixpanel';
import {SENTRY_ENV} from '@config';
import {getOrganization, getUser} from '@/selectors';

export const useMixpanel = () => {
  const {track: trackMixpanel, ...rest} = Mixpanel;
  const user = useSelector(getUser);
  const organization = useSelector(getOrganization);

  const track = (name, props) => {
    trackMixpanel(name, {
      ...props,
      environment: SENTRY_ENV,
      user,
      organization: organization.active,
    });
  };

  return {
    track,
    ...rest,
  };
};
