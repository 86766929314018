import TextField from '@mui/material/TextField';
import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

const useStyles = () => ({
  root: (theme) => ({
    fontSize: '0.875rem',
    // Input Label
    '& label': {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
    },
    // Focused Input Label
    '& label.Mui-focused': {
      fontWeight: 'normal',
    },
    '& .MuiInputBase-input': {
      paddingY: 1,
      paddingX: 2,
    },
    '& .MuiInputAdornment-root': {
      marginTop: '0 !important',
    },
  }),
  input: (theme) => ({
    fontSize: '0.875rem', // Set your desired font size for the label
    '&:before': {
      borderBottom: `1px solid ${theme.palette.background.contrast.dark}`,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${theme.palette.background.contrast.light}`,
    },
    '&:after': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  }),
  label: {
    fontSize: '0.875rem',
  },
  helper: (theme) => ({
    fontSize: '12px',
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  }),
});

const BaseTextField = forwardRef(
  (
    {
      fullWidth = true,
      disabled = false,
      size = 'medium',
      id = '',
      name = '',
      required = false,
      label = '',
      placeholder = '',
      errors = null,
      helperText,
      onChange = () => {},
      onBlur = () => {},
      variant = 'standard',
      sx = {},
      ...rest
    },
    ref,
  ) => {
    const styles = useStyles();

    return (
      <TextField
        {...rest}
        ref={ref}
        id={id}
        name={name}
        required={required}
        fullWidth={fullWidth}
        disabled={disabled}
        size={size}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        variant={variant}
        error={!!errors}
        helperText={errors?.message || helperText}
        FormHelperTextProps={{sx: styles.helper}}
        InputLabelProps={{...rest?.InputLabelProps, sx: styles.label}}
        InputProps={{...rest?.InputProps, sx: styles.input}}
        sx={(theme) => ({
          ...styles.root(theme),
          ...(typeof sx === 'function' ? sx(theme) : sx),
        })}
      />
    );
  },
);

BaseTextField.propTypes = {
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  variant: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

BaseTextField.displayName = 'BaseTextField';

export default BaseTextField;
