import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import {Box, useTheme} from '@mui/material';
import {Prohibit, MapPin} from '@phosphor-icons/react';

import {toggleAddingLocationStatus} from '@store/modules/user/actions';
import {useMixpanel} from '@hooks/useMixpanel';
import {useTranslation} from '@hooks/useTranslation';
import {getUserAddingLocationStatus} from '@/selectors';
import BaseIconButton from '@/components/common/buttons/BaseIconButton';
import {useMapHandlers} from '@/hooks/map/useMapHandlers';

function ActionSaveLocation({sx}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {track} = useMixpanel();
  const {getI18N} = useTranslation();
  const {cancelAddUserLocation} = useMapHandlers();

  const {saveLocation, cancelSaveLocation} = getI18N('tooltips');

  const isAddingLocation = useSelector(getUserAddingLocationStatus);

  const handleAddLocation = (event) => {
    event?.preventDefault();
    dispatch(toggleAddingLocationStatus(true));
    track('Create new Location');
  };

  const handleCancelAddLocation = (event) => {
    event?.preventDefault();
    cancelAddUserLocation();
  };

  return (
    <Box
      sx={(theme) => ({
        ...(typeof sx === 'function' ? sx(theme) : sx),
        backgroundColor: theme.palette.background.dark,
        borderRadius: '6px',
      })}>
      {isAddingLocation ? (
        <BaseIconButton
          icon={Prohibit}
          color={theme.palette.text.primary}
          tooltip={cancelSaveLocation}
          sx={{backgroundColor: 'transparent'}}
          tooltipPlacement="left"
          onClick={handleCancelAddLocation}
        />
      ) : (
        <BaseIconButton
          icon={MapPin}
          color={theme.palette.text.primary}
          tooltip={saveLocation}
          sx={{backgroundColor: 'transparent'}}
          tooltipPlacement="left"
          onClick={handleAddLocation}
        />
      )}
    </Box>
  );
}

ActionSaveLocation.propTypes = {
  sx: PropTypes.object,
};

export default ActionSaveLocation;
