import {
  SET_CATEGORIES,
  TOGGLE_CATEGORY,
  TOGGLE_ALL_CATEGORIES,
  TOGGLE_TIMESLICE,
  TOGGLE_ALL_TIMESLICES,
  TOGGLE_MONTH,
  TOGGLE_ALL_MONTH,
  SET_RANGE,
  TOGGLE_INITIAL_LOADING,
  CHANGE_FILTERS_DATERANGE,
  LOADING_NBHD,
  SET_LAODING_TILE,
  SET_LOADING_TIMESTAMP,
  CHANGE_MINMAX_DATERANGE,
  SET_NEIGHBORHOODS_TIMESTAMP,
  SET_TIMEZONE,
  SET_CRIME_FILTERS,
  SET_VIEWPORT_BOUNDING_BOX,
  SET_LOCATION_SETTINGS,
  SET_LOCATION_FILTERS,
  SET_FILTERS_LOADING,
  TOGGLE_QUARTER,
  TOGGLE_ALL_QUARTERS,
  SET_GOOGLE_VALUE,
  SET_INPUT_VALUE,
  SET_SUGGESTIONS,
  SET_SEARCH_VALUE,
  SET_SEARCH_RESULT,
  TOGGLE_SOURCE,
  TOGGLE_ALL_SOURCES,
  SET_SOURCES,
  SET_ACTIVE_CITY,
  UPDATE_FILTERS,
  SET_LOCATION_LOADING,
  SET_GRANULARITY,
  SET_MAP_LAYERS,
  SET_GTM_STATUS,
} from '@/store/modules/filters/actionTypes';

export const setGTMStatus = (payload) => ({
  type: SET_GTM_STATUS,
  payload,
});

export const setGranularity = (payload) => ({
  type: SET_GRANULARITY,
  payload,
});

export const setLocationLoading = (payload) => ({
  type: SET_LOCATION_LOADING,
  payload,
});

export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS,
  filters,
});

export const setSources = (sources) => ({
  type: SET_SOURCES,
  sources,
});

export const toggleSource = (payload) => ({
  type: TOGGLE_SOURCE,
  payload,
});

export const toggleAllSources = () => ({
  type: TOGGLE_ALL_SOURCES,
});

export const setGoogleValue = (payload) => ({
  type: SET_GOOGLE_VALUE,
  payload,
});

export const setSuggestions = (payload) => ({
  type: SET_SUGGESTIONS,
  payload,
});

export const setInputValue = (payload) => ({
  type: SET_INPUT_VALUE,
  payload,
});

export const setSearchValue = (payload) => ({
  type: SET_SEARCH_VALUE,
  payload,
});
export const setSearchResult = (payload) => ({
  type: SET_SEARCH_RESULT,
  payload,
});

export const setFiltersLoading = (payload) => ({
  type: SET_FILTERS_LOADING,
  payload,
});

export const setLocationFilters = (payload) => ({
  type: SET_LOCATION_FILTERS,
  payload,
});

export const setLocationSettings = (payload) => ({
  type: SET_LOCATION_SETTINGS,
  payload,
});

export const setViewportBoundingBox = (payload) => ({
  type: SET_VIEWPORT_BOUNDING_BOX,
  payload,
});

export const setCrimeFilters = () => ({
  type: SET_CRIME_FILTERS,
});

export const setTimezone = (timezone) => ({
  type: SET_TIMEZONE,
  timezone,
});

export const setRange = (range) => ({
  type: SET_RANGE,
  range,
});

/**
 * @author Juan de Dios
 * @description set the filter categories
 * @param {Object} categories object of filter categories
 */
export const filterSetCategories = (categories) => ({
  type: SET_CATEGORIES,
  categories,
});

export const filterToggleCategory = (category) => ({
  type: TOGGLE_CATEGORY,
  category,
});

export const filterToggleAllCategories = () => ({
  type: TOGGLE_ALL_CATEGORIES,
});

export const filterToggleTimeslice = (timeslice) => ({
  type: TOGGLE_TIMESLICE,
  timeslice,
});

export const filterToggleAllTimeslices = () => ({
  type: TOGGLE_ALL_TIMESLICES,
});

export const setLayers = (payload) => ({
  type: SET_MAP_LAYERS,
  payload,
});

export const filterToggleMonth = (month) => ({
  type: TOGGLE_MONTH,
  month,
});

export const toggleAllMonths = () => ({
  type: TOGGLE_ALL_MONTH,
});

export const toggleQuarter = (payload) => ({
  type: TOGGLE_QUARTER,
  payload,
});

export const toggleAllQuarters = () => ({
  type: TOGGLE_ALL_QUARTERS,
});

export const setInitialLoading = (state = null) => ({
  type: TOGGLE_INITIAL_LOADING,
  state,
});

export const changeFiltersDateRange = (fromDate, toDate) => ({
  type: CHANGE_FILTERS_DATERANGE,
  fromDate,
  toDate,
});

export const changeFiltersMinMaxDateRange = (minDate, maxDate) => ({
  type: CHANGE_MINMAX_DATERANGE,
  maxDate,
  minDate,
});

export const toggleLoadingNBHD = (state = null) => ({
  type: LOADING_NBHD,
  loading: state,
});

export const setLoadingTile = (state = null) => ({
  type: SET_LAODING_TILE,
  loading: state,
});

export const setLoadingTimestamp = (timestamp, tilesetTimestap) => ({
  type: SET_LOADING_TIMESTAMP,
  timestamp,
  tilesetTimestap,
});

export const setNeighborhoodsLoadingTimestamp = (timestamp) => ({
  type: SET_NEIGHBORHOODS_TIMESTAMP,
  timestamp,
});

export const setActiveCity = (payload) => ({
  type: SET_ACTIVE_CITY,
  payload,
});
