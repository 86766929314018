import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {styles} from '@/components/common/cards/BaseCard.style';
import BaseTooltip from '@/components/common/BaseTooltip';

function BaseCard({
  id,
  children,
  title,
  tooltip,
  headerActions,
  footer,
  sx = {},
  sxContent = styles.content,
}) {
  return (
    <Box
      id={id}
      key={id}
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}>
      {(title || headerActions) && (
        <Box sx={styles.header}>
          {title && (
            <Typography variant="body2" sx={styles.title}>
              {title}
              {tooltip && <BaseTooltip message={tooltip} iconSize={14} />}
            </Typography>
          )}
          {headerActions && (
            <Box sx={styles.headerActions}>{headerActions}</Box>
          )}
        </Box>
      )}
      <Box
        sx={(theme) => ({
          ...styles.content,
          ...(typeof sxContent === 'function' ? sxContent(theme) : sxContent),
        })}>
        {children}
      </Box>
      {footer && <Box sx={styles.footer}>{footer}</Box>}
    </Box>
  );
}

BaseCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  headerActions: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  sxContent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BaseCard;
