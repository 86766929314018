import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import html2canvas from 'html2canvas';
import {DownloadSimple} from '@phosphor-icons/react';
import {useCallback} from 'react';

import {useMap} from '@hooks/useMap';
import {useMixpanel} from '@hooks/useMixpanel';
import {useTranslation} from '@hooks/useTranslation';
import {useErrorHandler} from '@hooks/useErrorHandler';
import {getViewTypeWorld, getLocation, getIsMapReady} from '@/selectors';
import BaseIconButton from '@/components/common/buttons/BaseIconButton';

function ActionMapDownload({sx}) {
  const {map} = useMap();
  const {track} = useMixpanel();
  const {getI18N} = useTranslation();
  const {handleGlobalError} = useErrorHandler();

  const {name: locationName} = useSelector(getLocation);
  const isWorldView = useSelector(getViewTypeWorld);
  const isMapReady = useSelector(getIsMapReady);

  const {navbarScreenShot} = getI18N('tooltips');

  const onFabDownloadClick = useCallback(() => {
    if (map && isMapReady) {
      map.setBearing(map.getBearing());
      map.once('render', async () => {
        const currentDate = new Date();
        const cityName = locationName?.replace(', ', '_').replace(' ', '_');
        const screenshotName = isWorldView ? 'World' : cityName;
        const filename = `${screenshotName}_${currentDate.toLocaleDateString()}_${currentDate.toLocaleTimeString()}.png`;

        track('Download Image', {
          city: screenshotName,
          location: locationName,
        });

        try {
          const appDocument = document.querySelector("[id*='map-main']");
          const link = document.createElement('a');
          const appCanvas = await html2canvas(appDocument, {useCORS: true});
          const imageData = appCanvas.toDataURL();

          link.href = imageData;
          link.download = filename;
          link.click();
          link.remove();
        } catch (error) {
          throw handleGlobalError(
            'error capturing or downloading screenshot. Error is',
            error,
          );
        }
      });
    }
  }, [map, isMapReady, isWorldView, locationName]);

  return (
    <BaseIconButton
      icon={DownloadSimple}
      sx={sx}
      tooltip={navbarScreenShot}
      onClick={onFabDownloadClick}
    />
  );
}

ActionMapDownload.propTypes = {
  sx: PropTypes.any,
};

export default ActionMapDownload;
