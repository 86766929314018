import {isEmpty} from 'lodash';

import {
  POPULATION_FILL_LAYER,
  RADIUS_FILL_LAYER,
  THREAT_SCORE_FILL_LAYER,
} from '@/components/map/layers/constants';

export const hasFeatures = (features) => features?.length > 0;

export const hasFeatureCountry = (features) =>
  features?.some(({layer}) => layer.id === 'country-boundaries-layer');

export const hasNeighborhoodState = (features) =>
  features?.some(
    ({layer, state}) =>
      layer.id === 'neighborhoods-boundaries-layer' && !isEmpty(state),
  );

export const hasNeighborhoodFeature = (features) =>
  features?.some(({layer}) => layer.id === 'neighborhoods-boundaries-layer');

export const hasCrimeEventFeature = (features) =>
  features.some(({layer}) => layer.id.includes('Crime Events'));

export const getNeighborhoodFeature = (features) =>
  features.find(({layer}) => layer.id === 'neighborhoods-boundaries-layer');

export const hasCrimeMarkerFeatures = (features) =>
  features?.some(({layer}) => layer.id === 'city-markers-layer');

export const getCityMarkerFeatures = (features) =>
  features.find(({layer}) => layer.id === 'city-markers-layer');

export const getCountryFeature = (features) =>
  features.find(({layer}) => layer.id === 'country-boundaries-layer');

export const hasTimezoneFeature = (features) =>
  features?.some(({layer}) => layer.id === 'timezone-layer');

export const getTimezoneFeature = (features) =>
  features.find(({layer}) => layer.id === 'timezone-layer');

export const hasThreatPointsFeatures = (features) =>
  features?.some(
    ({layer}) =>
      layer.id === 'threat-heatmap-layer' || layer.id === 'threat-points-layer',
  );

export const hasUserLocations = (features) =>
  features?.some(({layer}) => layer.id === 'saved-locations-markers-layer');

export const getUserLocations = (features) =>
  features.find(({layer}) => layer.id === 'saved-locations-markers-layer');

export const hasCustomMarker = (features) =>
  features?.some(({layer}) => layer.id === 'custom-marker-layer');

export const getCustomMarker = (features) =>
  features.find(({layer}) => layer.id === 'custom-marker-layer');

export const hasChangeFeatures = (features) =>
  features?.some(({layer}) => layer.id === 'change-points-layer');

export const getChangeFeatures = (features) =>
  features.find(({layer}) => layer.id === 'change-points-layer');

export const hasThreatRadiusFeatures = (features) =>
  features?.some(({layer}) => layer.id === RADIUS_FILL_LAYER);

export const getThreatRadiusFeatures = (features) =>
  features.find(({layer}) => layer.id === RADIUS_FILL_LAYER);

export const hasThreatScoreFeatures = (features) =>
  features?.some(({layer}) => layer.id === THREAT_SCORE_FILL_LAYER);

export const getThreatScoreFeatures = (features) =>
  features.find(({layer}) => layer.id === THREAT_SCORE_FILL_LAYER);

export const hasPopulationFeatures = (features) =>
  features?.some(({layer}) => layer.id === POPULATION_FILL_LAYER);

export const getPopulationFeatures = (features) =>
  features.find(({layer}) => layer.id === POPULATION_FILL_LAYER);

export const handleClickRadius = (zoom) => {
  let radius = 10;
  if (zoom <= 10) {
    radius = 250;
  } else if (zoom <= 11) {
    radius = 200;
  } else if (zoom <= 12) {
    radius = 150;
  } else if (zoom <= 13) {
    radius = 100;
  } else if (zoom <= 14) {
    radius = 70;
  } else if (zoom <= 15) {
    radius = 50;
  } else if (zoom <= 16) {
    radius = 30;
  } else {
    radius = 10;
  }
  return radius;
};

export function areCoordinatesEqual(coord1, coord2, precision = 5) {
  // check here for undefined before attempting to apply math or unsafe-optional-chaining warning will throw
  if (
    coord1?.lat === undefined ||
    coord2?.latitude === undefined ||
    coord1?.lng === undefined ||
    coord2?.longitude === undefined
  ) {
    return false;
  }

  const tolerance = 10 ** -precision;

  return (
    Math.abs(coord1.lat - coord2.latitude) < tolerance &&
    Math.abs(coord1.lng - coord2.longitude) < tolerance
  );
}
