export const styles = {
  root: {
    width: '100%',
    height: '100%',
    paddingBottom: 2,
    paddingLeft: 1,
    paddingRight: 2,
    borderRadius: 2,
  },
  map: {
    borderRadius: 'inherit',
  },
};
