import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {useTheme} from '@mui/material';

const useStyles = () => ({
  root: (theme) => ({
    padding: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.light,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
});

function BaseIconButton({
  tooltip = '',
  tooltipPlacement = 'bottom',
  color = null,
  icon: Icon,
  size = 32,
  onClick = () => {},
  disabled,
  sx,
  children,
}) {
  const theme = useTheme();
  const styles = useStyles();

  function RenderIcon(props) {
    return Icon ? <Icon {...props} /> : null;
  }

  const buttonSize = `${size.toString()}px`;

  return (
    <Tooltip
      title={tooltip}
      placement={tooltipPlacement}
      data-html2canvas-ignore="true">
      <IconButton
        onClick={onClick}
        disabled={disabled}
        sx={(theme) => ({
          ...styles.root(theme),
          width: buttonSize,
          height: buttonSize,
          ...(typeof sx === 'function' ? sx(theme) : sx),
        })}>
        {children || (
          <RenderIcon
            width={`${(size * 0.7).toFixed(0).toString()}px`}
            height={`${(size * 0.7).toFixed(0).toString()}px`}
            color={!disabled ? color : theme.palette.text.disabled}
          />
        )}
      </IconButton>
    </Tooltip>
  );
}

BaseIconButton.propTypes = {
  sx: PropTypes.any,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  size: PropTypes.number,
  icon: PropTypes.elementType,
  color: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default BaseIconButton;
