import {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_CHANGE_PASSWORD_URL,
} from '@config';
import {useQuery} from '@/hooks/useQuery';

export const useAuth0Service = () => {
  const {
    user,
    error,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  } = useAuth0();
  const query = useQuery();
  const reportAccessToken = query.get('accessToken');
  const [accessToken, setAccessToken] = useState('');

  const getAuth0Token = async () => {
    if (!isAuthenticated) return '';
    const settings = {
      audience: AUTH0_AUDIENCE,
      scope: 'read:current_user',
    };
    try {
      return await getAccessTokenSilently(settings);
    } catch (e) {
      console.error(e);
      if (e.error === 'login_required' || e.error === 'consent_required') {
        return loginWithRedirect(settings);
      }

      throw e;
    }
  };

  const handleAuth0LogOut = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleAuth0Password = async () => {
    try {
      await fetch(AUTH0_CHANGE_PASSWORD_URL, {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({
          client_id: AUTH0_CLIENT_ID,
          email: user?.email,
          connection: 'Username-Password-Authentication',
          organization: user?.org_id,
        }),
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAuth0Token().then(setAccessToken);
    }
  }, [isAuthenticated]);

  return {
    user,
    error,
    isLoading,
    isAuthenticated,
    accessToken,
    reportAccessToken,
    getAuth0Token,
    handleAuth0LogOut,
    handleAuth0Password,
  };
};
