import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {useMixpanel} from '@hooks/useMixpanel';
import {useMapHandlers} from '@hooks/map/useMapHandlers';
import {useApi} from '@hooks/api/useApi';
import {
  getLayers,
  getUserAddingLocationStatus,
  getUserSavingLocationStatus,
  getLocation,
  getUser,
} from '@/selectors';
import {
  hasThreatPointsFeatures,
  getNeighborhoodFeature,
  hasFeatureCountry,
  hasFeatures,
  getCountryFeature,
  hasCrimeMarkerFeatures,
  getCityMarkerFeatures,
  hasNeighborhoodFeature,
  hasNeighborhoodState,
  hasUserLocations,
  getUserLocations,
  hasCustomMarker,
  getCustomMarker,
} from '@/utils/mapUtils';
import {convertDistance, isMac} from '@/utils/utils';
import {THREAT_RADIUS_LIMITS} from '@/components/map/constants';

export const useMapClick = () => {
  const {track} = useMixpanel();
  const {
    clickLocation,
    createUserLocation,
    hideMapTooltip,
    clickDistrict,
    clickThreatPoints,
    clearMapSelection,
    cancelSaveLocation,
    clickSavedLocation,
    highlightCountry,
  } = useMapHandlers();
  const {getSavedLocation} = useApi();

  const {districts: isDistrictsLayer} = useSelector(getLayers);
  const {name: locationName} = useSelector(getLocation);
  const {id: locationId} = useSelector(getLocation);
  const isAddingLocation = useSelector(getUserAddingLocationStatus);
  const isSavingLocation = useSelector(getUserSavingLocationStatus);
  const {units} = useSelector(getUser);

  const handleClick = useCallback(
    (event) => {
      const {
        features,
        lngLat: {lat: latitude, lng: longitude},
        target: map,
        originalEvent: {ctrlKey, metaKey},
      } = event;

      hideMapTooltip();
      highlightCountry();

      if (isAddingLocation) {
        const hood = getNeighborhoodFeature(features);
        const hoodId = hood?.properties?.id;
        const location = {
          locationId: hoodId ? locationId : null,
          hoodId: hoodId ?? null,
          name: 'Temporary location',
          latitude,
          longitude,
          zoom: 13,
          viewType: 'City',
          radiusMeters: convertDistance(
            THREAT_RADIUS_LIMITS[units].main,
            'm',
            units,
          ),
        };
        createUserLocation(location);
        track('User creating saved location', location);
      } else {
        if (!hasFeatures(features)) {
          clearMapSelection();
          highlightCountry();
        }

        if (
          hasFeatureCountry(features) &&
          !hasCrimeMarkerFeatures(features) &&
          !hasUserLocations(features)
        ) {
          const {properties} = getCountryFeature(features);
          const location = {
            id: properties.id,
            name: properties.name,
            type: properties.type,
            latitude: properties.latitude,
            longitude: properties.longitude,
            zoom: properties.default_zoom_level,
          };
          clickLocation(location, map);
          track('User clicked location', {
            ...location,
            location: location.name,
          });
        }

        if (
          hasNeighborhoodFeature(features) &&
          hasNeighborhoodState(features) &&
          !hasCrimeMarkerFeatures(features) &&
          !hasUserLocations(features) &&
          !hasCustomMarker(features) &&
          isDistrictsLayer
        ) {
          const {properties} = getNeighborhoodFeature(features);
          clickDistrict(properties, (ctrlKey && !isMac) || (metaKey && isMac));
          track('User clicked Neighborhood', {
            ...properties,
            location: locationName,
          });
        }

        if (
          hasThreatPointsFeatures(features) &&
          !hasCrimeMarkerFeatures(features) &&
          !hasUserLocations(features) &&
          !hasCustomMarker(features)
        ) {
          const location = {lon: longitude, lat: latitude};
          clickThreatPoints(location);
          track('Threat Event Opened', {
            ...location,
            location: locationName,
          });
        }

        if (hasCrimeMarkerFeatures(features)) {
          const {properties, geometry} = getCityMarkerFeatures(features);
          const location = {
            id: properties.id,
            name: properties.n,
            type: 'City',
            latitude: geometry.coordinates[1],
            longitude: geometry.coordinates[0],
            zoom: properties.z,
          };
          clickLocation(location, map);
          track('User clicked location', {
            location: location.name,
            ...location,
          });
        }

        if (hasUserLocations(features)) {
          const {properties} = getUserLocations(features);
          getSavedLocation.mutate(
            {query: properties.id},
            {onSuccess: (location) => clickSavedLocation(location, map)},
          );
          track('User clicked Saved Location Marker', {id: properties.id});
        }

        if (hasCustomMarker(features) && !hasUserLocations(features)) {
          const {geometry, properties} = getCustomMarker(features);
          const hood = getNeighborhoodFeature(features);
          const hoodId = hood?.properties?.id;
          const lat = geometry.coordinates[1];
          const lon = geometry.coordinates[0];
          const location = {
            locationId: hoodId ? locationId : null,
            hoodId: hoodId ?? null,
            name: properties?.['marker-label'] || 'Temporary location',
            latitude: lat,
            longitude: lon,
            zoom: 13,
            viewType: 'City',
            radiusMeters: convertDistance(
              THREAT_RADIUS_LIMITS[units].main,
              'm',
              units,
            ),
          };
          createUserLocation(location);
          track('User saved select/search Marker', location);
        }

        if (
          !hasUserLocations(features) &&
          !hasCustomMarker(features) &&
          isSavingLocation
        ) {
          cancelSaveLocation();
        }
      }
    },
    [
      isDistrictsLayer,
      isAddingLocation,
      isSavingLocation,
      locationId,
      locationName,
      units,
      clickSavedLocation,
      hideMapTooltip,
      highlightCountry,
      createUserLocation,
      clickLocation,
      clickDistrict,
      clickThreatPoints,
      cancelSaveLocation,
    ],
  );

  const handleRightClick = useCallback(
    (event) => {
      const {
        features,
        lngLat: {lat: latitude, lng: longitude},
      } = event;
      const hood = getNeighborhoodFeature(features);
      const hoodId = hood?.properties?.id;
      const location = {
        locationId: hoodId ? locationId : null,
        hoodId: hoodId ?? null,
        name: 'Temporary location',
        latitude,
        longitude,
        zoom: 13,
        viewType: 'City',
        radiusMeters: convertDistance(
          THREAT_RADIUS_LIMITS[units].main,
          'm',
          units,
        ),
      };
      hideMapTooltip();
      createUserLocation(location);
      track('User creating saved location', location);
    },
    [locationId, units, createUserLocation, hideMapTooltip],
  );

  return {handleClick, handleRightClick};
};
