import isArray from 'lodash/isArray';

export const getBrowserLanguage = () => {
  let language;

  if (navigator.languages && navigator.languages.length) {
    [language] = navigator.languages;
  } else {
    language =
      navigator.userLanguage || navigator.language || navigator.browserLanguage;
  }

  const supported = ['en_US', 'pt_BR', 'it_IT', 'es_MX', 'fr_FR'];

  if (language) {
    const trLang = language.replace('-', '_');
    if (supported.includes(trLang)) return trLang;
  }

  return 'en_US';
};

export const compareLetter = (locale) =>
  new Intl.Collator(locale.replace('_', '-')).compare;

// eslint-disable-next-line no-promise-executor-return
export const wait = (seconds) => new Promise((res) => setTimeout(res, seconds));

export const getObjectValues = (values) => {
  if (isArray(values)) return values;

  return Object.keys(values)
    .filter((val) => values[val])
    .map(Number);
};

export const buildQueryParams = (params) => {
  const filteredParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key]) filteredParams[key] = params[key];
  });

  return `?${new URLSearchParams(filteredParams).toString()}`;
};

/**
 * This function converts a distance from current units to target units. The default current units are meters, so if only 2 arguments are provided it will be converting from meters to the target units.
 * @param {*} radius
 * @param {*} prefferedUnits
 * @param {*} currentUnits
 * @returns distance in target units
 * @example
 * convertUnits(1000, 'km') // 1
 * convertUnits(1, 'km', 'mi') // 1.60934
 * convertUnits(1, 'mi', 'km') // 0.621371
 */
export const convertDistance = (distance, targetUnits, currentUnits = 'm') => {
  if (targetUnits === currentUnits) return distance;

  const miles = ['mi', 'miles'];
  const kilometers = ['km', 'kilometers'];
  const meters = ['m', 'meters'];

  let finalDistance = distance;

  if (meters.includes(targetUnits)) {
    if (miles.includes(currentUnits)) finalDistance = distance * 1609.34;
    if (kilometers.includes(currentUnits)) finalDistance = distance * 1000;
  } else if (miles.includes(targetUnits)) {
    if (meters.includes(currentUnits)) finalDistance = distance / 1609.34;
    if (kilometers.includes(currentUnits)) finalDistance = distance / 1.60934;
  } else if (kilometers.includes(targetUnits)) {
    if (meters.includes(currentUnits)) finalDistance = distance / 1000;
    if (miles.includes(currentUnits)) finalDistance = distance * 1.60934;
  }

  return Math.round(finalDistance * 100) / 100;
};

export const convertArea = (area, targetUnits, currentUnits = 'm') => {
  if (targetUnits === currentUnits) return area;

  let finalArea = area;

  if (targetUnits === 'm') {
    if (currentUnits === 'mi') finalArea = area * 2590000;
    if (currentUnits === 'km') finalArea = area * 1000000;
  } else if (targetUnits === 'mi') {
    if (currentUnits === 'm') finalArea = area / 2590000;
    if (currentUnits === 'km') finalArea = area / 2.59;
  } else if (targetUnits === 'km') {
    if (currentUnits === 'm') finalArea = area / 1000000;
    if (currentUnits === 'mi') finalArea = area * 2.59;
  }

  return Math.round(finalArea * 100) / 100;
};

export const arrayToFilter = (array) =>
  array.map(Number).reduce((obj, key) => {
    obj[key] = true;
    return obj;
  }, {});

export const getOS = () =>
  navigator?.userAgentData?.platform ?? navigator?.userAgent ?? '';

export const isWindows = getOS().toLowerCase().includes('win');

export const isMac = getOS().toLowerCase().includes('mac');
