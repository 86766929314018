import {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {Collapse, Divider, Typography, useTheme} from '@mui/material';
import {CaretCircleDown, CaretCircleUp} from '@phosphor-icons/react';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import QuintileColorScale from '@/components/analytics/quintile/QuintileColorScale';
import {styles} from '@/components/analytics/quintile/QuintilesGroup.styles';

function QuintilesGroup({
  options,
  columns,
  isLoading = false,
  defaultExpanded = false,
  isReport = false,
}) {
  const theme = useTheme();

  const [expanded, setExpanded] = useState([]);

  const parentOptions = useMemo(() => {
    const parents = options.filter((option) => option?.parentId === null);
    if (defaultExpanded) {
      setExpanded(
        parents
          ?.map((parent) => parent.id)
          .slice(0, isReport ? 2 : parents?.length),
      );
    }
    return parents;
  }, [options]);

  const childOptions = useMemo(
    () => options.filter((option) => option?.parentId !== null),
    [options],
  );

  const getChildren = (parentId) =>
    childOptions.filter((option) => option?.parentId === parentId) || [];

  const hasChildren = (parentId) => getChildren(parentId).length > 0;

  const handleExpand = (id) => {
    if (!hasChildren(id)) return;
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((expandedId) => expandedId !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };

  if (isLoading) {
    return (
      <Box>
        {[1, 2, 3, 4, 5, 6].map((i) => (
          <BaseSkeleton
            key={i}
            width="100%"
            height="37.91px"
            sx={{marginBottom: 0.5}}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box sx={styles.root}>
      {parentOptions.map((parent) => (
        <Box key={`quintile-group-header-${parent.id}`} sx={styles.parent}>
          <Box sx={styles.optionHeader}>
            <Box
              sx={styles.parentItem}
              style={{padding: !isReport ? '7px 16px' : 0}}>
              {columns.map((column) =>
                column.type === 'quintile' ? (
                  <Box
                    key={`${parent.id}-${column.id}`}
                    flexBasis={column.width}
                    textAlign={column.textAlign}>
                    <QuintileColorScale quintile={parent[column.id]} />
                  </Box>
                ) : (
                  <Typography
                    key={`${parent.id}-${column.id}`}
                    variant="caption"
                    flexBasis={column.width}
                    textAlign={column.textAlign}
                    fontWeight={isReport ? 'bold' : 'normal'}>
                    {parent[column.id]}
                  </Typography>
                ),
              )}
            </Box>
            {!isReport && (
              <Box sx={styles.iconContainer}>
                {expanded.includes(parent.id) ? (
                  <CaretCircleUp
                    size={21}
                    style={styles.icon(!hasChildren(parent.id), theme)}
                    onClick={() => handleExpand(parent.id)}
                  />
                ) : (
                  <CaretCircleDown
                    size={21}
                    style={styles.icon(!hasChildren(parent.id), theme)}
                    onClick={() => handleExpand(parent.id)}
                  />
                )}
              </Box>
            )}
          </Box>
          <Collapse in={expanded.includes(parent.id)}>
            {!isReport && <Divider sx={styles.divider} />}
            <Box sx={styles.collapsibleBox}>
              {getChildren(parent.id).map((children) => (
                <Box
                  key={children.id}
                  sx={styles.childItem}
                  style={isReport ? styles.compactChildItem : {}}>
                  {columns.map((column) =>
                    column.type === 'quintile' ? (
                      <Box
                        key={`${children.id}-${column.id}`}
                        flexBasis={column.width}
                        textAlign={column.textAlign}>
                        <QuintileColorScale quintile={children[column.id]} />
                      </Box>
                    ) : (
                      <Typography
                        key={`${children.id}-${column.id}`}
                        variant="caption"
                        fontWeight="normal"
                        flexBasis={column.width}
                        textAlign={column.textAlign}>
                        {children[column.id]}
                      </Typography>
                    ),
                  )}
                </Box>
              ))}
            </Box>
          </Collapse>
        </Box>
      ))}
    </Box>
  );
}

QuintilesGroup.propTypes = {
  options: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      width: PropTypes.string,
      textAlign: PropTypes.string,
      type: PropTypes.oneOf(['text', 'quintile']),
    }).isRequired,
  ),
  isLoading: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  isReport: PropTypes.bool,
};

export default QuintilesGroup;
