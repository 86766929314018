import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import isEqual from 'lodash/isEqual';

import CircularProgress from '@mui/material/CircularProgress';
import {Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {DownloadSimple} from '@phosphor-icons/react';

import {styles} from '@report/ReportDownloadModal.styles';
import {getReportDownloadModalStatus} from '@/selectors';
import {setReportModalOpen} from '@/store/modules/modal/actions';

import BaseButton from '@/components/common/buttons/BaseButton';
import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import BaseModal from '@/components/common/modals/BaseModal';
import BaseTextField from '@/components/common/inputs/BaseTextField';
import BaseRadio from '@/components/common/checkboxes/BaseRadio';
import ReportCustomBuilder from '@/components/report/ReportCustomBuilder';

import {useTranslation} from '@/hooks/useTranslation';
import {useReport} from '@/hooks/api/useReport';
import {useSnackbar} from '@/hooks/useSnackbar';
import {useFeatureAccess} from '@/hooks/useFeatureAccess';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';

function ReportDownloadModal({granularities, availableReports}) {
  const dispatch = useDispatch();
  const {locale, getI18N} = useTranslation();
  const {showSnackbar} = useSnackbar();
  const {getFeatureAccess} = useFeatureAccess();

  const isOpen = useSelector(getReportDownloadModalStatus);

  const {
    reportNameLabel,
    defaultReport,
    defaultReportName,
    onePageReport,
    customReport,
    summaryPageLabel,
    changeAnalysisLabel,
    threatAnalysisLabel,
    dataSourcesLabel,
    trendAnalysisLabel,
    districtAnalysisLabel,
    granularitySubtitle,
    downloadLabel,
    downloadMessage,
  } = getI18N('reportDownloadModal');
  const hideCustomReport = getFeatureAccess('custom-report', 'hide');

  const [selectedReportType, setSelectedReportType] = useState('default');
  const [granularity, setGranularity] = useState('radius');
  const [reportName, setReportName] = useState(defaultReportName);
  const [customOptions, setCustomOptions] = useState([]);

  const {isLoading, requestReport} = useReport({
    name: reportName,
    format: 'pptx',
    reportType: selectedReportType,
    customOptions,
    onSuccess: () => {
      setReportName(defaultReportName);
      dispatch(setReportModalOpen(false));
      showSnackbar({
        iconColor: 'success',
        icon: 'check',
        message: downloadMessage,
      });
    },
  });

  const availableGranularities = useMemo(
    () =>
      granularities.map((granularity) => {
        const availableReportType = availableReports?.find(
          (report) => report.type === selectedReportType,
        );
        const isDisabled = !availableReportType?.granularities?.includes(
          granularity.value,
        );
        return {
          ...granularity,
          disabled: isDisabled || granularity.disabled,
        };
      }),
    [granularities, availableReports, selectedReportType],
  );

  const reportTypes = useMemo(() => {
    const all = [
      {
        label: defaultReport,
        value: 'default',
      },
      {
        label: onePageReport,
        value: 'onePage',
      },
      {
        label: customReport,
        value: 'custom',
      },
    ];
    return all.filter((report) => {
      if (report.value === 'custom') {
        return !hideCustomReport;
      }
      return true;
    });
  }, [customReport, defaultReport, hideCustomReport, onePageReport]);

  const reportPages = useMemo(
    () => [
      {
        label: summaryPageLabel,
        value: 'summary',
      },
      {
        label: changeAnalysisLabel,
        value: 'changeAnalysis',
      },
      {
        label: threatAnalysisLabel,
        value: 'threatBreakdown',
      },
      {
        label: districtAnalysisLabel,
        value: 'districtAnalysis',
      },
      {
        label: trendAnalysisLabel,
        value: 'trendAnalysis',
      },
      {
        label: dataSourcesLabel,
        value: 'dataSourceInsights',
      },
    ],
    [locale],
  );

  const handleNameChange = (event) => {
    setReportName(event.target.value);
  };

  const handleCloseModal = useCallback(() => {
    dispatch(setReportModalOpen(false));
  }, [dispatch]);

  const handleGranularity = useCallback(
    (granularity) => {
      setGranularity(granularity);
      if (
        selectedReportType === 'onePage' ||
        selectedReportType === 'default'
      ) {
        setCustomOptions([{granularity}]);
      } else {
        setCustomOptions([]);
      }
    },
    [selectedReportType],
  );

  const handleReportPages = (report) => {
    setCustomOptions((options) => {
      const existingIndex = options.findIndex(
        (option) => option.granularity === report.granularity,
      );
      if (existingIndex !== -1) {
        if (!report.enabled) {
          return options.filter(
            (option) => option.granularity !== report.granularity,
          );
        }
        if (isEqual(options[existingIndex], report)) {
          return options;
        }
        const updatedOptions = [...options];
        updatedOptions[existingIndex] = report;
        return updatedOptions;
      }
      return report.enabled ? [...options, report] : options;
    });
  };

  const handleDownload = () => {
    requestReport();
  };

  useEffect(() => {
    if (selectedReportType === 'default' || selectedReportType === 'onePage') {
      handleGranularity(
        availableGranularities?.find(({disabled}) => !disabled)?.value ??
          'radius',
      );
    }
  }, [selectedReportType, availableGranularities, handleGranularity]);

  return (
    <BaseModal sx={styles.root} open={isOpen} onClose={handleCloseModal}>
      <Box sx={styles.content}>
        <BaseTextField
          fullWidth
          id="reportName"
          name="reportName"
          required
          label={reportNameLabel}
          variant="standard"
          size="medium"
          value={reportName}
          onChange={handleNameChange}
          sx={styles.reportNameInput}
        />
        <Grid container spacing={4}>
          {reportTypes.map((report) => (
            <Grid
              item
              xs={12 / reportTypes.length}
              key={report.value}
              sx={styles.granularityGrid}>
              <BaseRadio
                label={report.label}
                labelPlacement="start"
                checked={report.value === selectedReportType}
                onChange={() => setSelectedReportType(report.value)}
                sx={(theme) =>
                  styles.reportType(theme, report.value, selectedReportType)
                }
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={4}>
          {selectedReportType === 'default' && (
            <Grid item xs={12 / reportTypes.length}>
              <Typography variant="body2" sx={styles.onePageTitle}>
                {granularitySubtitle}
              </Typography>
              <Box sx={styles.onePageGranularity}>
                <BaseRadioGroup
                  id="default-report-download-radio-group"
                  columns={3}
                  spacing={2}
                  onChange={handleGranularity}
                  selected={granularity}
                  options={availableGranularities}
                />
              </Box>
            </Grid>
          )}
          {selectedReportType === 'onePage' && (
            <>
              <Grid item xs={12 / reportTypes.length} />
              <Grid item xs={12 / reportTypes.length}>
                <Typography variant="body2" sx={styles.onePageTitle}>
                  {granularitySubtitle}
                </Typography>
                <Box sx={styles.onePageGranularity}>
                  <BaseRadioGroup
                    id="one-page-report-download-radio-group"
                    columns={3}
                    spacing={2}
                    onChange={handleGranularity}
                    selected={granularity}
                    options={availableGranularities}
                  />
                </Box>
              </Grid>
            </>
          )}
          {selectedReportType === 'custom' && (
            <FeatureAccessWrapper feature="custom-report" action="hide">
              {availableGranularities.map((granularity) => (
                <Grid item key={granularity.value} xs={12 / reportTypes.length}>
                  <ReportCustomBuilder
                    granularity={granularity}
                    reportPages={reportPages}
                    onChange={handleReportPages}
                  />
                </Grid>
              ))}
            </FeatureAccessWrapper>
          )}
        </Grid>
      </Box>
      <Box sx={styles.buttonContainer}>
        <BaseButton
          sx={styles.downloadButton}
          disabled={customOptions.length === 0 || isLoading}
          onClick={handleDownload}>
          {isLoading ? (
            <CircularProgress sx={styles.circularProgress} size={16} />
          ) : (
            <DownloadSimple style={styles.downloadButtonIcon} />
          )}
          {downloadLabel}
        </BaseButton>
      </Box>
    </BaseModal>
  );
}

ReportDownloadModal.propTypes = {
  granularities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  availableReports: PropTypes.arrayOf(PropTypes.object),
};

export default ReportDownloadModal;
