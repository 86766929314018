import {SET_THEME} from '@/store/modules/theme/actionTypes';

export const initialState = 'dark';

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THEME:
      return action.theme;
    default:
      return state;
  }
};

export default themeReducer;
