import React from 'react';
import PropTypes from 'prop-types';

import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

const useStyles = ({right, left, top, bottom, height, width, padding}) => ({
  wrapper: (theme) => {
    const wrapperStyles = {
      backgroundColor: theme.palette.background.default,
      position: 'absolute',
      zIndex: 99999999,
      padding: 10,
      overflow: 'scroll',
    };

    if (height) {
      wrapperStyles.height = height;
    }

    if (width) {
      wrapperStyles.width = width;
    }

    if (padding) {
      wrapperStyles.padding = padding;
    }

    if (top && !bottom) {
      wrapperStyles.top = top;
    }

    if (bottom) {
      wrapperStyles.bottom = bottom;
    }

    if (right && !left) {
      wrapperStyles.right = right;
    }

    if (left) {
      wrapperStyles.left = left;
    }

    return wrapperStyles;
  },
});

function BaseSlideModal({
  show = false,
  right = 10,
  left = 0,
  top = 10,
  bottom = 0,
  height,
  width,
  padding = 10,
  onClose = () => {},
  children = <Box />,
  ...props
}) {
  const styles = useStyles({right, left, top, bottom, height, width, padding});

  return (
    <Slide direction="left" in={show}>
      <Box
        {...props}
        sx={styles.wrapper}
        data-testid="base-slide-modal-content"
        onClick={() => onClose()}>
        {children}
      </Box>
    </Slide>
  );
}

BaseSlideModal.propTypes = {
  show: PropTypes.bool,
  right: PropTypes.number,
  left: PropTypes.number,
  top: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  bottom: PropTypes.number,
  padding: PropTypes.number,
  onClose: PropTypes.func,
  children: PropTypes.any,
};

export default BaseSlideModal;
