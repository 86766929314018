export const SET_MAP_TOOLTIP = 'SET_MAP_TOOLTIP';
export const SET_MARKER = 'SET_MARKER';
export const SET_SEARCH_MARKER = 'SET_SEARCH_MARKER';
export const SET_LOADING_MAP = 'SET_LOADING_MAP';
export const SET_DEBUG_MODE = 'SET_DEBUG_MODE';
export const SET_UNMAPPED_MODE = 'SET_UNMAPPED_MODE';
export const SET_VIEWTYPE = 'SET_VIEWTYPE';
export const SET_LOCATION_ADDRESS = 'SET_LOCATION_ADDRESS';
export const SET_IS_FLYING = 'SET_IS_FLYING';
export const SET_CRIME_EVENTS_COUNT = 'SET_CRIME_EVENTS_COUNT';
export const SET_TILES = 'SET_TILES';
export const SET_ROUTED_TO_MAP = 'SET_ROUTED_TO_MAP';
export const SET_MAP_MOUNTED_STATUS = 'SET_MAP_MOUNTED_STATUS';
export const SET_MAP_VIEWPORT = 'SET_MAP_VIEWPORT';
export const SET_SELECTED_CITY = 'SET_SELECTED_CITY';
