import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {
  getNeighborhoodFeature,
  hasFeatureCountry,
  hasFeatures,
  getCountryFeature,
  hasCrimeMarkerFeatures,
  getCityMarkerFeatures,
  hasNeighborhoodFeature,
  hasNeighborhoodState,
  hasUserLocations,
  getUserLocations,
  hasThreatRadiusFeatures,
  getThreatRadiusFeatures,
  hasThreatScoreFeatures,
  getThreatScoreFeatures,
  getPopulationFeatures,
  hasPopulationFeatures,
} from '@utils/mapUtils';
import {useMixpanel} from '@hooks/useMixpanel';
import {useMapHandlers} from '@hooks/map/useMapHandlers';
import {getLayers} from '@/selectors';
import {useTranslation} from '@/hooks/useTranslation';

export const useMapMouseMove = () => {
  const {track} = useMixpanel();
  const {getI18N} = useTranslation();
  const {hideMapTooltip, showMapTooltip, highlightCountry, highlightRadius} =
    useMapHandlers();

  const {districts: isDistrictsLayer} = useSelector(getLayers);
  const {threatScore, population} = getI18N('tooltips');

  const handleMouseHover = useCallback(
    ({features, event: {latitude, longitude}}) => {
      if (
        !hasFeatures(features) ||
        (hasNeighborhoodFeature(features) && !isDistrictsLayer)
      ) {
        hideMapTooltip();
        highlightCountry();
        highlightRadius();
      }

      if (hasThreatRadiusFeatures(features)) {
        const {id} = getThreatRadiusFeatures(features);
        highlightRadius(id);
      }

      if (hasCrimeMarkerFeatures(features)) {
        const {properties, geometry} = getCityMarkerFeatures(features);
        showMapTooltip(
          properties.n,
          geometry.coordinates[1],
          geometry.coordinates[0],
        );
      }

      if (
        hasNeighborhoodFeature(features) &&
        hasNeighborhoodState(features) &&
        !hasCrimeMarkerFeatures(features) &&
        !hasUserLocations(features) &&
        isDistrictsLayer
      ) {
        const {properties} = getNeighborhoodFeature(features);
        showMapTooltip(properties.displayName, latitude, longitude);
      }

      if (
        hasFeatureCountry(features) &&
        !hasCrimeMarkerFeatures(features) &&
        !hasUserLocations(features)
      ) {
        const {id, properties} = getCountryFeature(features);
        highlightCountry(id);
        showMapTooltip(properties.name, latitude, longitude);
      }

      if (hasUserLocations(features)) {
        const {properties, geometry} = getUserLocations(features);
        showMapTooltip(
          properties.n,
          geometry.coordinates[1],
          geometry.coordinates[0],
        );
        track('Hover User Saved Location Marker');
      }

      if (
        hasThreatScoreFeatures(features) &&
        !hasCrimeMarkerFeatures(features) &&
        !hasUserLocations(features)
      ) {
        const {properties} = getThreatScoreFeatures(features);
        showMapTooltip(
          `${threatScore}: ${properties.tsd}`,
          latitude,
          longitude,
        );
      }

      if (
        hasPopulationFeatures(features) &&
        !hasCrimeMarkerFeatures(features) &&
        !hasUserLocations(features)
      ) {
        const {properties} = getPopulationFeatures(features);
        showMapTooltip(`${population}: ${properties.p}`, latitude, longitude);
      }

      if (
        !hasFeatureCountry(features) &&
        !hasCrimeMarkerFeatures(features) &&
        !hasNeighborhoodFeature(features) &&
        !hasUserLocations(features) &&
        !hasThreatRadiusFeatures(features) &&
        !hasThreatScoreFeatures(features) &&
        !hasPopulationFeatures(features)
      ) {
        hideMapTooltip();
        highlightRadius();
        highlightCountry();
      }
    },
    [hideMapTooltip, showMapTooltip, highlightCountry, isDistrictsLayer],
  );
  return {handleMouseHover};
};
