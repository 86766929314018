import axios from 'axios';

import {useAuth0Service} from '@/hooks/useAuth0Service';
import {validateRequest} from '@/utils/httpUtils';

export const useHttpClient = () => {
  const {getAuth0Token, reportAccessToken, isAuthenticated} = useAuth0Service();

  const httpSettings = async (request) => {
    let token;
    if (reportAccessToken && !isAuthenticated) {
      token = reportAccessToken;
    } else {
      token = await getAuth0Token();
    }
    return validateRequest(request, token);
  };

  const get = async (request) => {
    const {url, headers, params, signal} = await httpSettings(request);
    const {data} = await axios.get(url, {signal, headers, params});
    return data;
  };

  const post = async (request) => {
    const {url, headers, body, params, signal} = await httpSettings(request);
    const {data} = await axios.post(url, body, {signal, headers, params});
    return data;
  };

  const put = async (request) => {
    const {url, headers, body, params} = await httpSettings(request);
    const {data} = await axios.put(url, body, {headers, params});
    return data;
  };

  const patch = async (request) => {
    const {url, headers, body, params} = await httpSettings(request);
    const {data} = await axios.patch(url, body, {headers, params});
    return data;
  };

  const deletes = async (request) => {
    const {url, headers, body, params} = await httpSettings(request);
    const {data} = await axios.delete(url, {headers, data: body, params});
    return data;
  };

  return {
    get,
    post,
    put,
    patch,
    deletes,
  };
};
