import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import {
  API_EVENT_BREAKDOWN,
  API_EVENT_BREAKDOWN_RADIUS,
} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import {useTranslation} from '@/hooks/useTranslation';

import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import EventBreakdown from '@/components/analytics/charts/EventBreakdown';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';

function EventBreakdownSection({id, granularity, sx, isReport, settings}) {
  const [chartType, setChartType] = useState('categories');
  const {locale, getI18N} = useTranslation();
  const {all, primaryCategories, subCategories} = getI18N('chartSelection');

  const chartTypeOptions = useMemo(
    () => [
      {
        label: all,
        value: 'all',
      },
      {
        label: primaryCategories,
        value: 'categories',
      },
      {
        label: subCategories,
        value: 'subCategories',
      },
    ],
    [locale],
  );

  const title = getI18N('threatAnalytics.chartTitle3');

  const downloadTitle = useMemo(() => {
    if (chartType === 'all') return `${title} - ${all}`;
    if (chartType === 'categories') return `${title} - ${primaryCategories}`;
    if (chartType === 'subCategories') return `${title} - ${subCategories}`;
  }, [chartType, locale]);

  const path = useMemo(
    () =>
      granularity === 'radius'
        ? API_EVENT_BREAKDOWN_RADIUS
        : API_EVENT_BREAKDOWN,
    [granularity],
  );

  const {
    data,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title: downloadTitle,
    path,
    granularity,
    customParams: {
      filterBy: chartType,
    },
  });

  useEffect(() => {
    setChartType(settings.metric);
  }, [settings]);

  if (isReport)
    return (
      <ReportChartWrapper isLoading={isFetching} error={Boolean(error)}>
        <EventBreakdown
          height={sx.height}
          width={sx.width}
          type={chartType}
          data={data}
          isLoading={isFetching}
        />
      </ReportChartWrapper>
    );

  if (error) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="eventBreakdown"
        downloadCSV={downloadCSV}
        copyCSV={copyCSV}
        downloadImage={downloadImage}
        copyImage={copyImage}
        chart={
          <EventBreakdown type={chartType} data={data} isLoading={isFetching} />
        }
        controls={
          <BaseRadioGroup
            dense
            onChange={(value) => setChartType(value)}
            selected={chartType}
            options={chartTypeOptions}
          />
        }
        settings={{id, metric: chartType}}
      />
    </Box>
  );
}

EventBreakdownSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isReport: PropTypes.bool,
  settings: PropTypes.object,
};

export default EventBreakdownSection;
