import moment from 'moment-timezone';
import {SPARSE_DATES} from '@/components/map/constants';

const toMoment = (date, timezone) =>
  timezone ? moment(date).tz(timezone) : moment(date);

const isOnlyYearsSparseDates = (type) => type === SPARSE_DATES.ONLY_YEARS;

const isSparseDatesWithMonths = (type) => type === SPARSE_DATES.WITH_MONTHS;

const isSparseDatesQuarterly = (type) => type === SPARSE_DATES.QUARTERLY;

export {
  isOnlyYearsSparseDates,
  isSparseDatesWithMonths,
  toMoment,
  isSparseDatesQuarterly,
};
