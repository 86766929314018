import MUIgrey from '@mui/material/colors/grey';

const grey = {
  ...MUIgrey,
  /**
   * Custom: ![white] white (https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  0: '#ffffff',
  /**
   * Custom: ![grey 50](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  50: '#fafafa',
  /**
   * Custom: ![grey 400](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  400: '#E8E8F1',
  /**
   * Custom: ![grey 500](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  500: '#B1B1C3',
  /**
   * Custom: ![grey 600](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  600: '#7F7F8C',
  /**
   * Custom: ![grey 700](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  700: '#52525B',
  /**
   * Custom: ![grey 800](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  800: '#303036',
  /**
   * Custom: ![grey 900](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  900: '#202025',
  /**
   * Custom: ![grey 1000](https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  1000: '#151518',
  /**
   * Custom: ![black] black (https://www.figma.com/design/E3NlqgV7HkYBKrJrwhR3fI/Base-Operations---Design-System-2024?node-id=7767-1253&t=XPkQjIfjjZ4x30TG-0)
   */
  2000: '#080808',
};

export default grey;
