import React, {useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

// Icons
import {Info} from '@phosphor-icons/react';

// Base Components

// styles

// MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';

// Hooks
import {useTranslation} from '@hooks/useTranslation';
import {styles} from '@/components/user/key-management/KeyManagementModal.styles';
import BaseButton from '@/components/common/buttons/BaseButton';
import BaseTextField from '@/components/common/inputs/BaseTextField';
import BaseModal from '@/components/common/modals/BaseModal';

function KeyManagementModal({
  title = null,
  open = false,
  apiKey = {},
  handleClose = () => {},
  onSubmit = () => {},
  status = 'create',
}) {
  const theme = useTheme();
  const copyRef = useRef(null);
  const {getI18N} = useTranslation();

  const {
    validationLabel,
    textFieldLabel,
    warningText,
    copyLabel,
    deleteLabel,
    createLabel,
    deleteText,
    maxValidation,
  } = getI18N('user');

  const isCreating = useMemo(() => status === 'create', [status]);
  const isCopying = useMemo(() => status === 'copy', [status]);
  const isDeleting = useMemo(() => status === 'delete', [status]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, maxValidation).required(validationLabel),
  });

  const {
    register,
    handleSubmit,
    formState: {errors, isDirty},
    reset,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {name: ''},
  });

  useEffect(() => {
    reset({name: apiKey.name ?? ''});
  }, [apiKey]);

  const copyToClipboard = () => {
    if (copyRef.current) {
      copyRef.current.select();
      navigator.clipboard.writeText(copyRef.current.value);
      onSubmit();
    }
  };

  const handleFocus = (event) => event.target.select();

  return (
    <BaseModal title={title} open={open} onClose={handleClose}>
      <Grid container spacing={2} sx={styles.grid}>
        <Grid item xs={12}>
          {isCreating && (
            <BaseTextField
              control={control}
              fullWidth
              id="name"
              name="name"
              required
              label={textFieldLabel}
              variant="standard"
              size="medium"
              errors={errors.name}
              {...register('name')}
            />
          )}
          {isCopying && (
            <>
              <BaseTextField
                autoFocus
                fullWidth
                hiddenLabel
                id="secret"
                name="secret"
                variant="standard"
                size="medium"
                inputRef={copyRef}
                value={apiKey.secret}
                errors={errors.title}
                onFocus={handleFocus}
                sx={styles.secretTextField}
              />
              <Box sx={styles.warningContainer}>
                <Box>
                  <Info size={32} color={theme.palette.primary.main} />
                </Box>
                <Box sx={styles.warningText}>
                  <Typography variant="caption">{warningText}</Typography>
                </Box>
              </Box>
            </>
          )}
          {isDeleting && (
            <Box sx={styles.warningContainer}>
              <Box sx={styles.deleteIcon}>
                <Info size={32} color={theme.palette.error.main} />
              </Box>
              <Box sx={styles.deleteText}>
                <Typography variant="caption">
                  {deleteText} &quot;{apiKey.name}&quot;
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box sx={styles.actionsContainer}>
        <Box sx={styles.separator} />
        {isCopying ? (
          <BaseButton onClick={copyToClipboard}>{copyLabel}</BaseButton>
        ) : (
          <BaseButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty && isCreating}>
            {isDeleting ? deleteLabel : createLabel}
          </BaseButton>
        )}
      </Box>
    </BaseModal>
  );
}

KeyManagementModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  apiKey: PropTypes.object,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
};

export default KeyManagementModal;
