import {
  Bed,
  Buildings,
  Airplane,
  Warehouse,
  Car,
  CalendarBlank,
  ForkKnife,
  Wrench,
  MapPinLine,
  Garage,
  Star,
} from '@phosphor-icons/react';

export const LOCATION_TYPE_ICONS = {
  office: Buildings,
  hotel: Bed,
  event: CalendarBlank,
  restaurant: ForkKnife,
  travel: Airplane,
  warehouse: Warehouse,
  parking: Car,
  service_area: Wrench,
  point_of_interest: MapPinLine,
  facility: Garage,
  other: Star,
};
