import React, {memo} from 'react';
import {Layer, Source} from 'react-map-gl';
import {useSelector} from 'react-redux';

import {MARTIN_SERVICE_URL} from '@config';
import {getThreatPointsSettings, getThreatHeatmapSettings} from '@/selectors';
import {THREAT_POINTS_LAYER} from '@/components/map/layers/constants';

function LayerThreatPoints() {
  const pointsLayerSettings = useSelector(getThreatPointsSettings);
  const heatmapLayerSettings = useSelector(getThreatHeatmapSettings);

  const pointsSourceSettings = {
    id: 'threat-points-source',
    type: 'vector',
    url: MARTIN_SERVICE_URL + THREAT_POINTS_LAYER,
  };

  return (
    <Source {...pointsSourceSettings}>
      <Layer {...pointsLayerSettings} />
      <Layer {...heatmapLayerSettings} />
    </Source>
  );
}

export default memo(LayerThreatPoints);
