import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import DataSourceTrend from '@/components/analytics/charts/DataSourceTrend';
import {
  API_SOURCE_CATEGORIES_TREND,
  API_SOURCE_CATEGORIES_TREND_RADIUS,
} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import SectionTabs from '@/components/analytics/sections/SectionTabs';
import {useTranslation} from '@/hooks/useTranslation';

function DataSourceReportingSection({id, granularity, sx, isReport, settings}) {
  const {getI18N} = useTranslation();
  const title = getI18N('threatAnalytics.chartTitle11');
  const path = useMemo(
    () =>
      granularity === 'radius'
        ? API_SOURCE_CATEGORIES_TREND_RADIUS
        : API_SOURCE_CATEGORIES_TREND,
    [granularity],
  );

  const {daily} = getI18N('chartSelection');
  const {label10: monthly} = getI18N('threatAnalytics');

  const timeAggregationOptions = [
    {label: monthly, value: 'monthly'},
    {label: daily, value: 'daily'},
  ];
  const [timeAggregation, setTimeAggregation] = useState('monthly');

  useEffect(() => {
    setTimeAggregation(settings.period);
  }, [settings]);

  const {
    data,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    path,
    granularity,
    exactDates: false,
    customParams: {
      timeAggregation,
    },
  });

  if (isReport)
    return (
      <ReportChartWrapper isLoading={isFetching} error={Boolean(error)}>
        <DataSourceTrend
          showLegend
          height={sx.height}
          width={sx.width}
          data={data}
          isFetching={isFetching}
        />
      </ReportChartWrapper>
    );

  if (error) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="dataSourceTrend"
        downloadCSV={downloadCSV}
        copyCSV={copyCSV}
        downloadImage={downloadImage}
        copyImage={copyImage}
        chart={
          <DataSourceTrend showLegend data={data} isLoading={isFetching} />
        }
        controls={
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            sx={{maxWidth: '50%'}}>
            <SectionTabs
              options={timeAggregationOptions}
              value={timeAggregation}
              onChange={(_, value) => {
                setTimeAggregation(value);
              }}
            />
          </Box>
        }
        settings={{id, period: timeAggregation}}
      />
    </Box>
  );
}

DataSourceReportingSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isReport: PropTypes.bool,
  settings: PropTypes.object,
};

export default DataSourceReportingSection;
