import React, {memo} from 'react';
import {Source, Layer} from 'react-map-gl';
import {useSelector} from 'react-redux';

import {MARTIN_SERVICE_URL} from '@config';
import {
  getSavedLocationsMarkersLayer,
  getSavedLocationsTextLayer,
} from '@/selectors';
import {SAVED_LOCATIONS_LAYER} from '@/components/map/layers/constants';

function LayerSavedLocations() {
  const markersLayer = useSelector(getSavedLocationsMarkersLayer);
  const textLayer = useSelector(getSavedLocationsTextLayer);

  const sourceSettings = {
    id: 'saved-locations-source',
    type: 'vector',
    url: MARTIN_SERVICE_URL + SAVED_LOCATIONS_LAYER,
  };

  return (
    <Source {...sourceSettings}>
      <Layer {...markersLayer} />
      <Layer {...textLayer} />
    </Source>
  );
}

export default memo(LayerSavedLocations);
