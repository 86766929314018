import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {getOrganization, getUser} from '@/selectors';
import {useAuth0Service} from '@/hooks/useAuth0Service';

export const useFeatureAccess = () => {
  const {reportAccessToken} = useAuth0Service();
  const user = useSelector(getUser);
  const {active: organization} = useSelector(getOrganization);

  /**
   * Represents controlled features with their respective configurations.
   *
   * @property {string} id - Jira ticket id when first added. E.g., 'PD-4810'
   * @property {string} name - Name to identify the feature. E.g., 'shared-saved-locations'
   * @property {string[]} roles - Allowed roles. Possible values: ['user','owner','all','none']
   * @property {string[]} tiers - Available tiers. Possible values: ['enterprise', 'premium', 'standard','all','none']
   * @property {string[]} actions - Actions to apply. Possible values: ['disable', 'hide']
   *
   * @example
   * {
   *   id: 'PD-4810',
   *   name: 'shared-saved-locations',
   *   roles: ['owner'],
   *   tiers: ['all'],
   *   actions: ['disable']
   * }
   *
   */

  const CONTROLLED_FEATURES = [
    {
      id: 'PD-4810',
      name: 'shared-saved-locations',
      mixpanel: 'Additional Saved Locations',
      roles: ['owner', 'admin'],
      tiers: ['premium', 'enterprise'],
      actions: ['disable', 'hide'],
    },
    {
      id: 'PD-5405', // TODO: improve role/action mapping, this should be one feature with shared-saved-locations, but for now adding more rules will work, not recommended if this keep increasing on quantity or complexity
      name: 'shared-locations-visibility',
      mixpanel: 'Additional Team Downloads',
      roles: ['all'],
      tiers: ['premium', 'enterprise'],
      actions: ['hide'],
    },
    {
      id: 'PD-5182',
      name: 'api-key-management',
      mixpanel: 'User API Keys',
      roles: ['owner'],
      tiers: ['premium', 'enterprise'],
      actions: ['hide'],
    },
    {
      id: 'PD-7777',
      name: 'saved-locations-tags',
      mixpanel: 'Location Types Tabs',
      roles: ['all'],
      tiers: ['premium', 'enterprise'],
      actions: ['hide'],
    },
    {
      id: 'PD-7778',
      name: 'saved-locations-priority',
      mixpanel: 'Priority Rating',
      roles: ['all'],
      tiers: ['premium', 'enterprise'],
      actions: ['disable', 'label', 'hide'],
    },
    {
      id: 'PD-5319',
      name: 'threat-analytics-change',
      mixpanel: 'Percentage Change Threat Analytics',
      roles: ['all'],
      tiers: ['premium', 'enterprise'],
      actions: ['tease', 'hide'],
    },
    {
      id: 'PD-5425',
      name: 'saved-locations-change',
      mixpanel: 'Percentage Change Saved Locations',
      roles: ['all'],
      tiers: ['enterprise'],
      actions: ['disable', 'hide', 'label'],
    },
    {
      id: 'PD-4791',
      name: 'saved-locations-downloads',
      mixpanel: 'Additional Team Downloads',
      roles: ['all'],
      tiers: ['premium', 'enterprise'],
      actions: ['disable'],
    },
    {
      id: 'PD-4790',
      name: 'report-downloads',
      mixpanel: 'Report Downloads',
      roles: ['all'],
      tiers: ['premium', 'enterprise'],
      actions: ['hide'],
    },
    {
      id: 'PD-5754',
      name: 'custom-report',
      mixpanel: 'Custom Report',
      roles: ['all'],
      tiers: ['premium', 'enterprise'],
      actions: ['hide'],
    },
    {
      id: 'PD-5607',
      name: 'saved-locations-analysis',
      mixpanel: 'Enterprise Dashboard Charts',
      roles: ['all'],
      tiers: ['enterprise'],
      actions: ['hide'],
    },
    {
      id: 'PD-6281',
      name: 'my-locations-analysis',
      mixpanel: 'My Locations Analysis',
      roles: ['all'], // ['all'], -> for local development, will turn on
      tiers: ['enterprise'], // ['all'], -> for local development, will turn on
      actions: ['hide'],
    },
    {
      id: 'PD-6130',
      name: 'map-layers',
      mixpanel: 'Map Layers',
      roles: ['none'],
      tiers: ['none'],
      actions: ['hide'],
    },
    {
      id: 'PD-6512',
      name: 'country-analysis',
      mixpanel: 'Country Analysis Settings',
      roles: ['none'],
      tiers: ['none'],
      actions: ['hide'],
    },
    {
      id: 'PD-6512',
      name: 'gtm',
      mixpanel: 'Global Threats Model',
      roles: ['none'],
      tiers: ['none'],
      actions: ['hide'],
    },
  ];

  const getControlledFeature = useCallback(
    (controlledFeature) => {
      if (!controlledFeature) return {};

      const feature = CONTROLLED_FEATURES.find(
        ({id, name}) => controlledFeature === id || controlledFeature === name,
      );

      const hasRoleAccess = (role) => feature.roles.includes(role);
      const hasTierAccess = (tier) => feature.tiers.includes(tier);

      if (reportAccessToken) {
        return {
          ...feature,
          status: false,
        };
      }

      if (hasRoleAccess('none') || hasTierAccess('none'))
        return {
          ...feature,
          status: true,
        };

      return {
        ...feature,
        status: !(
          (hasRoleAccess('all') || hasRoleAccess(user.role)) &&
          (hasTierAccess('all') || hasTierAccess(organization.tier))
        ),
      };
    },
    [user, organization, reportAccessToken],
  );

  const getFeatureAccess = useCallback(
    (controlledFeature, action) => {
      if (!controlledFeature || !action) return false;
      const feature = getControlledFeature(controlledFeature);
      return feature.actions.includes(action) && feature.status;
    },
    [getControlledFeature],
  );

  return {
    getFeatureAccess,
    getControlledFeature,
  };
};
