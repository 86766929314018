import {Skeleton} from '@mui/material';
import PropTypes from 'prop-types';

function BaseSkeleton({
  children,
  variant = 'rounded',
  width = '100%',
  height,
  sx = {},
}) {
  return (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
      sx={(theme) => ({
        ...(typeof sx === 'function' ? sx(theme) : sx),
        backgroundColor: theme.palette.background.light,
      })}>
      {children}
    </Skeleton>
  );
}

BaseSkeleton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BaseSkeleton;
