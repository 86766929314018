export const styles = {
  baseToggleGroup: {
    paddingTop: '10px',
    '& .MuiToggleButton-root:first-of-type': {
      borderRadius: '20px 0 0 20px',
    },
    '& .MuiToggleButton-root:last-child': {
      borderRadius: '0 20px 20px 0',
    },
  },
  baseVisibilitySwitch: (theme, checkedLabel, uncheckedLabel) => ({
    width: '100%',
    paddingY: '8px',
    paddingX: '0px',
    '& .Mui-checked + .MuiSwitch-track': {
      background: `linear-gradient(to right,
          ${theme.palette.background.light} 50%, ${theme.palette.primary.main} 50%)`,
      '&:after': {
        color: theme.palette.common.black,
      },
      '&:before': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      marginY: 1,
      width: '100%',
      borderRadius: '20px',
      '& .MuiSwitch-input': {
        left: 0,
        width: '100%',
      },
    },
    '& .MuiSwitch-track': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      border: `${theme.palette.primary.main} 1px solid`,
      opacity: '1 !important',
      background: `linear-gradient(to right,
          ${theme.palette.primary.main} 50%,${theme.palette.background.light} 50%)`,
      '&:after, &:before': {
        fontSize: '11px',
        flex: '1 0 50%',
        textAlign: 'center',
        width: 80,
        height: 16,
      },
      '&:before': {
        color: theme.palette.common.black,
        content: `"${checkedLabel}"`,
      },
      '&:after': {
        color: theme.palette.primary.main,
        content: `"${uncheckedLabel}"`,
      },
    },
    '& .MuiSwitch-thumb': {
      color: 'transparent',
      transform: 'translateX(0px)',
      boxShadow: 'none',
    },
  }),
};
