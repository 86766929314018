import PropTypes from 'prop-types';
import {useTheme} from '@mui/material';
import {NovuProvider} from '@novu/notification-center';
import {NOVU_APP_ID} from '@config';
import {useSelector} from 'react-redux';
import {getUserProfile} from '@/selectors';

function NotificationProvider({children}) {
  const theme = useTheme();
  const user = useSelector(getUserProfile);

  return (
    <NovuProvider
      subscriberId={user.authId}
      applicationIdentifier={NOVU_APP_ID}
      initialFetchingStrategy={{
        fetchUnseenCount: true,
        fetchOrganization: false,
        fetchNotifications: true,
        fetchUserPreferences: true,
      }}
      styles={{
        bellButton: {
          dot: {
            '& > rect': {
              fill: theme.palette.primary.main,
            },
          },
        },
      }}>
      {children}
    </NovuProvider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
