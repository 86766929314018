import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useRef} from 'react';

import {
  getViewTypeWorld,
  getLocationZoomThreshold,
  getDebugMode,
  getViewTypeCity,
} from '@/selectors';
import {setTiles, setViewTypeView} from '@/store/modules/map/actions';
import {setViewportBoundingBox} from '@/store/modules/filters/actions';

import {LAYER_TYPE} from '@/components/map/constants';
import {ZOOM} from '@/hooks/constants';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';

export const useMapZoom = () => {
  const dispatch = useDispatch();
  const zoom = useRef({
    current: ZOOM.INITIAL_ZOOM,
    previous: ZOOM.INITIAL_ZOOM,
  });
  const {getTilesInViewPort, setZoom, getZoom} = useMapboxGL();

  const isWorldView = useSelector(getViewTypeWorld);
  const zoomThreshold = useSelector(getLocationZoomThreshold);
  const debugMode = useSelector(getDebugMode);
  const isCityView = useSelector(getViewTypeCity);

  const handleZoom = useCallback((event) => {
    const map = event.target;
    zoom.current = {
      previous: zoom.current.next,
      next: map.getZoom(),
    };
  }, []);

  const handleZoomIn = useCallback(() => {
    const zoom = getZoom();
    setZoom(zoom + 0.5);
  }, [getZoom, setZoom]);

  const handleZoomOut = useCallback(() => {
    const zoom = getZoom();
    if (zoom <= 2) return;
    setZoom(zoom - 0.5);
  }, [getZoom, setZoom]);

  const handleZoomEnd = useCallback(
    (event) => {
      const map = event.target;

      map.triggerRepaint();

      const nextZoom = zoom.current.next;
      const previousZoom = zoom.current.previous;

      if (nextZoom !== previousZoom) {
        if (
          nextZoom > previousZoom &&
          nextZoom >= ZOOM.HEATMAP_THRESHOLD &&
          !isCityView
        ) {
          dispatch(setViewTypeView(LAYER_TYPE.CITY));
        }

        if (
          nextZoom < previousZoom &&
          zoomThreshold > nextZoom &&
          !isWorldView
        ) {
          dispatch(setViewTypeView(LAYER_TYPE.WORLD));
        }
      }

      if (debugMode) {
        dispatch(setTiles(getTilesInViewPort()));
      }

      dispatch(setViewportBoundingBox(map.getBounds()));
    },
    [
      dispatch,
      isWorldView,
      isCityView,
      zoomThreshold,
      getTilesInViewPort,
      debugMode,
    ],
  );

  return {
    handleZoom,
    handleZoomIn,
    handleZoomOut,
    handleZoomEnd,
  };
};
