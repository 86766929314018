// Importing environment variables
const API_URL = import.meta.env.VITE_API_URL;
const MARTIN_SERVICE_URL = import.meta.env.VITE_MARTIN_SERVICE_URL;
const TILE_SERVICE_URL = import.meta.env.VITE_TILE_SERVICE_URL;
const MAP_BOX_USERNAME = import.meta.env.VITE_MAP_BOX_USERNAME;
const MAP_BOX_NI_STYLE_ID = import.meta.env.VITE_MAP_BOX_NI_STYLE_ID;
const MAP_BOX_DARK_STYLE_ID = import.meta.env.VITE_MAP_BOX_DARK_STYLE_ID;
const MAP_BOX_SATELLITE_STYLE_ID = import.meta.env
  .VITE_MAP_BOX_SATELLITE_STYLE_ID;
const MAPBOX_PUBLIC_KEY = import.meta.env.VITE_MAPBOX_PUBLIC_KEY;
const MAP_BOX_ICON_SPRITE = import.meta.env.VITE_MAP_BOX_ICON_SPRITE;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV || 'local';
const MIXPANEL_API = import.meta.env.VITE_MIXPANEL_API;
const MIXPANEL_API_KEY = import.meta.env.VITE_MIXPANEL_API_KEY;
const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
const AUTH0_CHANGE_PASSWORD_URL = import.meta.env
  .VITE_AUTH0_CHANGE_PASSWORD_URL;
const {MODE} = import.meta.env;
const GOOGLE_PLACES_API = `https://maps.googleapis.com/maps/api/js?key=${
  import.meta.env.VITE_GOOGLE_MAPS_API_KEY
}&libraries=places&callback=googleCallback`;
const NOVU_APP_ID = import.meta.env.VITE_NOVU_APP_ID;

// print environment
console.info('mode:', MODE);

// Exporting environment variables
export {
  MODE,
  API_URL,
  MARTIN_SERVICE_URL,
  TILE_SERVICE_URL,
  MAP_BOX_USERNAME,
  MAP_BOX_NI_STYLE_ID,
  MAP_BOX_DARK_STYLE_ID,
  MAP_BOX_SATELLITE_STYLE_ID,
  MAPBOX_PUBLIC_KEY,
  MAP_BOX_ICON_SPRITE,
  SENTRY_DSN,
  SENTRY_ENV,
  MIXPANEL_API,
  MIXPANEL_API_KEY,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  AUTH0_CHANGE_PASSWORD_URL,
  GOOGLE_PLACES_API,
  NOVU_APP_ID,
};
