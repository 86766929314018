import {useTheme} from '@mui/material';
import {buildChartTheme} from '@visx/xychart';
import {useCallback, useMemo} from 'react';

export const useChartTheme = (seriesLength = 10, customColors) => {
  const theme = useTheme();

  const colors = useMemo(
    () => Object.values(theme.palette.chart),
    [theme.palette.chart],
  );

  const getChartColors = useCallback(
    (seriesLength = 10) => {
      if (seriesLength === 1) return [colors[2]];
      if (seriesLength === 2) return [colors[2], colors[7]];
      if (seriesLength === 3) return [colors[1], colors[4], colors[8]];
      if (seriesLength >= 4) {
        const result = [colors[0]];
        const interval = (colors.length - 2) / (seriesLength - 2);
        for (let i = 1; i < seriesLength - 1; i += 1) {
          const index = Math.round(i * interval);
          result.push(colors[index]);
        }
        result.push(colors[colors.length - 1]);
        if (result.length !== seriesLength) {
          return result.slice(0, seriesLength);
        }
        return result;
      }
      return colors;
    },
    [colors],
  );

  const chartTheme = buildChartTheme({
    // Basic Colors
    backgroundColor: theme.palette.background.paper,
    colors: customColors ?? getChartColors(seriesLength),

    // Grid
    gridColor: theme.palette.text.primary,
    gridColorDark: theme.palette.common.black,
    gridStyles: {
      strokeOpacity: 0.3,
    },

    // Labels
    svgLabelBig: {
      fill: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 'bold',
    },
    svgLabelSmall: {
      fill: theme.palette.text.secondary,
      fontSize: 10,
      fontWeight: 'normal',
    },

    // Ticks
    xAxisLineStyles: {
      stroke: theme.palette.text.secondary,
      strokeWidth: 2,
    },
    yAxisLineStyles: {
      stroke: theme.palette.text.secondary,
      strokeWidth: 2,
    },
    xTickLineStyles: {
      strokeWidth: 1,
      stroke: theme.palette.text.secondary,
    },
    yTickLineStyles: {
      strokeWidth: 1,
      stroke: theme.palette.text.secondary,
    },
    tickLength: 8,
  });

  return {
    theme: chartTheme,
    getChartColors,
    colors,
  };
};
