import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '@mui/material/Drawer';
import {styles} from '@common/drawer/DrawerBO.styles';

function DrawerBO({
  open = true,
  anchor = 'left',
  variant = 'persistent',
  PaperProps,
  onClose,
  children,
  width = '360px',
  height = '100vh',
  isMain = false,
  onClick,
}) {
  return (
    <Drawer
      onClick={onClick}
      variant={variant}
      anchor={anchor}
      PaperProps={{
        ...PaperProps,
        sx: (theme) =>
          styles.drawerPaper({theme, width, height, isMain, anchor}),
      }}
      onClose={onClose}
      open={open}
      sx={styles.root}>
      {children}
    </Drawer>
  );
}

DrawerBO.propTypes = {
  onClick: PropTypes.func,
  isMain: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  variant: PropTypes.oneOf(['persistent', 'temporary']),
  PaperProps: PropTypes.object,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DrawerBO;
