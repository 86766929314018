import {Box, Typography, useTheme} from '@mui/material';
import {BASE_LOGO_WHITE, SUPPORT_EMAIL} from '@/assets/constants';
import BaseButton from '@/components/common/buttons/BaseButton';
import {useStyles} from '@/components/views/ErrorPage.styles';
import {useTranslation} from '@/hooks/useTranslation';

function ErrorPage() {
  const {getI18N} = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  const {title, subtitle, refreshButton, goHome, contactSupport} =
    getI18N('errorPage');

  return (
    <Box sx={styles.root}>
      <Box sx={styles.contentWrapper}>
        <Box sx={styles.imageBox}>
          <img
            src={BASE_LOGO_WHITE}
            alt="Base Operations Logo"
            style={{width: '270px'}}
          />
        </Box>
        <Typography
          variant="h4"
          sx={styles.titleText}
          color={theme.palette.text.primary}>
          {title}
        </Typography>
        <Typography
          variant="h5"
          sx={styles.subtitleText}
          color={theme.palette.text.secondary}>
          {subtitle}
        </Typography>
        <BaseButton
          variant="outlined"
          size="medium"
          sx={styles.primaryButton}
          onClick={() => window.location.reload()}>
          {refreshButton}
        </BaseButton>
        <Box sx={styles.secondaryActions}>
          <BaseButton
            variant="text"
            size="small"
            color="secondary"
            sx={styles.secondaryActionButton}
            href="/">
            {goHome}
          </BaseButton>
          <Typography variant="body1" color={theme.palette.text.secondary}>
            •
          </Typography>
          <BaseButton
            variant="text"
            size="small"
            color="secondary"
            sx={styles.secondaryActionButton}
            href={`mailto:${SUPPORT_EMAIL}`}>
            {contactSupport}
          </BaseButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ErrorPage;
