import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Box, Typography, useTheme} from '@mui/material';

import {
  getClickedHoodId,
  getDistricts,
  getSelectedThreatTypes,
} from '@/selectors';

import {styles} from '@/components/analytics/sections/SeverityScoreSection.styles';
import Quintiles from '@/components/analytics/quintile/Quintiles';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';

import {useTranslation} from '@/hooks/useTranslation';
import {API_CHART_QUINTILES} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';

function SeverityScoreSection({id, granularity, sx, isReport}) {
  const theme = useTheme();
  const {getI18N, getThreatTypesTranslation} = useTranslation();

  const {locationDistricts} = useSelector(getDistricts);
  const {selectedDistricts} = useSelector(getDistricts);
  const locationHoodId = useSelector(getClickedHoodId);
  const threatTypes = useSelector(getSelectedThreatTypes);

  const quintiles = getI18N('quantiles');
  const unknown = getI18N('unknown');
  const title = getI18N('threatAnalytics.chartTitle9');
  const {threatCategory, eventCount, severity} = getI18N('chartQuintile');

  const {
    data,
    isFetching,
    error,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    path: API_CHART_QUINTILES,
    granularity,
  });

  const score = useMemo(() => {
    const hoodId = selectedDistricts?.[0]?.id ?? locationHoodId;
    const hood = locationDistricts.find(({id}) => id === hoodId);
    return {
      value: hood?.total || '-',
      label: quintiles[hood?.total] || unknown,
    };
  }, [
    selectedDistricts,
    locationDistricts,
    quintiles,
    unknown,
    locationHoodId,
  ]);

  const formattedData = useMemo(
    () =>
      data?.chartData?.map(({category, categoryId, ...quintile}) => ({
        ...threatTypes.find(({id}) => categoryId === id),
        ...quintile,
        name: getThreatTypesTranslation(categoryId),
      })) || [],
    [threatTypes, data],
  );

  const columns = useMemo(
    () => [
      {
        id: 'name',
        label: threatCategory,
        width: '40%',
        textAlign: 'start',
        type: 'text',
      },
      {
        id: 'count',
        label: eventCount,
        width: '30%',
        textAlign: 'start',
        type: 'text',
      },
      {
        id: 'severity',
        label: severity,
        width: '30%',
        textAlign: 'center',
        type: 'quintile',
      },
    ],
    [threatCategory, eventCount, severity],
  );

  if (isReport)
    return (
      <ReportChartWrapper isLoading={isFetching} error={Boolean(error)}>
        {!isFetching && (
          <Box
            sx={styles.quintileScore}
            style={{
              backgroundColor: theme.palette.quintile[score.value],
            }}>
            <Typography variant="caption" sx={styles.scoreLabel}>
              {score.value} ({score.label})
            </Typography>
          </Box>
        )}
        <Quintiles
          isReport
          defaultExpanded
          width={sx.width}
          columns={columns}
          data={formattedData}
          isFetching={isFetching}
        />
      </ReportChartWrapper>
    );

  if (error) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="severityScore"
        downloadCSV={downloadCSV}
        copyCSV={copyCSV}
        downloadImage={downloadImage}
        copyImage={copyImage}
        chart={
          <>
            {isFetching && (
              <BaseSkeleton
                width="60px"
                height="24px"
                sx={styles.quintileSkeleton}
              />
            )}
            {!isFetching && (
              <Box
                sx={styles.quintileScore}
                style={{
                  backgroundColor: theme.palette.quintile[score.value],
                }}>
                <Typography variant="caption" sx={styles.scoreLabel}>
                  {score.value} ({score.label})
                </Typography>
              </Box>
            )}
            <Quintiles
              data={formattedData}
              isFetching={isFetching}
              columns={columns}
            />
          </>
        }
      />
    </Box>
  );
}

SeverityScoreSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isReport: PropTypes.bool,
};

export default SeverityScoreSection;
