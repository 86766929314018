import {RESET_STORE} from '@store/modules/actionTypes';
import {
  SET_MARKER,
  SET_SEARCH_MARKER,
  SET_LOADING_MAP,
  SET_DEBUG_MODE,
  SET_VIEWTYPE,
  SET_LOCATION_ADDRESS,
  SET_UNMAPPED_MODE,
  SET_MAP_TOOLTIP,
  SET_IS_FLYING,
  SET_CRIME_EVENTS_COUNT,
  SET_TILES,
  SET_ROUTED_TO_MAP,
  SET_MAP_MOUNTED_STATUS,
  SET_MAP_VIEWPORT,
  SET_SELECTED_CITY,
} from '@/store/modules/map/actionTypes';
import {LAYER_TYPE} from '@/components/map/constants';

export const initialState = {
  selectedCity: {},
  viewport: {
    latitude: 0,
    longitude: 0,
    zoom: 2,
  },
  mapTooltip: {
    visible: false,
    location: '',
    lat: 0,
    lon: 0,
  },
  marker: {},
  tiles: [],
  viewType: LAYER_TYPE.WORLD,
  isFlying: false,
  unmappedMode: false,
  loadingMap: true,
  debugMode: false,
  routedToMap: false,
  isMapMounted: false,
  // to rename - viewport count
  crimeEventsCount: 0,
  // to merge - marker
  searchMarker: null,
  locationAddress: null,
  // to own state - neighborhoods
  neighborhoodIncidents: [],
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CITY:
      return {
        ...state,
        selectedCity: action.payload,
      };
    case SET_MAP_VIEWPORT:
      return {
        ...state,
        viewport: action.payload,
      };
    case SET_MAP_MOUNTED_STATUS: {
      return {
        ...state,
        isMapMounted: action.payload,
      };
    }
    case SET_ROUTED_TO_MAP: {
      return {
        ...state,
        routedToMap: action.payload,
      };
    }
    case SET_TILES: {
      return {
        ...state,
        tiles: action.payload,
      };
    }
    case SET_CRIME_EVENTS_COUNT: {
      return {
        ...state,
        crimeEventsCount: action.payload,
      };
    }
    case SET_IS_FLYING: {
      return {
        ...state,
        isFlying: action.payload,
      };
    }
    case SET_MAP_TOOLTIP: {
      return {
        ...state,
        mapTooltip: {
          ...state.mapTooltip,
          ...action.mapTooltip,
        },
      };
    }
    case SET_MARKER: {
      return {
        ...state,
        marker: action.marker,
      };
    }
    case SET_SEARCH_MARKER: {
      return {
        ...state,
        searchMarker: action.search,
      };
    }
    case SET_LOADING_MAP: {
      return {
        ...state,
        loadingMap: action.loading,
      };
    }
    case SET_DEBUG_MODE: {
      return {
        ...state,
        debugMode: action.debug,
      };
    }
    case SET_UNMAPPED_MODE: {
      return {
        ...state,
        unmappedMode: action.unmapped,
      };
    }
    case SET_VIEWTYPE: {
      return {
        ...state,
        viewType: action.viewType,
      };
    }
    case SET_LOCATION_ADDRESS: {
      return {
        ...state,
        locationAddress: action.address,
      };
    }
    case RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default mapReducer;
