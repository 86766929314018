export const styles = {
  root: () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  colorScale: (theme) => ({
    background: `linear-gradient(90deg, ${theme.palette.quintile[1]} 0%, ${theme.palette.quintile[2]} 25%, ${theme.palette.quintile[3]} 50%,  ${theme.palette.quintile[4]} 75%, ${theme.palette.quintile[5]} 100%)`,
  }),
};
