import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import Box from '@mui/material/Box';
import BaseChipLabel from '@/components/common/inputs/BaseChipLabel';
import {styles} from '@/components/common/inputs/BaseChipRadioGroup.styles';
import BaseInputLabel from '@/components/common/inputs/BaseInputLabel';

const renderChipRadio = ({
  options,
  placeholder,
  onChange,
  value,
  defaultValue,
  size,
  ...rest
}) => (
  <RadioGroup
    {...rest}
    placeholder={placeholder}
    onChange={onChange}
    row
    value={value}
    defaultValue={defaultValue || options[0]?.value}>
    {options.map(({value: optionValue, label, icon: Icon}) => (
      <FormControlLabel
        key={optionValue}
        value={optionValue}
        sx={styles.formControlLabel}
        control={<Radio key={optionValue} size={size} sx={styles.radio} />}
        label={
          <BaseChipLabel
            label={label}
            size={size}
            sx={styles.baseChipLabel}
            deleteIcon={<Icon />}
          />
        }
      />
    ))}
  </RadioGroup>
);

function BaseChipRadioGroup({
  control,
  size = 'small',
  id = '',
  name = '',
  variant = 'standard',
  disabled = false,
  fullWidth = false,
  label,
  required = false,
  placeholder = '',
  onChange = () => {},
  value,
  defaultValue,
  options = [],
  errors = null,
  sx = {},
  ...rest
}) {
  return (
    <FormControl
      id={id}
      size={size === 'xsmall' ? 'small' : size}
      name={name}
      variant={variant}
      required={required}
      fullWidth={fullWidth}
      error={!!errors}
      sx={{marginBottom: 2}}
      disabled={disabled}>
      {label && (
        <Box marginBottom={1}>
          <BaseInputLabel required={required} label={label} />
        </Box>
      )}
      {control && (
        <Controller
          control={control}
          name={name}
          render={({field: {onChange, value}}) =>
            renderChipRadio({
              placeholder,
              onChange,
              value,
              defaultValue,
              options,
              size,
              sx,
              ...rest,
            })
          }
        />
      )}
      {!control &&
        renderChipRadio({
          placeholder,
          onChange,
          value,
          defaultValue,
          options,
          sx,
          size,
          ...rest,
        })}
      {errors?.message && <FormHelperText>{errors.message}</FormHelperText>}
    </FormControl>
  );
}

BaseChipRadioGroup.propTypes = {
  control: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  options: PropTypes.array,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

BaseChipRadioGroup.displayName = 'BaseChipRadioGroup';

export default BaseChipRadioGroup;
