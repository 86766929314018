import {
  SWITCH_SIDE_NAV_STATUS,
  SET_ANALYTICS_NAV_STATUS,
  SET_ANALYTICS_HEIGHT,
  SET_ANALYTICS_WIDTH,
  SET_SAVED_LOCATIONS_FILTERS_STATUS,
  SET_MY_LOCATIONS_ANALYSIS_FILTERS_STATUS,
} from '@/store/modules/drawer/actionTypes';

export const setSavedLocationFiltersStatus = (payload = undefined) => ({
  type: SET_SAVED_LOCATIONS_FILTERS_STATUS,
  payload,
});

export const setMyLocationsAnalysisFiltersStatus = (payload = undefined) => ({
  type: SET_MY_LOCATIONS_ANALYSIS_FILTERS_STATUS,
  payload,
});

export const setMapFiltersStatus = (payload = undefined) => ({
  type: SWITCH_SIDE_NAV_STATUS,
  payload,
});

export const setAnalyticsPanelStatus = (payload = undefined) => ({
  type: SET_ANALYTICS_NAV_STATUS,
  payload,
});

export const setAnalyticsDrawerWith = (payload) => ({
  type: SET_ANALYTICS_WIDTH,
  payload,
});

export const setAnalyticsDrawerHeight = (payload) => ({
  type: SET_ANALYTICS_HEIGHT,
  payload,
});
