export const SET_CATEGORIES = 'SET_CATEGORIES';
export const TOGGLE_SOURCE = 'TOGGLE_SOURCE';
export const TOGGLE_ALL_SOURCES = 'TOGGLE_ALL_SOURCES';
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
export const TOGGLE_ALL_CATEGORIES = 'TOGGLE_ALL_CATEGORIES';
export const TOGGLE_TIMESLICE = 'TOGGLE_TIMESLICE';
export const TOGGLE_ALL_TIMESLICES = 'TOGGLE_ALL_TIMESLICES';
export const SET_MAP_LAYERS = 'SET_MAP_LAYERS';
export const TOGGLE_MAPTHREATTYPE = 'TOGGLE_MAPTHREATTYPE';
export const TOGGLE_MONTH = 'TOGGLE_MONTH';
export const TOGGLE_ALL_MONTH = 'TOGGLE_ALL_MONTH';
export const CHANGE_FILTERS_DATERANGE = 'CHANGE_FILTERS_DATERANGE';
export const SET_RANGE = 'SET_RANGE';
export const SET_FILTER_COUNTRIES = 'SET_FILTER_COUNTRIES';
export const TOGGLE_INITIAL_LOADING = 'TOGGLE_INITIAL_LOADING';
export const LOADING_NBHD = 'LOADING_NBHD';
export const SET_LAODING_TILE = 'SET_LAODING_TILE';
export const SET_AVAILABLE_CITIES = 'SET_AVAILABLE_CITIES';
export const SET_LOADING_TIMESTAMP = 'SET_LOADING_TIMESTAMP';
export const CHANGE_MINMAX_DATERANGE = 'CHANGE_MINMAX_DATERANGE';
export const SET_NEIGHBORHOODS_TIMESTAMP = 'SET_NEIGHBORHOODS_TIMESTAMP';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_CRIME_FILTERS = 'SET_CRIME_FILTERS';
export const SET_VIEWPORT_BOUNDING_BOX = 'SET_VIEWPORT_BOUNDING_BOX';
export const SET_LOCATION_SETTINGS = 'SET_LOCATION_SETTINGS';
export const SET_LOCATION_FILTERS = 'SET_LOCATION_FILTERS';
export const SET_FILTERS_LOADING = 'SET_FILTERS_LOADING';
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const TOGGLE_ALL_QUARTERS = 'TOGGLE_ALL_QUARTERS';
export const TOGGLE_QUARTER = 'TOGGLE_QUARTER';
export const SET_GOOGLE_VALUE = 'SET_GOOGLE_VALUE';
export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const SET_SUGGESTIONS = 'SET_SUGGESTIONS';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SOURCES = 'SET_SOURCES';
export const SET_ACTIVE_CITY = 'SET_ACTIVE_CITY';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SET_LOCATION_LOADING = 'SET_LOCATION_LOADING';
export const SET_GRANULARITY = 'SET_GRANULARITY';
export const SET_GTM_STATUS = 'SET_GTM_STATUS';
