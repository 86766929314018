import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import ThreatWordCloud from '@/components/analytics/charts/ThreatWordCloud';
import {
  API_CHART_EVENT_SUMMARY,
  API_CHART_EVENT_SUMMARY_RADIUS,
} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';

function EventCompositionSection({id, granularity, sx, isReport}) {
  const title = 'Event Composition';

  const path = useMemo(
    () =>
      granularity === 'radius'
        ? API_CHART_EVENT_SUMMARY_RADIUS
        : API_CHART_EVENT_SUMMARY,
    [granularity],
  );

  const {
    data,
    error,
    isFetching,
    downloadImage,
    copyImage,
    downloadCSV,
    copyCSV,
  } = useChart({
    id,
    path,
    granularity,
    title,
  });

  if (isReport)
    return (
      <ReportChartWrapper isLoading={isFetching} error={Boolean(error)}>
        <ThreatWordCloud
          data={data}
          isLoading={isFetching}
          height={sx.height}
          width={sx.width}
        />
      </ReportChartWrapper>
    );

  if (error) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="eventComposition"
        downloadImage={downloadImage}
        copyImage={copyImage}
        downloadCSV={downloadCSV}
        copyCSV={copyCSV}
        chart={
          <ThreatWordCloud showLegend data={data} isLoading={isFetching} />
        }
      />
    </Box>
  );
}

EventCompositionSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isReport: PropTypes.bool,
};

export default EventCompositionSection;
