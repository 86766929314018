// Packages
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import PropTypes from 'prop-types';

// MUI Components
import BaseSkeleton from '@common/BaseSkeleton';
import {Box, Link} from '@mui/material';

// Styles and constants
import {styles} from '@/components/analytics/notable-events/NotableEvents.styles';
import {useTranslation} from '@/hooks/useTranslation';

// Hooks
import {API_THREAT, API_THREAT_RADIUS} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import {useMoment} from '@/hooks/useMoment';

import {EVENT_LIST_KEY} from '@/components/analytics/constants';
import NotableEvents from '@/components/analytics/notable-events/NotableEvents';
import {useMixpanel} from '@/hooks/useMixpanel';
import {getLocation} from '@/selectors';

/**
 * Displays section of notable events.
 *
 * @param {string} granularity - The current selected tab
 * @returns {React.Component} - The component
 *
 * @example
 * <NotableEventsSections sx={{mb: 2}} />
 */
function NotableEventsSections({sx, granularity, setActivePanel}) {
  const {track} = useMixpanel();
  const {getI18N} = useTranslation();
  const {goToEventList} = getI18N('threatAnalytics');
  const {getNotableEventsFromDate} = useMoment();
  const path = granularity === 'radius' ? API_THREAT_RADIUS : API_THREAT;
  const {name: locationName} = useSelector(getLocation);

  const {
    data: threatData,
    error,
    isFetching,
  } = useChart({
    path,
    customParams: {
      sortBy: 'score.desc',
      take: 3,
      type: 'unrest',
    },
    granularity,
    fromDate: getNotableEventsFromDate(),
  });

  // Filter out null scores
  const filteredData = useMemo(() => {
    if (threatData?.data?.length > 0) {
      return threatData?.data?.filter((threat) => threat?.score !== null);
    }
    return [];
  }, [threatData]);

  const handleGoToEventList = () => {
    track('Notable Events - Additional Events Button Clicked', {
      location: locationName,
    });
    setActivePanel(EVENT_LIST_KEY);
  };

  const eventListLink = (
    <Link onClick={handleGoToEventList} sx={styles.link}>
      {goToEventList}
    </Link>
  );

  if (error || (!isFetching && filteredData.length === 0)) return eventListLink;

  return (
    <Box sx={sx}>
      <Box sx={styles.root}>
        {isFetching ? (
          <NotableEventsLoader />
        ) : (
          <NotableEvents events={filteredData} />
        )}
      </Box>
      {eventListLink}
    </Box>
  );
}

NotableEventsSections.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string,
  setActivePanel: PropTypes.func,
};

export default NotableEventsSections;

function NotableEventsLoader() {
  return (
    <Box>
      {[0, 1, 2, 3, 4].map((index) => (
        <BaseSkeleton key={index} height="16px" sx={{marginBottom: 1}} />
      ))}
      <BaseSkeleton width="80%" height="16px" sx={{marginBottom: 1}} />
    </Box>
  );
}
