import React, {memo, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

// MUI Components
import {Box, useTheme} from '@mui/material';

// Styles and constants
import {styles} from '@/components/common/typography/TextLimit.styles';
import {useTranslation} from '@/hooks/useTranslation';

function TextLimit({text, linesLimit = 3, sx = {}}) {
  const [showAll, setShowAll] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const boxRef = useRef(null);

  const {getI18N} = useTranslation();
  const theme = useTheme();

  const {less, more} = getI18N('textLimit');

  const handleClick = () => {
    if (!isLimitReached) return;
    setShowAll((prev) => !prev);
  };

  useEffect(() => {
    const checkIfLimitReached = () => {
      const lineHeight = parseInt(
        window.getComputedStyle(boxRef.current).lineHeight,
        10,
      );
      const maxHeight = lineHeight * linesLimit;
      setIsLimitReached(boxRef.current.scrollHeight > maxHeight);
    };

    checkIfLimitReached();
    window.addEventListener('resize', checkIfLimitReached);

    return () => window.removeEventListener('resize', checkIfLimitReached);
  }, [text, linesLimit]);

  const backgroundColor =
    sx.backgroundColor || sx.background || theme.palette.background.default;

  return (
    <Box
      onClick={handleClick}
      sx={{
        ...styles.textBox,
        maxHeight: showAll ? 'none' : `${linesLimit * 1.5}em`,
        overflow: 'hidden',
        backgroundColor,
        ...sx,
      }}
      ref={boxRef}>
      <span style={styles.lineBreak}>{text}</span>
      {isLimitReached &&
        (!showAll ? (
          <Box sx={styles.moreButton} component="span">
            ...&nbsp;
            <Box sx={styles.moreButtonText} component="span">
              [{more}]
            </Box>
          </Box>
        ) : (
          <Box sx={styles.lessButton} component="span">
            &nbsp;[{less}]
          </Box>
        ))}
    </Box>
  );
}

export default memo(TextLimit);

TextLimit.propTypes = {
  text: PropTypes.string.isRequired,
  linesLimit: PropTypes.number,
  sx: PropTypes.any,
};
