import React from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {getMapTooltip} from '@/selectors';
import BasePopup from '@/components/common/map/BasePopup';

const styles = {
  tooltipUnrestData: {
    marginLeft: '15px',
  },
  tooltipContainer: {
    paddingBlock: 0.5,
    paddingInline: 1,
  },
  tooltipText: {
    maxWidth: 300,
    wordWrap: 'break-word',
    fontSize: '1rem',
  },
};

function MapTooltip() {
  const mapTooltipData = useSelector(getMapTooltip);

  return (
    mapTooltipData.visible && (
      <BasePopup
        closeButton={false}
        latitude={mapTooltipData.lat}
        longitude={mapTooltipData.lon}
        className="popup"
        captureScroll={false}
        anchor="bottom"
        dynamicPosition
        offset={[0, -12]}>
        <Box sx={styles.tooltipContainer}>
          <Typography sx={styles.tooltipText}>
            {mapTooltipData?.location}
          </Typography>
        </Box>
      </BasePopup>
    )
  );
}

export default MapTooltip;
