import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {getDebugMode, getUnmappedMode} from '@/selectors';
import LegendDebug from '@/components/map/legends/LegendDebug';
import LegendUnmapped from '@/components/map/legends/LegendUnmapped';

function Legend() {
  const unmappedMode = useSelector(getUnmappedMode);
  const debugMode = useSelector(getDebugMode);

  return (
    <>
      {debugMode && <LegendDebug />}
      {unmappedMode && <LegendUnmapped />}
    </>
  );
}

export default memo(Legend);
