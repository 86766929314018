import React, {forwardRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {Box, Collapse, Typography} from '@mui/material';
import FeatureAccessWrapper from '@feature-access/FeatureAccessWrapper';
import {styles} from '@common/inputs/BaseSelect.styles';
import {CaretDown, CaretUp} from '@phosphor-icons/react';
import BaseTooltip from '@/components/common/BaseTooltip';
import BetaLabel from '@/components/common/typography/BetaLabel';

const renderSelect = ({
  options,
  displayEmpty,
  placeholder,
  onChange,
  value,
  size,
  sx,
  ...rest
}) => (
  <Select
    {...rest}
    sx={(theme) => ({
      ...styles.root(theme),
      ...(typeof sx === 'function' ? sx(theme) : sx),
      ...(size === 'xsmall' ? styles.sxStyle : {}),
    })}
    IconComponent={CaretDown}
    placeholder={placeholder}
    displayEmpty={displayEmpty}
    onChange={onChange}
    value={value}>
    {options.map((o) => (
      <ControlledMenuItem
        key={o.value}
        feature={o.feature}
        action={o.action}
        value={o.value}
        disabled={o.disabled}
        sx={styles.menuItem}
        items={o.items}>
        <Box sx={styles.menuItemContent}>
          <Typography variant="body2">{o.label}</Typography>
          {o.tooltip && (
            <BaseTooltip
              icon
              message={o.tooltip}
              placement="right"
              disabled={Boolean(o.disabled)}
            />
          )}
        </Box>
      </ControlledMenuItem>
    ))}
  </Select>
);

function BaseSelect({
  control,
  size = 'medium',
  id = '',
  name = '',
  variant = 'standard',
  disabled = false,
  displayEmpty = false,
  fullWidth = false,
  label,
  required = false,
  placeholder = '',
  onChange = () => {},
  value,
  options = [],
  errors = null,
  sx = {},
  ...rest
}) {
  return (
    <FormControl
      id={id}
      size={size === 'xsmall' ? 'small' : size}
      name={name}
      variant={variant}
      required={required}
      fullWidth={fullWidth}
      error={!!errors}
      disabled={disabled}>
      {label && (
        <InputLabel sx={styles.label} required={required}>
          {label}
        </InputLabel>
      )}
      {control && (
        <Controller
          control={control}
          name={name}
          render={({field: {onChange, value}}) =>
            renderSelect({
              displayEmpty,
              placeholder,
              onChange,
              value,
              options,
              size,
              sx,
              ...rest,
            })
          }
        />
      )}
      {!control &&
        renderSelect({
          displayEmpty,
          placeholder,
          onChange,
          value,
          options,
          size,
          sx,
          ...rest,
        })}
      {errors?.message && <FormHelperText>{errors.message}</FormHelperText>}
    </FormControl>
  );
}

const ControlledMenuItem = forwardRef(
  ({feature = '', action = '', items, children, ...props}, ref) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
      setOpen(!open);
    };

    const caret = open ? <CaretUp /> : <CaretDown />;
    const isSubMenu = items && items.length > 0;
    const subMenuProps = isSubMenu ? {onClick: handleToggle} : {};

    return (
      <>
        <FeatureAccessWrapper ref={ref} feature={feature} action={action}>
          <MenuItem {...props} {...subMenuProps}>
            {children}
            {isSubMenu && caret}
          </MenuItem>
        </FeatureAccessWrapper>
        {items && (
          <Collapse in={open} timeout="auto">
            {items.map((item) => (
              <MenuItem
                {...props}
                onClick={item.onClick}
                sx={styles.nestedMenuItem}
                key={item.value}
                value={item.value}>
                <Box sx={styles.subMenuItemContent}>
                  <Typography variant="body2">{item.label}</Typography>
                  <BetaLabel sx={styles.betaLabel} />
                </Box>
              </MenuItem>
            ))}
          </Collapse>
        )}
      </>
    );
  },
);

ControlledMenuItem.propTypes = {
  feature: PropTypes.string,
  action: PropTypes.string,
  tooltip: PropTypes.any,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any}),
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ControlledMenuItem.displayName = 'ControlledMenuItem';

BaseSelect.propTypes = {
  control: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  options: PropTypes.array,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BaseSelect;
