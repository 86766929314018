import {
  SET_ORGANIZATIONS,
  SET_ACTIVE_ORGANIZATION,
  SET_ACTIVE_ORGANIZATION_ID,
} from '@/store/modules/organization/actionTypes';

export const initialState = {
  all: [],
  active: {},
  activeId: null,
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return {
        ...state,
        all: action.payload,
      };
    case SET_ACTIVE_ORGANIZATION:
      return {
        ...state,
        active: action.payload,
      };
    case SET_ACTIVE_ORGANIZATION_ID:
      return {
        ...state,
        activeId: action.payload,
      };
    default:
      return state;
  }
};

export default organizationReducer;
