export const styles = {
  baseChipLabel: {
    color: 'common.black',
    backgroundColor: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    marginRight: 0.5,
    marginBottom: 1,
    opacity: '0.5',
    padding: '4px 8px',
    '& .MuiChip-label': {
      fontSize: '0.8125rem',
      fontWeight: 'normal',
      padding: '0 10px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'common.black',
    },
    '& .MuiChip-deleteIcon:hover': {
      color: 'common.black',
    },
  },
  radio: {
    display: 'none',
    '&.Mui-checked + .MuiFormControlLabel-label > .MuiChip-root': {
      opacity: '1',
    },
  },
  formControlLabel: {
    margin: 0,
  },
};
