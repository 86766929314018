import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import QuintilesGroup from '@/components/analytics/quintile/QuintilesGroup';
import {styles} from '@/components/analytics/quintile/Quintiles.styles';

function Quintiles({
  data,
  columns,
  isFetching = false,
  defaultExpanded = false,
  isReport = false,
  width,
}) {
  return (
    <Box id="quintile-container" style={{width}}>
      <Box
        sx={isReport ? styles.compactQuintilesHeader : styles.quintilesHeader}>
        {columns.map((column) => (
          <Typography
            key={`quintile-header-${column.id}`}
            fontWeight="bold"
            variant="caption"
            flexBasis={column.width}
            textAlign={column.textAlign}>
            {column.label}
          </Typography>
        ))}
      </Box>
      <QuintilesGroup
        options={data}
        isLoading={isFetching}
        defaultExpanded={defaultExpanded}
        isReport={isReport}
        columns={columns}
      />
    </Box>
  );
}

Quintiles.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      width: PropTypes.string,
      textAlign: PropTypes.string,
    }).isRequired,
  ),
  isFetching: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  isReport: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Quintiles;
