export const styles = {
  inputLabel: (theme) => ({
    fontSize: '10.5px',
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    '&.Mui-focused': {
      fontWeight: 'normal',
    },
  }),
};
