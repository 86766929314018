import {
  DISPLAY_SEARCH,
  SET_RECENT_SEARCHES,
  SET_REPORT_DOWNLOAD_MODAL_STATUS,
} from '@/store/modules/modal/actionTypes';

export const displaySearchModal = (payload) => ({
  type: DISPLAY_SEARCH,
  payload,
});

export const setRecentSearches = (payload) => ({
  type: SET_RECENT_SEARCHES,
  payload,
});

export const setReportModalOpen = (payload) => ({
  type: SET_REPORT_DOWNLOAD_MODAL_STATUS,
  payload,
});
