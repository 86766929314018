import {
  SET_LOCATION_DISTRICTS,
  SET_SELECTED_DISTRICT,
} from '@store/modules/districts/actionTypes';
import {RESET_STORE} from '@store/modules/actionTypes';

export const initialState = {
  locationDistricts: [],
  selectedDistricts: [],
};

const districtsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION_DISTRICTS: {
      return {
        ...state,
        locationDistricts: action.payload,
      };
    }
    case SET_SELECTED_DISTRICT: {
      let selected;
      const newDistrict = action.payload;
      const {selectedDistricts} = state;
      if (Array.isArray(newDistrict)) {
        selected = newDistrict;
      } else if (typeof newDistrict === 'undefined') {
        selected = [];
      } else if (selectedDistricts.find(({id}) => id === newDistrict.id)) {
        selected = selectedDistricts.filter(({id}) => id !== newDistrict.id);
      } else {
        selected = [...selectedDistricts, newDistrict];
      }
      return {
        ...state,
        selectedDistricts: selected,
      };
    }
    case RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default districtsReducer;
