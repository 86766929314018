export const styles = {
  root: (theme) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.dark,
    width: '230px',
    borderRadius: '6px',
    transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  }),
  scale: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  legend: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'inherit',
  },
  title: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  options: {
    backgroundColor: 'transparent',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 2,
  },
  section: {
    width: '100%',
    gap: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: ({preview, selected, disabled}) => ({
    position: 'relative',
    cursor: disabled ? 'not-allowed' : 'pointer',
    height: '50px',
    width: '50px',
    backgroundImage: `url(${preview})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    border: selected ? '2px solid' : 'none',
    borderColor: (theme) => theme.palette.primary.main,
    borderRadius: '6px',
    backgroundOpacity: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  caption: (disabled) => ({
    opacity: disabled ? 0.5 : 1,
  }),
  collapsed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    padding: 1,
  },
  expanded: {
    padding: 2,
    height: '100%',
  },
  disabled: (theme) => ({
    position: 'absolute',
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${theme.palette.background.dark}80`,
  }),
};
