import PropTypes from 'prop-types';
import {useTheme, IconButton} from '@mui/material';
import {CaretDown, CaretUp} from '@phosphor-icons/react';
import {useMemo} from 'react';
import {styles} from '@/components/common/buttons/SortIconButton.styles';

const DIRECTIONS = {
  asc: 'ASC',
  desc: 'DESC',
};

function SortIconButton({onClick, sortDirection, active}) {
  const theme = useTheme();

  const colors = useMemo(() => {
    if (!active)
      return {
        up: theme.palette.text.secondary,
        down: theme.palette.text.secondary,
      };

    const up =
      sortDirection === DIRECTIONS.asc
        ? theme.palette.primary.main
        : theme.palette.text.secondary;
    const down =
      sortDirection === DIRECTIONS.desc
        ? theme.palette.primary.main
        : theme.palette.text.secondary;

    return {up, down};
  }, [sortDirection, active]);

  return (
    <IconButton onClick={onClick} sx={styles.root} disableRipple>
      <CaretUp size={12} color={colors.up} />
      <CaretDown size={12} color={colors.down} />
    </IconButton>
  );
}

SortIconButton.propTypes = {
  onClick: PropTypes.func,
  sortDirection: PropTypes.oneOf(Object.values(DIRECTIONS)),
  active: PropTypes.bool,
};

export default SortIconButton;
