import {useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Layer, Source} from 'react-map-gl';
import {useMap} from '@hooks/useMap';
import {useTheme} from '@mui/material';

import {useUnmappedCity} from '@hooks/useUnmappedCity';
import {TILE_SERVICE_URL} from '@config';
import {NEIGHBORHOOD_LAYER, JSON} from '@/components/map/layers/constants';
import {
  getShowFilters,
  getCategories,
  getRange,
  getLayers,
  getIsFlying,
  getClickedHoodId,
  getThreatAnalyticsAreaDisplay,
  getThreatAnalyticsAreaShape,
  getLoadingNeighborhoods,
  getLocation,
  getSelectedDistrictIds,
  getDistricts,
  getIsMapReady,
  getUser,
} from '@/selectors';

function LayerDistricts() {
  const unmappedCities = useUnmappedCity();
  const {map} = useMap();
  const theme = useTheme();

  const categories = useSelector(getCategories);
  const range = useSelector(getRange);
  const showFilters = useSelector(getShowFilters);
  const {locationDistricts} = useSelector(getDistricts);
  const {mapStyle} = useSelector(getUser);
  const {districts: isDistrictsLayer} = useSelector(getLayers);
  const clickedHood = useSelector(getClickedHoodId);
  const radiusVisibility = useSelector(getThreatAnalyticsAreaDisplay);
  const areaShape = useSelector(getThreatAnalyticsAreaShape);
  const isFlying = useSelector(getIsFlying);
  const {id: locationId} = useSelector(getLocation);
  const {name: locationName} = useSelector(getLocation);
  const hoodsLoading = useSelector(getLoadingNeighborhoods);
  const selectedDistricts = useSelector(getSelectedDistrictIds);
  const isMapReady = useSelector(getIsMapReady);

  const paintNeighborhoods = useCallback(() => {
    if (map?.isStyleLoaded() && isMapReady) {
      locationDistricts.forEach((neighborhood) => {
        let score = neighborhood.total;
        if (!Object.values(categories).some(Boolean)) {
          score = -1;
        } else if (!(score > range[0] && score <= range[1])) {
          score = -1;
        }
        map.setFeatureState(
          {
            source: 'nhoods',
            sourceLayer: NEIGHBORHOOD_LAYER,
            id: neighborhood.id,
          },
          {...neighborhood, total: score},
        );
      });
    }
  }, [locationDistricts, range, map, categories, isMapReady]);

  const clearNeighborhoods = useCallback(() => {
    if (map?.isStyleLoaded() && isMapReady) {
      map.removeFeatureState({
        source: 'nhoods',
        sourceLayer: NEIGHBORHOOD_LAYER,
      });
      map?.off('render', clearNeighborhoods);
    }
  }, [map, isMapReady]);

  useEffect(() => {
    if (
      isMapReady &&
      isDistrictsLayer &&
      locationDistricts?.length > 0 &&
      !isFlying &&
      !hoodsLoading
    ) {
      paintNeighborhoods();
      map?.on('render', paintNeighborhoods);
    }
    return () => {
      map?.off('render', paintNeighborhoods);
    };
  }, [
    map,
    isFlying,
    locationDistricts,
    range,
    locationName,
    isDistrictsLayer,
    showFilters,
    categories,
    mapStyle,
    hoodsLoading,
    isMapReady,
  ]);

  useEffect(
    () => () => {
      map?.on('render', clearNeighborhoods);
    },
    [],
  );

  const neighborhoodFillLayer = {
    id: 'neighborhoods-boundaries-layer',
    source: 'nhoods',
    'source-layer': NEIGHBORHOOD_LAYER,
    type: 'fill',
    filter: unmappedCities
      ? ['all']
      : ['==', ['get', 'locationId'], locationId || ''],
    layout: {
      visibility: showFilters ? 'visible' : 'none',
    },
    paint: {
      'fill-opacity': isDistrictsLayer ? 0.6 : 0,
      'fill-color': [
        'match',
        ['feature-state', 'total'],
        -1,
        theme.palette.background.transparent,
        0,
        theme.palette.quintile[0],
        1,
        theme.palette.quintile[1],
        2,
        theme.palette.quintile[2],
        3,
        theme.palette.quintile[3],
        4,
        theme.palette.quintile[4],
        5,
        theme.palette.quintile[5],
        unmappedCities
          ? theme.palette.background.default
          : theme.palette.background.transparent,
      ],
    },
  };

  const neighborhoodLinesLayer = {
    id: 'neighborhood-line-layer',
    source: 'nhoods',
    'source-layer': NEIGHBORHOOD_LAYER,
    type: 'line',
    filter: unmappedCities
      ? ['all']
      : ['==', ['get', 'locationId'], locationId || ''],
    layout: {
      visibility: showFilters && isDistrictsLayer ? 'visible' : 'none',
    },
    paint: {
      'line-width': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        2,
        0.5,
      ],
      'line-color': [
        'match',
        ['feature-state', 'total'],
        -1,
        theme.palette.background.transparent,
        0,
        theme.palette.quintile[0],
        1,
        theme.palette.quintile[1],
        2,
        theme.palette.quintile[2],
        3,
        theme.palette.quintile[3],
        4,
        theme.palette.quintile[4],
        5,
        theme.palette.quintile[5],
        unmappedCities
          ? theme.palette.background.default
          : theme.palette.background.transparent,
      ],
    },
  };

  const neighborhoodLabelsLayer = {
    id: 'neighborhoods-label-layer',
    source: 'nhoods',
    'source-layer': NEIGHBORHOOD_LAYER,
    type: 'symbol',
    layout: {
      'text-field': [
        'format',
        [
          'concat',
          ['get', 'id'],
          '\n',
          ['get', 'displayName'],
          '\n',
          ['get', 'originId'],
        ],
        {'text-color': theme.palette.text.primary, 'text-size': 14},
      ],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.5,
      visibility: unmappedCities ? 'visible' : 'none',
    },
  };

  const defineFilter = () => {
    if (areaShape === 'hood-polygon' && selectedDistricts.length > 0) {
      return ['in', ['get', 'id'], ['literal', selectedDistricts ?? []]];
    }
    if (areaShape !== 'hood-polygon') {
      return ['==', ['get', 'locationId'], locationId ?? ''];
    }
    return ['==', ['get', 'id'], clickedHood ?? ''];
  };

  const defineVisibility = () => {
    if (
      (isDistrictsLayer && selectedDistricts.length > 0) ||
      (areaShape !== 'circle' && radiusVisibility)
    ) {
      return 'visible';
    }
    return 'none';
  };

  const neighborhoodHighlightLineLayer = {
    id: 'neighborhood-layer-highlighted-outline',
    source: 'nhoods',
    'source-layer': NEIGHBORHOOD_LAYER,
    type: 'line',
    filter: defineFilter(),
    layout: {
      visibility: defineVisibility(),
    },
    paint: {
      'line-width': areaShape === 'hood-polygon' ? 2 : 0.5,
      'line-color': theme.palette.primary.main,
    },
  };

  const neighborhoodHighlightFillLayer = {
    id: 'neighborhood-layer-highlighted-fill',
    source: 'nhoods',
    'source-layer': NEIGHBORHOOD_LAYER,
    type: 'fill',
    filter:
      areaShape === 'hood-polygon'
        ? ['==', ['get', 'id'], clickedHood || '']
        : ['==', ['get', 'locationId'], locationId || ''],
    layout: {
      visibility:
        areaShape !== 'circle' && radiusVisibility ? 'visible' : 'none',
    },
    paint: {
      'fill-outline-color': theme.palette.background.transparent,
      'fill-opacity': 0.1,
      'fill-color': theme.palette.primary.main,
    },
  };

  return (
    <Source
      id="nhoods"
      type="vector"
      url={TILE_SERVICE_URL + NEIGHBORHOOD_LAYER + JSON}
      promoteId={{[NEIGHBORHOOD_LAYER]: 'id'}}>
      <Layer {...neighborhoodFillLayer} />
      <Layer {...neighborhoodLinesLayer} />
      <Layer {...neighborhoodHighlightFillLayer} />
      <Layer {...neighborhoodHighlightLineLayer} />
      <Layer {...neighborhoodLabelsLayer} />
    </Source>
  );
}

export default LayerDistricts;
