import {useDispatch, useSelector, useStore} from 'react-redux';

import {getSnackbar} from '@/selectors';
import {
  showSnackbarAction,
  hideSnackbarAction,
  addToSnackbarQueueAction,
  removeFromSnackbarQueueAction,
} from '@/store/modules/snackbar/actions';

const ANIMATION_DELAY = 300;

export const useSnackbar = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const {isOpen, isLoading, icon, iconColor, message, actions, onClick} =
    useSelector(getSnackbar);

  const processSnackbarQueue = () => {
    const {snackbar} = store.getState();
    const {snackbarQueue} = snackbar;

    if (snackbarQueue?.length > 0 && !snackbar.isOpen) {
      const message = snackbarQueue[0];

      let timeoutId;
      if (!message.loading && message.duration) {
        timeoutId = setTimeout(() => {
          dispatch(hideSnackbarAction());
          setTimeout(() => {
            processSnackbarQueue();
          }, ANIMATION_DELAY); // wait for the snackbar to close
        }, message.duration); // show snackbar for duration
      }

      dispatch(
        showSnackbarAction({
          ...message,
          timeoutId,
        }),
      );

      dispatch(removeFromSnackbarQueueAction(message.id));
    }
  };

  const hideSnackbar = (_, reason) => {
    if (reason === 'clickaway') return;

    // Clear timeout if it exists
    const currentTimeoutId = store.getState().snackbar.timeoutId;
    if (currentTimeoutId) {
      clearTimeout(currentTimeoutId);
    }

    // Hide snackbar
    dispatch(hideSnackbarAction());
    setTimeout(() => {
      processSnackbarQueue();
    }, ANIMATION_DELAY); // wait for the snackbar to close
  };

  const hideSnackbarById = (id) => {
    const currentMessageId = store.getState().snackbar.id;
    if (currentMessageId != null && id === currentMessageId) {
      hideSnackbar();
    }
  };

  const showSnackbar = ({
    id = Math.random().toString(36).substring(2, 11),
    icon,
    iconColor = 'primary',
    message,
    loading = false,
    duration = 3000,
    actions = [
      {
        component: 'icon',
        props: {
          onClick: id ? () => hideSnackbarById(id) : hideSnackbar,
        },
      },
    ],
    onClick,
  }) => {
    const {
      snackbar: {snackbarQueue},
    } = store.getState();

    // if message is already in the queue, don't add it again
    if (snackbarQueue.some((m) => m.message === message)) return;

    dispatch(
      addToSnackbarQueueAction({
        id,
        icon,
        iconColor,
        message,
        isLoading: loading,
        isOpen: false,
        duration,
        actions,
        onClick,
      }),
    );

    processSnackbarQueue();
  };

  return {
    isOpen,
    isLoading,
    icon,
    iconColor,
    message,
    showSnackbar,
    hideSnackbar,
    hideSnackbarById,
    actions,
    onClick,
  };
};
