import {SET_SOURCE_CATEGORIES} from '@/store/modules/source-categories/actionTypes';

export const initialState = [];

const sourceCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOURCE_CATEGORIES:
      return action.sourceCategories;
    default:
      return state;
  }
};

export default sourceCategoriesReducer;
