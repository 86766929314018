import {
  HIDE_SNACKBAR,
  SHOW_SNACKBAR,
  ADD_TO_SNACKBAR_QUEUE,
  REMOVE_FROM_SNACKBAR_QUEUE,
} from '@/store/modules/snackbar/actionTypes';

export const initialState = {
  id: null,
  timeoutId: null,
  message: null,
  isLoading: false,
  isOpen: false,
  icon: null,
  iconColor: 'primary',
  actions: [],
  onClick: () => {},
  snackbarQueue: [],
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_SNACKBAR_QUEUE:
      return {
        ...state,
        snackbarQueue: [
          ...state.snackbarQueue,
          {
            id: action.id,
            timeoutId: action.timeoutId,
            icon: action.icon,
            iconColor: action.iconColor,
            message: action.message,
            actions: action.actions,
            onClick: action.onClick,
            duration: action.duration,
          },
        ],
      };
    case REMOVE_FROM_SNACKBAR_QUEUE:
      return {
        ...state,
        snackbarQueue: state.snackbarQueue.filter(
          (message) => message.id !== action.id,
        ),
      };
    case SHOW_SNACKBAR:
      return {
        ...state,
        id: action.id,
        timeoutId: action.timeoutId,
        icon: action.icon,
        iconColor: action.iconColor,
        message: action.message,
        isLoading: action.isLoading,
        isOpen: true,
        actions: action.actions,
        onClick: action.onClick,
        duration: action.duration,
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        id: null,
        timeoutId: null,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
