import {SET_THREAT_CATEGORIES} from '@/store/modules/threat-categories/actionTypes';

export const initialState = [];

const threatCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THREAT_CATEGORIES:
      return action.threatCategories;
    default:
      return state;
  }
};

export default threatCategoriesReducer;
