import {API_URL} from '@config';

export const validateRequest = ({queryKey, ...request}, token) => {
  const settings = queryKey
    ? {...queryKey[1], signal: request.signal}
    : request;
  const headers = {
    ...(settings.input?.headers || settings.headers),
    Authorization: `Bearer ${token}`,
  };
  const signal = settings.input?.signal || settings.signal;
  const path = settings.input?.path || settings.path;
  const query = settings.input?.query || settings.query;
  const inputBody = settings.input?.body;
  const mergedBody = {...settings.body, ...inputBody};
  const isFormData = inputBody instanceof FormData;
  const body = isFormData ? inputBody : mergedBody;
  const params = {...settings.params, ...settings.input?.params};
  const directory = query ? `${path}/${query}`.replaceAll('//', '/') : path;
  const url = API_URL + directory;
  return {url, body, params, headers, signal};
};
