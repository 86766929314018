import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {createTheme, ThemeProvider} from '@mui/material/styles';

import {getTheme} from '@/selectors';
import {darkTheme} from '@/styles/theme/dark';
import {reportsTheme} from '@/styles/theme/reports';

function StyledThemeProvider({children}) {
  const theme = useSelector(getTheme);

  const themes = {
    dark: darkTheme,
    reports: reportsTheme,
  };

  const muiTheme = useMemo(() => createTheme(themes[theme]), [theme]);

  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
}

StyledThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StyledThemeProvider;
