import {useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box, Divider, Fab, Typography, useTheme} from '@mui/material';
import {FileArrowDown} from '@phosphor-icons/react';

import {setReportModalOpen} from '@/store/modules/modal/actions';

import {useReport} from '@/hooks/api/useReport';
import {API_THREAT_RADIUS} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import {useTranslation} from '@/hooks/useTranslation';
import {useGranularity} from '@/hooks/charts/useGranularity';

import {GRANULARITY_TABS} from '@/components/analytics/constants';
import {styles} from '@/components/analytics/Analytics.styles';
import AnalyticsComponents from '@/components/analytics/AnalyticsComponents';
import AnalyticsSelector from '@/components/analytics/AnalyticsSelector';
import UserFeedback from '@/components/common/UserFeedback';
import AnalyticsGroup from '@/components/analytics/AnalyticsGroup';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import ReportDownloadModal from '@/components/report/ReportDownloadModal';
import BaseTooltip from '@/components/common/BaseTooltip';
import DataSourceAttribution from '@/components/analytics/sections/DataSourceAttribution';

function Analytics() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {getI18N} = useTranslation();
  const {availableReports} = useReport({});
  const {granularity, isRadiusEnabled, isDistrictEnabled, isCityEnabled} =
    useGranularity();

  const [activePanel, setActivePanel] = useState('overview');
  const [hoverDownloadReport, setHoverDownloadReport] = useState(false);

  const {
    disabledRadiusTooltip,
    disabledDistrictTooltip,
    disabledCityTooltip,
    disabledComparativeTooltip,
  } = getI18N('tooltips');
  const {panelType, reportButton} = getI18N('threatAnalytics');

  const components = useMemo(
    () =>
      AnalyticsComponents.filter((component) =>
        component.analyticsType.includes(activePanel),
      ),
    [activePanel],
  );

  const radiusData = useChart({
    path: API_THREAT_RADIUS,
    granularity: 'radius',
    customParams: {take: 1},
    disabled: !isRadiusEnabled,
  });

  const reportDownloadAvailable = useMemo(() => {
    const isReportAvail =
      availableReports?.data?.some(
        (report) => report.granularities.length > 0,
      ) ?? false;

    return (
      ((isRadiusEnabled && radiusData?.data?.data?.length > 0) ||
        isDistrictEnabled ||
        isCityEnabled) &&
      isReportAvail
    );
  }, [
    isRadiusEnabled,
    radiusData,
    isDistrictEnabled,
    isCityEnabled,
    availableReports?.data,
  ]);

  const disabledTooltips = {
    radius: disabledRadiusTooltip,
    district: disabledDistrictTooltip,
    city: disabledCityTooltip,
    comparative: disabledComparativeTooltip,
  };

  const enabledGranularityTabs = useMemo(
    () =>
      GRANULARITY_TABS.map((option) => {
        const isDisabled =
          (option.value === 'radius' && !isRadiusEnabled) ||
          (option.value === 'district' && !isDistrictEnabled) ||
          (option.value === 'city' && !isCityEnabled);
        return {
          ...option,
          disabled: isDisabled,
          icon: isDisabled ? (
            <BaseTooltip
              message={disabledTooltips[option.value]}
              iconSize={16}
              iconColor={theme.palette.text.disabled}
            />
          ) : null,
          iconPosition: 'end',
        };
      }),
    [isRadiusEnabled, isDistrictEnabled, isCityEnabled],
  );

  const handleReportDownload = () => {
    dispatch(setReportModalOpen(true));
  };

  const handleActivePanel = (value) => {
    setActivePanel(value);
  };

  return (
    <Box sx={styles.root}>
      <AnalyticsSelector
        components={AnalyticsComponents}
        selected={activePanel}
        onChange={handleActivePanel}
        granularity={granularity}
      />
      <Box sx={styles.card}>
        <Typography variant="body2" sx={styles.title}>
          {panelType[activePanel]}
        </Typography>
        <Divider />
        <Box sx={styles.content}>
          <AnalyticsGroup
            components={components}
            granularity={granularity}
            analyticsType={activePanel}
            setActivePanel={setActivePanel}
          />
          <FeatureAccessWrapper feature="report-downloads" action="hide">
            <ReportDownloadModal
              granularities={enabledGranularityTabs}
              availableReports={availableReports.data}
            />
            <Fab
              size="small"
              color="primary"
              variant={hoverDownloadReport ? 'extended' : 'round'}
              onMouseEnter={() => setHoverDownloadReport(true)}
              onMouseLeave={() => setHoverDownloadReport(false)}
              disabled={!reportDownloadAvailable}
              onClick={handleReportDownload}
              sx={styles.downloadFab}>
              <Box sx={styles.fabContent}>
                <FileArrowDown size={20} />
                {hoverDownloadReport && (
                  <Typography variant="body2">{reportButton}</Typography>
                )}
              </Box>
            </Fab>
          </FeatureAccessWrapper>
          {activePanel === 'source' && <DataSourceAttribution />}
          <UserFeedback context={activePanel} />
        </Box>
      </Box>
    </Box>
  );
}

export default Analytics;
