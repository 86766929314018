import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {useMap} from '@hooks/useMap';

import {
  setNewUserLocation,
  saveLocationPopupStatus,
  toggleAddingLocationStatus,
  setSavingLocationStatus,
  setInspectingLocationStatus,
  setClickedLocation,
  setThreatAnalyticsArea,
} from '@store/modules/user/actions';
import {useMixpanel} from '@hooks/useMixpanel';
import {useMapHandlers} from '@hooks/map/useMapHandlers';
import {useRouter} from '@hooks/useRouter';
import {useQuery} from '@hooks/useQuery';
import {
  setTimezone,
  setActiveCity,
  setLoadingTile,
} from '@/store/modules/filters/actions';
import {setSelectedDistrict} from '@/store/modules/districts/actions';
import {
  setDebugMode,
  setLocationAddress,
  setSelectionMarker,
  setUnmappedMode,
  setSearchMarker,
  setSelectedCity,
  setRoutedToMap,
} from '@/store/modules/map/actions';
import {
  setMapFiltersStatus,
  setAnalyticsPanelStatus,
} from '@/store/modules/drawer/actions';
import {
  getSearchResult,
  getUserSelectedLocation,
  getViewTypeWorld,
  getIsMapReady,
  getMap,
} from '@/selectors';

import {useTranslation} from '@/hooks/useTranslation';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';

export const useMapListeners = () => {
  const dispatch = useDispatch();
  const {map} = useMap();
  const {track} = useMixpanel();
  const {route} = useRouter();
  const {searchLocation, searchSavedLocation, clickLocation} = useMapHandlers();
  const {locale} = useTranslation();
  const query = useQuery();
  const {setMapTranslations} = useMapboxGL();

  const {routedToMap, isFlying, viewType, selectedCity} = useSelector(getMap);
  const isViewTypeWorld = useSelector(getViewTypeWorld);
  const searchResult = useSelector(getSearchResult);
  const selectedUserLocation = useSelector(getUserSelectedLocation);
  const isMapReady = useSelector(getIsMapReady);

  useEffect(() => {
    const modeDebugger = query.get('debug');
    const modeUnmapped = query.get('unmapped');
    if (isMapReady && modeUnmapped && modeDebugger) {
      if (modeDebugger === 'true') {
        dispatch(setDebugMode(true));
      }

      if (modeUnmapped === 'true') {
        dispatch(setUnmappedMode(true));
      }
    }
  }, [isMapReady]);

  useEffect(() => {
    if (isViewTypeWorld && !isFlying && isMapReady) {
      dispatch(setSearchMarker(null));
      dispatch(setActiveCity(null));
      dispatch(setTimezone(''));
      dispatch(setLocationAddress(null));
      dispatch(setMapFiltersStatus(false));
      dispatch(setSelectionMarker({}));
      dispatch(setSelectedDistrict());
      dispatch(setAnalyticsPanelStatus(false));
      dispatch(setNewUserLocation({}));
      dispatch(saveLocationPopupStatus());
      dispatch(toggleAddingLocationStatus());
      dispatch(setSavingLocationStatus());
      dispatch(setInspectingLocationStatus());
      dispatch(setClickedLocation());
      dispatch(setThreatAnalyticsArea({display: false}));
    }
  }, [viewType]);

  useEffect(() => {
    setMapTranslations(locale);
  }, [locale, setMapTranslations]);

  useEffect(() => {
    if (!isEmpty(searchResult) && isMapReady && map) {
      searchLocation(searchResult);
      track('User search location', {...searchResult, route});
    }
  }, [searchLocation, map, searchResult, isMapReady]);

  useEffect(() => {
    if (!isEmpty(selectedUserLocation) && routedToMap && isMapReady && map) {
      searchSavedLocation(selectedUserLocation);
      track('User search saved location', {...selectedUserLocation, route});
    }
  }, [searchSavedLocation, map, selectedUserLocation, isMapReady, routedToMap]);

  useEffect(() => {
    if (!isEmpty(selectedCity) && routedToMap && isMapReady && map) {
      clickLocation(selectedCity);
      dispatch(setSelectedCity({}));
      dispatch(setRoutedToMap(false));
    }
  }, [clickLocation, map, selectedCity, isMapReady, routedToMap]);

  const handleLayerLoading = useCallback(
    (event) => {
      if (event.dataType === 'source' && event.type === 'sourcedataloading') {
        dispatch(setLoadingTile(true));
      }
    },
    [dispatch],
  );

  const handleLayerLoaded = useCallback(() => {
    dispatch(setLoadingTile(false));
  }, [dispatch]);

  useEffect(() => {
    if (map) {
      map.on('sourcedataloading', handleLayerLoading);
      map.on('idle', handleLayerLoaded);
    }
    return () => {
      if (map) {
        map.off('sourcedataloading');
        map.off('idle');
      }
    };
  }, [map, handleLayerLoading, handleLayerLoaded]);

  return {};
};
