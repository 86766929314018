// Packages
import PropTypes from 'prop-types';

// MUI Components
import Box from '@mui/material/Box';
import TextLimit from '@/components/common/typography/TextLimit';

// Styles and constants
import {styles} from '@/components/analytics/notable-events/NotableEvents.styles';
import {useTranslation} from '@/hooks/useTranslation';

function NotableEvents({events = []}) {
  const {getThreatTypesTranslation} = useTranslation();

  const joinText = (subCategory, notes) => `${subCategory} - ${notes}`;

  return (
    <Box component="ul" sx={styles.widgetBox}>
      {events?.map(
        (event, index) =>
          event?.auxInfo && (
            <Box key={index} component="li" sx={styles.widget}>
              <TextLimit
                text={joinText(
                  getThreatTypesTranslation(event?.categoryId),
                  event?.auxInfo?.description,
                )}
                linesLimit={9}
                sx={styles.textLimit}
              />
            </Box>
          ),
      )}
    </Box>
  );
}

NotableEvents.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
};

export default NotableEvents;
