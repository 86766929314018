export const ZOOM = {
  HEATMAP_THRESHOLD: 10,
  INITIAL_ZOOM: 2,
};

export const TOP_NAVBAR_HEIGHT = 64;
export const LEFT_NAVBAR_WIDTH = 56;
export const ANALYTICS_DRAWER_WIDTH = 420;
export const FILTER_DRAWER_WIDTH = 360;

export const CHART_DISPLAY_OPTIONS = {
  TYPE: 'type',
  TAG: 'tag',
};
