import {useCallback} from 'react';
import {useMapHandlers} from '@hooks/map/useMapHandlers';

export const useMapMouseOut = () => {
  const {hideMapTooltip, highlightRadius, highlightCountry} = useMapHandlers();

  const handleMouseOutMap = useCallback(() => {
    hideMapTooltip();
    highlightCountry();
    highlightRadius();
  }, [hideMapTooltip, highlightCountry, highlightRadius]);

  return {handleMouseOutMap};
};
