import React, {memo} from 'react';
import {Layer, Source} from 'react-map-gl';
import {useTheme} from '@mui/material';

import {MARTIN_SERVICE_URL} from '@config';
import {TIMEZONE_LAYER} from '@/components/map/layers/constants';
import {useTimezoneMap} from '@/hooks/useTimezoneMap';

function LayerTimezone() {
  const mappedTimezones = useTimezoneMap();
  const theme = useTheme();

  const timezoneSourceSettings = {
    id: 'timezone-source',
    type: 'vector',
    url: MARTIN_SERVICE_URL + TIMEZONE_LAYER,
  };

  const timezoneLabelSettings = {
    id: 'timezone-labels-layer',
    source: 'timezone-source',
    'source-layer': TIMEZONE_LAYER,
    type: 'symbol',
    layout: {
      'text-field': ['get', 'tzid'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-size': 10,
      visibility: mappedTimezones ? 'visible' : 'none',
    },
    paint: {
      'text-color': theme.palette.primary.contrastText,
    },
  };

  const timezoneSelectableSettings = {
    id: 'timezone-layer',
    source: 'timezone-source',
    'source-layer': TIMEZONE_LAYER,
    type: 'fill',
    layout: {
      visibility: 'visible',
    },
    paint: {
      'fill-opacity': mappedTimezones ? 0.5 : 0,
      'fill-color': theme.palette.primary.main,
    },
  };

  return (
    <Source {...timezoneSourceSettings}>
      <Layer {...timezoneSelectableSettings} />
      <Layer {...timezoneLabelSettings} />
    </Source>
  );
}

export default memo(LayerTimezone);
