import {useMemo} from 'react';
import {Layer, Source} from 'react-map-gl';
import {useSelector} from 'react-redux';

import {useTheme} from '@mui/material';
import {TILE_SERVICE_URL} from '@config';
import {COUNTRY_LAYER, JSON} from '@map/layers/constants';
import {getLayers} from '@/selectors';

function LayerCountry() {
  const theme = useTheme();
  const {countries: isCountriesLayer} = useSelector(getLayers);

  const countrySourceSettings = {
    id: 'country-boundaries-source',
    type: 'vector',
    url: TILE_SERVICE_URL + COUNTRY_LAYER + JSON,
    promoteId: {[COUNTRY_LAYER]: 'id'},
  };

  const countryLayerSettings = useMemo(
    () => ({
      id: 'country-boundaries-layer',
      source: 'country-boundaries-source',
      'source-layer': COUNTRY_LAYER,
      type: 'fill',
      maxzoom: 7,
      layout: {
        visibility: isCountriesLayer ? 'visible' : 'none',
      },
      paint: {
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.8,
          0.5,
        ],
        'fill-color': [
          'step',
          ['get', 'rate'],
          theme.palette.map.disabled,
          0.01,
          theme.palette.decile[1],
          4,
          theme.palette.decile[2],
          6,
          theme.palette.decile[3],
          8,
          theme.palette.decile[4],
          10,
          theme.palette.decile[5],
          12,
          theme.palette.decile[6],
          14,
          theme.palette.decile[7],
          16,
          theme.palette.decile[8],
          20,
          theme.palette.decile[9],
          30,
          theme.palette.decile[10],
        ],
      },
    }),
    [isCountriesLayer],
  );

  const countryBorderLayerSettings = useMemo(
    () => ({
      id: 'country-line-layer',
      source: 'country-boundaries-source',
      'source-layer': COUNTRY_LAYER,
      type: 'line',
      maxzoom: 7,
      layout: {
        visibility: isCountriesLayer ? 'visible' : 'none',
      },
      paint: {
        'line-opacity': 0.7,
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2.5,
          0,
        ],
        'line-color': [
          'step',
          ['get', 'rate'],
          theme.palette.map.disabled,
          0.01,
          theme.palette.decile[1],
          4,
          theme.palette.decile[2],
          6,
          theme.palette.decile[3],
          8,
          theme.palette.decile[4],
          10,
          theme.palette.decile[5],
          12,
          theme.palette.decile[6],
          14,
          theme.palette.decile[7],
          16,
          theme.palette.decile[8],
          20,
          theme.palette.decile[9],
          30,
          theme.palette.decile[10],
        ],
      },
    }),
    [isCountriesLayer],
  );

  return (
    <Source {...countrySourceSettings}>
      <Layer {...countryLayerSettings} />
      <Layer {...countryBorderLayerSettings} />
    </Source>
  );
}

export default LayerCountry;
