import {useMemo, useState} from 'react';

import {
  API_THREAT_CATEGORIES_TREND,
  API_THREAT_CATEGORIES_TREND_RADIUS,
} from '@/hooks/api/constants';
import {useTranslation} from '@/hooks/useTranslation';
import {useChart} from '@/hooks/api/useChart';
import {useMoment} from '@/hooks/useMoment';

export const useCategoryTrend = ({
  id = 'category-trend',
  title = 'Category Trend',
  granularity,
  aggregation,
  format = 'primary',
  type,
  onlyDownloads,
}) => {
  const {locale, getThreatTypesTranslation} = useTranslation();
  const {moment} = useMoment();
  const [disabled, setDisabled] = useState([]);

  const path = useMemo(
    () =>
      granularity === 'radius'
        ? API_THREAT_CATEGORIES_TREND_RADIUS
        : API_THREAT_CATEGORIES_TREND,
    [granularity],
  );

  const {
    data,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    path,
    granularity,
    exactDates: false,
    onlyDownloads,
    customParams: {
      timeAggregation: aggregation,
      type,
    },
  });

  // Format data, and order parent categories
  const formatData = (data) => {
    const result = {
      [getThreatTypesTranslation(58)]: [],
      [getThreatTypesTranslation(59)]: [],
      [getThreatTypesTranslation(57)]: [],
      [getThreatTypesTranslation(55)]: [],
      [getThreatTypesTranslation(60)]: [],
      [getThreatTypesTranslation(56)]: [],
    };

    data.forEach(({date, categoryId, count}) => {
      const category = getThreatTypesTranslation(categoryId);
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push({
        x: moment(date).local().toDate(),
        y: count,
      });
    });
    return Object.entries(result).reduce((acc, [key, value]) => {
      if (value.length > 0) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  // Primary Category Data
  const primaryData = useMemo(() => {
    if (!data?.chartData || data.chartData.length === 0) {
      return {};
    }
    const raw = data.chartData
      .filter(({isParent}) => isParent)
      .sort((d) => d.count);

    return formatData(raw);
  }, [data, locale]);

  // Sub-category Data
  const subData = useMemo(() => {
    if (!data?.chartData || data.chartData.length === 0) {
      return {};
    }
    const raw = data.chartData
      .filter(({isParent}) => !isParent)
      .sort((d) => d.count);

    return formatData(raw);
  }, [data, locale]);

  // Fromatted Data
  const formattedData = useMemo(() => {
    if (format === 'primary') {
      return primaryData;
    }
    if (format === 'sub') {
      return subData;
    }
  }, [primaryData, subData, format]);

  // Length of series
  const seriesLength = useMemo(
    () => Object.keys(formattedData).length || 1,
    [formattedData],
  );

  const legend = useMemo(
    () =>
      Object.keys(formattedData)
        .filter((key) => formattedData[key].length > 0)
        .map((key) => ({
          label: key,
          value: key,
        })) || [],
    [formattedData],
  );

  // Data filtered by disabled array
  const filteredData = useMemo(() => {
    if (disabled.length === 0) {
      return formattedData;
    }
    const result = {};
    Object.keys(formattedData).forEach((key) => {
      if (!disabled.includes(key)) {
        result[key] = formattedData[key];
      } else {
        result[key] = formattedData[key].map((d) => ({
          ...d,
          y: 0,
          disabled: true,
        }));
      }
    });
    return result;
  }, [disabled, formattedData]);

  return {
    seriesLength,
    data: filteredData,
    error,
    isFetching,
    legend,
    disabled,
    setDisabled,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
