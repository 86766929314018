import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {styles} from '@report/ReportDownloadModal.styles';

import BaseCheckbox from '@common/checkboxes/BaseCheckbox';
import BaseExpandableCheckboxGroup from '@common/checkboxes/BaseExpandableCheckboxGroup';
import {getLocationThreatTypes} from '@/selectors';

import {useTranslation} from '@/hooks/useTranslation';

function ReportCustomBuilder({
  granularity = {label: '', value: '', disabled: false},
  reportPages = [],
  onChange = () => {},
}) {
  const {getI18N} = useTranslation();

  const locationThreatTypes = useSelector(getLocationThreatTypes);

  const [selectedGranularity, setSelectedGranularity] = useState(false);
  const [selectedPages, setSelectedPages] = useState([]);

  const threatTypeLabels = getI18N('filters.categories');
  const {selectionLabel, infoCaptionLabel, allThreatsTypesLabel} = getI18N(
    'reportDownloadModal',
  );

  const filterReportPages = useCallback(
    (value, id) => {
      // Omit districtAnalysis as it does not make sense for city granularity
      if (value === 'districtAnalysis' && granularity.value === 'city')
        return false;
      return id === 0 || (id !== 0 && value !== 'summary');
    },
    [granularity.value],
  );

  const customPages = useMemo(() => {
    const parentOptions = [
      {id: 0, name: allThreatsTypesLabel, parentId: null},
      ...locationThreatTypes.filter(({parentId}) => !parentId),
    ];

    return [
      ...parentOptions.map(({id, parentId, name}) => ({
        id,
        name: threatTypeLabels[id] ?? name,
        parentId,
      })),
      ...parentOptions.flatMap(({id}) =>
        reportPages
          .filter(({value}) => filterReportPages(value, id))
          .map(({value, label}) => ({
            id: `${value}-${id}`,
            name: label,
            parentId: id,
            page: value,
          })),
      ),
    ];
  }, [locationThreatTypes, threatTypeLabels, reportPages]);

  const formattedPages = useMemo(
    () =>
      customPages
        .filter(({id}) => selectedPages.includes(id))
        .map(({page, parentId}) => ({
          page,
          categoryId: parentId !== 0 ? parentId : null,
        }))
        .reduce((result, {categoryId, page}) => {
          const existingGroup = result.find(
            (group) => group.categoryId === categoryId,
          );
          if (existingGroup) {
            existingGroup.pages.push(page);
          } else {
            result.push({categoryId, pages: [page]});
          }
          return result;
        }, []),
    [selectedPages, customPages],
  );

  const handleGranularity = () => {
    setSelectedGranularity(!selectedGranularity);
    if (!selectedGranularity) {
      setSelectedPages(
        customPages
          .filter(({parentId}) => typeof parentId === 'number')
          .map(({id}) => id),
      );
    } else {
      setSelectedPages([]);
    }
  };

  const handleChange = (selectedIds) => {
    setSelectedPages(selectedIds);
    if (selectedIds.length > 0) {
      setSelectedGranularity(true);
    } else {
      setSelectedGranularity(false);
    }
  };

  useEffect(() => {
    onChange({
      granularity: granularity.value,
      enabled: selectedGranularity,
      reportPages: formattedPages,
    });
  }, [granularity, selectedGranularity, formattedPages]);

  return (
    <Box sx={styles.customReportBuilder}>
      <BaseCheckbox
        bold
        label={granularity.label}
        sx={styles.reportOptionsGranularity}
        checked={selectedGranularity}
        onClick={handleGranularity}
        disabled={granularity.disabled}
      />
      <Box sx={styles.reportOptionsContent}>
        <Box sx={styles.reportOptionsHeader}>
          <Typography variant="body2" sx={styles.reportOptionsTitle}>
            {selectionLabel}
          </Typography>
          <Typography variant="caption" sx={styles.reportOptionsCaption}>
            {infoCaptionLabel}
          </Typography>
        </Box>
        <BaseExpandableCheckboxGroup
          selected={selectedPages}
          onChange={handleChange}
          disabled={granularity.disabled}
          options={customPages}
        />
      </Box>
    </Box>
  );
}

ReportCustomBuilder.propTypes = {
  granularity: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
  reportPages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ReportCustomBuilder;
