import React from 'react';
import PropTypes from 'prop-types';

// MUI
import {Typography} from '@mui/material';
import {styles} from '@/components/common/inputs/BaseInputLabel.styles';

function BaseInputLabel({label = '', required = false, sx, ...props}) {
  const themeInputStyles = (theme) => ({
    ...styles.inputLabel(theme),
    ...(typeof sx === 'function' ? sx(theme) : sx),
  });

  return (
    <Typography
      variant="caption"
      sx={themeInputStyles}
      required={required}
      {...props}>
      {label}
    </Typography>
  );
}

BaseInputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BaseInputLabel;
