import {useMemo} from 'react';
import {useQuery} from './useQuery';

export function useUnmappedCity() {
  const query = useQuery();

  return useMemo(() => {
    const hasUnmapped = query.get('unmapped');

    if (hasUnmapped === null) return false;

    return true;
  }, []);
}
