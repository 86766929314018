import {useMemo} from 'react';
import {useQuery} from '@/hooks/useQuery';

export function useTimezoneMap() {
  const query = useQuery();

  return useMemo(() => {
    const usingTimezoneMap = query.get('timezones');

    return usingTimezoneMap !== null;
  }, []);
}
