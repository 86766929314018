import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material';

import {useTranslation} from '@/hooks/useTranslation';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import SankeyChart from '@/components/common/charts/SankeyChart';

function EventBreakdown({
  data = {chartData: []},
  isLoading = false,
  type = 'all',
  height,
  width,
}) {
  const theme = useTheme();
  const {getChartColors} = useChartTheme();
  const {locale, getThreatTypesTranslation, getTimeSliceTranslation} =
    useTranslation();

  const nodes = useMemo(() => {
    const n = data?.chartData?.nodes;
    const links = data?.chartData?.links;
    if (!n || n.length === 0 || !links || links.length === 0) return [];

    // Isolate nodes by depth
    const categoryNodes = n.filter(({type}) => type === 'parentCategory');
    const subCategoryNodes = n.filter(({type}) => type === 'category');
    const daytimeNodes = n.filter(({type}) => type === 'daytime');

    // Get theme colors based on number of parent nodes * 2
    let colorsLength = categoryNodes.length * 2;
    if (categoryNodes.length === 0) {
      colorsLength = subCategoryNodes.length;
    }

    const colors = getChartColors(colorsLength);

    // Create array of nodes where parent node has color index 0, nodes with source of parent node have color index 1, etc.
    const formattedNodes = [];

    // Add first & second nodes w/ proper colors
    let index = 0;
    categoryNodes.forEach((node) => {
      formattedNodes.push({
        name: getThreatTypesTranslation(node.id),
        color: colors[index % colors.length],
        value: node.value,
        type: node.type,
      });

      // Color sub categories when parent node is present
      if (subCategoryNodes.length > 0) {
        // Get all links where source is parent node
        const firstLinks = links.filter(({source}) => source === node.name);
        // for each link, add node with color index 1
        firstLinks.forEach((link) => {
          const secondNode = subCategoryNodes.find(
            ({name}) => name === link.target,
          );
          formattedNodes.push({
            name: getThreatTypesTranslation(secondNode.id),
            color: colors[(index + 1) % colors.length],
            value: secondNode.value,
            type: secondNode.type,
          });
        });
      }
      index += 2;
    });

    if (categoryNodes.length === 0) {
      subCategoryNodes.forEach((node, i) => {
        formattedNodes.push({
          name: getThreatTypesTranslation(node.id),
          color: colors[i % colors.length],
          value: node.value,
          type: node.type,
        });
      });
    }

    daytimeNodes.forEach((node) => {
      formattedNodes.push({
        name: getTimeSliceTranslation(node.id),
        color: theme.palette.chart[8],
        value: node.value,
        type: node.type,
      });
    });

    return formattedNodes;
  }, [data, locale]);

  const links = useMemo(() => {
    const n = data?.chartData?.nodes;
    const links = data?.chartData?.links;
    if (!n || n.length === 0 || !links || links.length === 0) return [];

    // For each link, find asscoiated node, use node id to translate name, and add to links array
    const formattedLinks = [];
    links.forEach((link) => {
      const sourceNode = n.find(({name}) => name === link.source);
      const targetNode = n.find(({name}) => name === link.target);

      // If sourceNode or targetNode type is parentCategory or category, use id to translate name
      let sourceName = '';
      let targetName = '';
      if (sourceNode.type === 'daytime') {
        sourceName = sourceNode.name;
      } else {
        sourceName = getThreatTypesTranslation(sourceNode.id);
      }
      if (targetNode.type === 'daytime') {
        targetName = getTimeSliceTranslation(targetNode.id);
      } else {
        targetName = getThreatTypesTranslation(targetNode.id);
      }

      formattedLinks.push({
        source: sourceName,
        target: targetName,
        value: link.value,
      });
    });

    return formattedLinks;
  }, [data, locale]);

  const sankeyData = useMemo(() => ({nodes, links}), [data, type]);

  if (sankeyData.nodes.length === 0 || sankeyData.links.length === 0)
    return null;

  return (
    <SankeyChart
      height={height}
      width={width}
      data={sankeyData}
      isLoading={isLoading}
    />
  );
}

EventBreakdown.propTypes = {
  type: PropTypes.oneOf(['all', 'categories', 'subCategories']),
  height: PropTypes.number,
  width: PropTypes.number,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default EventBreakdown;
