import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {Box, useTheme} from '@mui/material';
import {curveStepAfter} from '@visx/curve';

import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseLine from '@/components/common/charts/XYCharts/BaseLine';
import BaseLegend from '@/components/common/charts/BaseLegend';
import {styles} from '@/components/analytics/sections/EventTrendSection.styles';
import SectionTabs from '@/components/analytics/sections/SectionTabs';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';

import {useTranslation} from '@/hooks/useTranslation';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import {useCategoryTrend} from '@/hooks/charts/useCategoryTrend';
import {useTimeSeries} from '@/hooks/charts/useTimeSeries';
import {useRollingAverage} from '@/hooks/charts/useRollingAverage';
import {useHistoricalAverage} from '@/hooks/charts/useHistoricalAverage';
import {useTrendLine} from '@/hooks/charts/useTrendLine';
import {useMoment} from '@/hooks/useMoment';

function EventTrendSection({id, granularity, sx, isReport, settings, isGTM}) {
  const theme = useTheme();
  const {moment} = useMoment();
  const {locale, getI18N} = useTranslation();
  const {getChartColors} = useChartTheme();

  const [averageType, setAverageType] = useState('trendline');
  const [aggregation, setAggregation] = useState('monthly');

  const title = getI18N('threatAnalytics.chartTitle4');
  const {eventCount} = getI18N('chartQuintile');
  const {label10: monthly} = getI18N('threatAnalytics');
  const {trendline: trendlineTooltip, historical: historicalTooltip} =
    getI18N('tooltips');
  const {average, trendline, rollingAverage, historicalAverage, daily} =
    getI18N('chartSelection');

  // Average Type Options
  const averageTypeOptions = useMemo(
    () => [
      {
        label: trendline,
        value: 'trendline',
        loading: true,
        tooltip: trendlineTooltip,
      },
      {
        label: average,
        value: 'average',
        loading: true,
      },
      {
        label: rollingAverage,
        value: 'rolling',
        loading: true,
      },
      {
        label: historicalAverage,
        value: 'historical',
        loading: true,
        tooltip: historicalTooltip,
      },
    ],
    [locale],
  );

  // Aggregation Options
  const aggregationOptions = useMemo(
    () => [
      {
        label: daily,
        value: 'daily',
      },
      {
        label: monthly,
        value: 'monthly',
      },
    ],
    [locale],
  );

  // Generate file title for downloads
  const downloadTitle = useMemo(() => {
    if (averageType === 'average')
      return `${title} - ${eventCount} & ${average}`;
    if (averageType === 'rolling') return `${title} - ${rollingAverage}`;
    if (averageType === 'historical') return `${title} - ${historicalAverage}`;
  }, [averageType, locale]);

  // Time Series Hook
  const {
    data: countData,
    averageData,
    error: countError,
    isFetching: countIsLoading,
  } = useTimeSeries({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
    type: 'crime',
  });

  // Category Trend Hook
  const {
    seriesLength: crimeSeriesLength,
    data: crimeCategoryData,
    error: crimeCategoryError,
    isFetching: crimeCategoryIsLoading,
    legend: crimeLegend,
    disabled: crimeDisabled,
    setDisabled: setCrimeDisabled,
  } = useCategoryTrend({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
    type: 'crime',
  });

  const {
    seriesLength: unrestSeriesLength,
    data: unrestCategoryData,
    error: unrestCategoryError,
    isFetching: unrestCategoryIsLoading,
    legend: unrestLegend,
    disabled: unrestDisabled,
    setDisabled: setUnrestDisabled,
  } = useCategoryTrend({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
    type: 'unrest',
  });

  const {downloadImage, copyImage, downloadCSV, copyCSV} = useCategoryTrend({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
    onlyDownloads: true,
  });

  // Rolling Average Hook
  const {
    data: rollingAverageData,
    error: rollingAverageError,
    isFetching: rollingAverageIsLoading,
    downloadCSV: downloadRollingAverageCSV,
    copyCSV: copyRollingAverageCSV,
  } = useRollingAverage({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
  });

  // Historical Average Hook
  const {
    data: historicalAverageData,
    error: historicalAverageError,
    isFetching: historicalAverageIsLoading,
    downloadCSV: downloadHistoricalAverageCSV,
    copyCSV: copyHistoricalAverageCSV,
  } = useHistoricalAverage({
    id,
    title: downloadTitle,
    granularity,
  });

  const {
    data: trendlineData,
    error: trendlineError,
    isFetching: trendlineIsLoading,
    downloadCSV: downloadTrendlineCSV,
    copyCSV: copyTrendlineCSV,
  } = useTrendLine({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
  });

  const isAnyLoading = useMemo(
    () =>
      unrestCategoryIsLoading ||
      crimeCategoryIsLoading ||
      trendlineIsLoading ||
      countIsLoading ||
      rollingAverageIsLoading ||
      historicalAverageIsLoading,
    [
      unrestCategoryIsLoading,
      crimeCategoryIsLoading,
      trendlineIsLoading,
      countIsLoading,
      rollingAverageIsLoading,
      historicalAverageIsLoading,
    ],
  );

  const isLoading = useMemo(
    () =>
      unrestCategoryIsLoading &&
      crimeCategoryIsLoading &&
      trendlineIsLoading &&
      countIsLoading &&
      rollingAverageIsLoading &&
      historicalAverageIsLoading,
    [
      unrestCategoryIsLoading,
      crimeCategoryIsLoading,
      trendlineIsLoading,
      countIsLoading,
      rollingAverageIsLoading,
      historicalAverageIsLoading,
    ],
  );

  const enabledAverageTypes = useMemo(
    () =>
      averageTypeOptions
        .map((option) => {
          const disabled =
            (option.value === 'trendline' && Boolean(trendlineError)) ||
            (option.value === 'average' && Boolean(countError)) ||
            (option.value === 'rolling' && Boolean(rollingAverageError)) ||
            (option.value === 'historical' && Boolean(historicalAverageError));

          const loading =
            (option.value === 'trendline' && Boolean(trendlineIsLoading)) ||
            (option.value === 'average' && Boolean(countIsLoading)) ||
            (option.value === 'rolling' && Boolean(rollingAverageIsLoading)) ||
            (option.value === 'historical' &&
              Boolean(historicalAverageIsLoading));

          return {
            ...option,
            disabled,
            loading,
          };
        })
        .filter((option) => {
          if (option.value === 'historical' && aggregation === 'daily') {
            return false;
          }
          return true;
        }),
    [
      averageTypeOptions,
      trendlineError,
      countError,
      rollingAverageError,
      historicalAverageError,
      trendlineIsLoading,
      countIsLoading,
      rollingAverageIsLoading,
      historicalAverageIsLoading,
      aggregation,
    ],
  );

  // Get historical average for each x value
  const historicalData = useMemo(
    () =>
      countData.map((d) => {
        // Get historical average for the month
        const historicalAverage = historicalAverageData.find(
          (h) => moment(d.x).month() === h.monthNumber - 1,
        );
        return {
          x: d.x,
          y: historicalAverage?.y || 0,
        };
      }),
    [countData, historicalAverageData],
  );

  // Get colors for each series
  const crimeColors = useMemo(() => {
    const chartColors = getChartColors(crimeSeriesLength + 7).slice(
      0,
      crimeSeriesLength,
    );
    const primary = theme.palette.primary.main;
    return [...chartColors, primary, primary, primary, primary];
  }, [getChartColors, crimeSeriesLength, theme]);

  const unrestColors = useMemo(() => {
    const chartColors = getChartColors(unrestSeriesLength + 7);
    return [chartColors[3], chartColors[6], chartColors[8]];
  }, [getChartColors, unrestSeriesLength]);

  const trendlineChart = useMemo(
    () => ({[trendline]: averageType === 'trendline' ? trendlineData : []}),
    [trendline, averageType, trendlineData],
  );

  const averageChart = useMemo(
    () => ({[average]: averageType === 'average' ? averageData : []}),
    [average, averageType, averageData],
  );

  const rollingAverageChart = useMemo(
    () => ({
      [rollingAverage]: averageType === 'rolling' ? rollingAverageData : [],
    }),
    [rollingAverage, averageType, rollingAverageData],
  );

  const historicalChart = useMemo(
    () => ({
      [historicalAverage]: averageType === 'historical' ? historicalData : [],
    }),
    [historicalAverage, averageType, historicalData],
  );

  const handleDownload = () => {
    if (averageType === 'trendline') {
      downloadTrendlineCSV();
    } else if (averageType === 'average') {
      downloadCSV();
    } else if (averageType === 'rolling') {
      downloadRollingAverageCSV();
    } else if (averageType === 'historical') {
      downloadHistoricalAverageCSV();
    }
  };

  const handleCopy = () => {
    if (averageType === 'trendline') {
      copyTrendlineCSV();
    } else if (averageType === 'average') {
      copyCSV();
    } else if (averageType === 'rolling') {
      copyRollingAverageCSV();
    } else if (averageType === 'historical') {
      copyHistoricalAverageCSV();
    }
  };

  const handleLegendClick = useCallback(
    (value) => {
      if (crimeDisabled.includes(value)) {
        setCrimeDisabled(crimeDisabled.filter((v) => v !== value));
      } else {
        setCrimeDisabled([...crimeDisabled, value]);
      }

      if (unrestDisabled.includes(value)) {
        setUnrestDisabled(unrestDisabled.filter((v) => v !== value));
      } else {
        setUnrestDisabled([...unrestDisabled, value]);
      }
    },
    [crimeDisabled, unrestDisabled],
  );

  const allError = useMemo(
    () =>
      unrestCategoryError &&
      crimeCategoryError &&
      trendlineError &&
      countError &&
      rollingAverageError &&
      historicalAverageError,
    [
      unrestCategoryError,
      crimeCategoryError,
      trendlineError,
      countError,
      rollingAverageError,
      historicalAverageError,
    ],
  );

  const xAxisValues = useMemo(() => {
    // Get unique x values for all charts
    const xValues = new Set();
    const xData = isEmpty(crimeCategoryData)
      ? unrestCategoryData
      : crimeCategoryData;
    Object.values(xData).forEach((c) =>
      c.forEach((d) => xValues.add(moment(d.x).toISOString())),
    );
    trendlineData.forEach((d) => xValues.add(moment(d.x).toISOString()));
    averageData.forEach((d) => xValues.add(moment(d.x).toISOString()));
    rollingAverageData.forEach((d) => xValues.add(moment(d.x).toISOString()));
    historicalData.forEach((d) => xValues.add(moment(d.x).toISOString()));
    return Array.from(xValues)
      .sort((a, b) => moment(a).diff(moment(b)))
      .map((d) => moment(d).toDate());
  }, [
    crimeCategoryData,
    unrestCategoryData,
    trendlineData,
    averageData,
    rollingAverageData,
    historicalData,
  ]);

  const legend = useMemo(() => {
    const crimeLabels = crimeLegend.map((label, index) => ({
      ...label,
      color: crimeColors[index],
    }));
    const unrestLabels = unrestLegend.map((label, index) => ({
      ...label,
      color: unrestColors[index],
    }));
    return [...crimeLabels, ...unrestLabels];
  }, [crimeLegend, crimeColors, unrestLegend, unrestColors]);

  const hideUnrestChart = useMemo(
    () => unrestCategoryError,
    [unrestCategoryError],
  );

  const hideCrimeChart = useMemo(
    () => crimeCategoryError,
    [crimeCategoryError],
  );

  const crimeChartHeight = useMemo(() => {
    if (hideCrimeChart) return 0;
    const singleHeight = isReport ? sx.height || 200 : 200;
    const stackedHeight = isReport ? sx.height * 0.6 || 125 : 125;
    return hideUnrestChart ? singleHeight : stackedHeight;
  }, [hideUnrestChart, hideCrimeChart, isReport, sx]);

  const unrestChartHeight = useMemo(() => {
    if (hideUnrestChart) return 0;
    const singleHeight = isReport ? sx.height || 200 : 200;
    const stackedHeight = isReport ? sx.height * 0.4 || 75 : 75;
    return hideCrimeChart ? singleHeight : stackedHeight;
  }, [hideUnrestChart, hideCrimeChart, isReport, sx]);

  // Set averageType to first enabled averageType if current averageType is disabled
  useEffect(() => {
    const selected = enabledAverageTypes.find(
      (option) => option.value === averageType,
    );

    if (!selected || selected?.disabled) {
      const firstEnabled = enabledAverageTypes.find(
        (option) => !option.disabled,
      );
      setAverageType(firstEnabled?.value);
    }
  }, [enabledAverageTypes, averageType]);

  useEffect(() => {
    setAverageType(settings.metric);
    setAggregation(settings.period);
  }, [settings]);

  if (isReport)
    return (
      <ReportChartWrapper isLoading={isAnyLoading} error={Boolean(allError)}>
        <BaseXYChart
          hideGrid
          numYTicks={4}
          hideYOrigin={!hideUnrestChart}
          marginBottom={hideUnrestChart ? 20 : 0}
          height={crimeChartHeight}
          isLoading={isLoading}
          customColors={crimeColors}
          dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
          xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}>
          <BaseBar data={crimeCategoryData} type="stacked" />
          {!trendlineError && <BaseLine data={trendlineChart} />}
          <BaseLine data={averageChart} />
          <BaseLine data={rollingAverageChart} />
          <BaseLine data={historicalChart} curve={curveStepAfter} />
        </BaseXYChart>
        <BaseXYChart
          hideGrid
          hideYLastTick={!hideCrimeChart}
          numYTicks={!hideCrimeChart ? 3 : 4}
          marginTop={0}
          height={unrestChartHeight}
          isLoading={isLoading}
          customColors={unrestColors}
          dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
          xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}
          backgroundColor={theme.palette.background.contrast.dark}>
          <BaseBar data={unrestCategoryData} type="stacked" />
        </BaseXYChart>
        <BaseLegend
          sx={styles.legend}
          isLoading={crimeCategoryIsLoading || unrestCategoryIsLoading}
          labels={[...crimeLegend, ...unrestLegend]}
          disabled={crimeDisabled && unrestDisabled}
          onClick={handleLegendClick}
        />
      </ReportChartWrapper>
    );

  if (allError) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="eventTrend"
        granularity={granularity}
        downloadCSV={handleDownload}
        copyCSV={handleCopy}
        downloadImage={() => downloadImage()}
        copyImage={() => copyImage()}
        isGTM={isGTM && aggregation === 'monthly'}
        chart={
          <>
            <BaseXYChart
              hideGrid
              numYTicks={4}
              hideYOrigin={!hideUnrestChart}
              marginBottom={hideUnrestChart ? 20 : 0}
              height={crimeChartHeight}
              isLoading={isLoading}
              customColors={crimeColors}
              dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
              xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}>
              <BaseBar data={crimeCategoryData} type="stacked" />
              {!trendlineError && <BaseLine data={trendlineChart} />}
              <BaseLine data={averageChart} />
              <BaseLine data={rollingAverageChart} />
              <BaseLine data={historicalChart} curve={curveStepAfter} />
            </BaseXYChart>
            <BaseXYChart
              hideGrid
              showBackground={!hideCrimeChart}
              hideYLastTick={!hideCrimeChart}
              numYTicks={!hideCrimeChart ? 3 : 4}
              marginTop={0}
              height={unrestChartHeight}
              isLoading={isLoading}
              customColors={unrestColors}
              dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
              xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}
              backgroundColor={theme.palette.background.contrast.dark}>
              <BaseBar data={unrestCategoryData} type="stacked" />
            </BaseXYChart>
            <BaseLegend
              sx={styles.legend}
              isLoading={crimeCategoryIsLoading || unrestCategoryIsLoading}
              labels={legend}
              disabled={crimeDisabled && unrestDisabled}
              onClick={handleLegendClick}
            />
          </>
        }
        controls={
          <Box display="flex" flexDirection="column" gap={2}>
            <Box sx={{width: 170}}>
              <SectionTabs
                options={aggregationOptions}
                value={aggregation}
                onChange={(_, value) => setAggregation(value)}
              />
            </Box>
            <BaseRadioGroup
              dense
              onChange={setAverageType}
              selected={averageType}
              options={enabledAverageTypes}
            />
          </Box>
        }
        settings={{id, metric: averageType, period: aggregation}}
      />
    </Box>
  );
}

EventTrendSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isReport: PropTypes.bool,
  settings: PropTypes.object,
  isGTM: PropTypes.bool,
};

export default EventTrendSection;
