import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, useTheme} from '@mui/material';

import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import PeriodAverage from '@/components/analytics/charts/PeriodAverage';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import BaseLegend from '@/components/common/charts/BaseLegend';

import {
  API_HISTORICAL_DAY_OF_WEEK,
  API_HISTORICAL_DAY_OF_WEEK_RADIUS,
  API_HISTORICAL_MONTHLY,
  API_HISTORICAL_MONTHLY_RADIUS,
  API_HISTORICAL_TIME_OF_DAY,
  API_HISTORICAL_TIME_OF_DAY_RADIUS,
} from '@/hooks/api/constants';
import {useTranslation} from '@/hooks/useTranslation';
import {useChart} from '@/hooks/api/useChart';
import {useReportURL} from '@/hooks/report/useReportURL';

const paths = {
  month: API_HISTORICAL_MONTHLY,
  day: API_HISTORICAL_DAY_OF_WEEK,
  time: API_HISTORICAL_TIME_OF_DAY,
};

const radiusPaths = {
  month: API_HISTORICAL_MONTHLY_RADIUS,
  day: API_HISTORICAL_DAY_OF_WEEK_RADIUS,
  time: API_HISTORICAL_TIME_OF_DAY_RADIUS,
};

const styles = {
  legend: {
    marginTop: 2,
  },
};

function PeriodAverageSection({
  id,
  granularity,
  sx,
  isReport,
  settings,
  isGTM,
}) {
  const {locale, getI18N} = useTranslation();
  const {reportURL} = useReportURL();
  const theme = useTheme();

  const [period, setPeriod] = useState('month');

  const {monthOfYear, dayOfWeek, timeOfDay} = getI18N('chartSelection');
  const title = getI18N('threatAnalytics.chartTitle12');
  const threatCrime = getI18N('threatCrime');
  const unrestLabel = getI18N('threatUnrest');

  const downloadTitle = useMemo(() => {
    if (period === 'month') return `${title} - ${monthOfYear}`;
    if (period === 'day') return `${title} - ${dayOfWeek}`;
    if (period === 'time') return `${title} - ${timeOfDay}`;
  }, [period, locale]);

  const params = useMemo(
    () => ({
      id,
      title: downloadTitle,
      granularity,
    }),
    [id, granularity, downloadTitle],
  );

  const {
    data: monthlyCrimeData,
    error: monthlyCrimeError,
    isFetching: monthlyCrimeIsFetching,
  } = useChart({
    ...params,
    path: granularity === 'radius' ? radiusPaths.month : paths.month,
    customParams: {
      timeAggregation: 'monthly',
      type: 'crime',
    },
  });

  const {
    data: monthlyUnrestData,
    error: monthlyUnrestError,
    isFetching: monthlyUnrestIsFetching,
  } = useChart({
    ...params,
    path: granularity === 'radius' ? radiusPaths.month : paths.month,
    customParams: {
      timeAggregation: 'monthly',
      type: 'unrest',
    },
  });

  const {
    data: weeklyCrimeData,
    error: weeklyCrimeError,
    isFetching: weeklyCrimeIsFetching,
  } = useChart({
    ...params,
    path: granularity === 'radius' ? radiusPaths.day : paths.day,
    customParams: {
      type: 'crime',
    },
  });

  const {
    data: weeklyUnrestData,
    error: weeklyUnrestError,
    isFetching: weeklyUnrestIsFetching,
  } = useChart({
    ...params,
    path: granularity === 'radius' ? radiusPaths.day : paths.day,
    customParams: {
      type: 'unrest',
    },
  });

  const {
    data: timeCrimeData,
    error: timeCrimeError,
    isFetching: timeCrimeIsFetching,
  } = useChart({
    ...params,
    path: granularity === 'radius' ? radiusPaths.time : paths.time,
    customParams: {
      type: 'crime',
    },
  });

  const {
    data: timeUnrestData,
    error: timeUnrestError,
    isFetching: timeUnrestIsFetching,
  } = useChart({
    ...params,
    disabled: true,
    path: granularity === 'radius' ? radiusPaths.time : paths.time,
    customParams: {
      type: 'unrest',
    },
  });

  const downloadParams = useMemo(() => {
    let path = paths[period];
    let customParams;
    if (granularity === 'radius') path = radiusPaths[period];
    if (period === 'month') customParams = {timeAggregation: 'monthly'};
    return {
      ...params,
      path,
      onlyDownloads: true,
      customParams,
    };
  }, [period, params]);

  const {copyImage, downloadImage, downloadCSV, copyCSV} =
    useChart(downloadParams);

  const isAnyLoading = useMemo(
    () =>
      monthlyCrimeIsFetching ||
      weeklyCrimeIsFetching ||
      timeCrimeIsFetching ||
      monthlyUnrestIsFetching ||
      weeklyUnrestIsFetching ||
      timeUnrestIsFetching,
    [
      monthlyCrimeIsFetching,
      weeklyCrimeIsFetching,
      timeCrimeIsFetching,
      monthlyUnrestIsFetching,
      weeklyUnrestIsFetching,
      timeUnrestIsFetching,
    ],
  );

  const isCrimeLoading = useMemo(
    () =>
      monthlyCrimeIsFetching && weeklyCrimeIsFetching && timeCrimeIsFetching,
    [monthlyCrimeIsFetching, weeklyCrimeIsFetching, timeCrimeIsFetching],
  );

  const isUnrestLoading = useMemo(
    () =>
      monthlyUnrestIsFetching && weeklyUnrestIsFetching && timeUnrestIsFetching,
    [monthlyUnrestIsFetching, weeklyUnrestIsFetching, timeUnrestIsFetching],
  );

  const isCrimeError = useMemo(
    () =>
      (period === 'month' && monthlyCrimeError) ||
      (period === 'day' && weeklyCrimeError) ||
      (period === 'time' && timeCrimeError),
    [period, monthlyCrimeError, weeklyCrimeError, timeCrimeError],
  );

  const isUnrestError = useMemo(
    () =>
      (period === 'month' && monthlyUnrestError) ||
      (period === 'day' && weeklyUnrestError) ||
      (period === 'time' && timeUnrestError),
    [period, monthlyUnrestError, weeklyUnrestError, timeUnrestError],
  );

  const crimeData = useMemo(() => {
    if (period === 'month') return monthlyCrimeData;
    if (period === 'day') return weeklyCrimeData;
    if (period === 'time') return timeCrimeData;
  }, [period, monthlyCrimeData, weeklyCrimeData, timeCrimeData]);

  const unrestData = useMemo(() => {
    if (period === 'month') return monthlyUnrestData;
    if (period === 'day') return weeklyUnrestData;
    if (period === 'time') return timeUnrestData;
  }, [period, monthlyUnrestData, weeklyUnrestData, timeUnrestData]);

  const enabledPeriods = useMemo(
    () =>
      [
        {
          label: monthOfYear,
          value: 'month',
        },
        {
          label: dayOfWeek,
          value: 'day',
        },
        {
          label: timeOfDay,
          value: 'time',
        },
      ].map((option) => {
        const disabled =
          (option.value === 'month' &&
            Boolean(monthlyCrimeError && monthlyUnrestError)) ||
          (option.value === 'day' &&
            Boolean(weeklyCrimeError && weeklyUnrestError)) ||
          (option.value === 'time' &&
            Boolean(timeCrimeError && timeUnrestError));

        const loading =
          (option.value === 'month' &&
            Boolean(monthlyCrimeIsFetching || monthlyUnrestIsFetching)) ||
          (option.value === 'day' &&
            Boolean(weeklyCrimeIsFetching || weeklyUnrestIsFetching)) ||
          (option.value === 'time' &&
            Boolean(timeCrimeIsFetching || timeUnrestIsFetching));

        return {
          ...option,
          disabled,
          loading,
        };
      }),
    [
      monthlyCrimeError,
      monthlyUnrestError,
      weeklyCrimeError,
      weeklyUnrestError,
      timeCrimeError,
      timeUnrestError,
      monthlyCrimeIsFetching,
      monthlyUnrestIsFetching,
      weeklyCrimeIsFetching,
      weeklyUnrestIsFetching,
      timeCrimeIsFetching,
      timeUnrestIsFetching,
      locale,
    ],
  );

  const hideUnrestChart = useMemo(
    () => isUnrestError || period === 'time',
    [isUnrestError, period],
  );

  const hideCrimeChart = useMemo(() => isCrimeError, [isCrimeError]);

  const crimeChartHeight = useMemo(() => {
    if (hideCrimeChart) return 0;
    const singleHeight = isReport ? sx.height || 200 : 200;
    const stackedHeight = isReport ? sx.height * 0.6 || 125 : 125;
    return hideUnrestChart ? singleHeight : stackedHeight;
  }, [hideUnrestChart, hideCrimeChart, isReport, sx]);

  const unrestChartHeight = useMemo(() => {
    if (hideUnrestChart) return 0;
    const singleHeight = isReport ? sx.height || 200 : 200;
    const stackedHeight = isReport ? sx.height * 0.4 || 75 : 75;
    return hideCrimeChart ? singleHeight : stackedHeight;
  }, [hideUnrestChart, hideCrimeChart, isReport, sx]);

  const legend = useMemo(
    () =>
      [
        {value: 'crime', label: threatCrime, color: theme.palette.chart[1]},
        {value: 'unrest', label: unrestLabel, color: theme.palette.chart[4]},
      ].filter(({value}) =>
        value === 'unrest' ? !hideUnrestChart : !hideCrimeChart,
      ),
    [theme, threatCrime, unrestLabel, hideUnrestChart, hideCrimeChart],
  );

  // Set period to first enabled period if current period is disabled
  useEffect(() => {
    const selected = enabledPeriods.find((option) => option.value === period);

    if (selected?.disabled) {
      const firstEnabled = enabledPeriods.find((option) => !option.disabled);
      setPeriod(firstEnabled?.value);
    }

    if (reportURL?.customFilters) {
      setPeriod(reportURL?.customFilters.period);
    }
  }, [enabledPeriods, period, reportURL]);

  useEffect(() => {
    setPeriod(settings.period);
  }, [settings]);

  if (isReport)
    return (
      <ReportChartWrapper
        isLoading={isAnyLoading}
        error={Boolean(isCrimeError && isUnrestError)}>
        <PeriodAverage
          numYTicks={4}
          height={crimeChartHeight}
          width={sx.width}
          marginBottom={hideUnrestChart ? 20 : 0}
          hideYOrigin={!hideUnrestChart}
          type={period}
          data={crimeData}
          isFetching={isCrimeLoading}
          color={theme.palette.chart[1]}
        />
        <PeriodAverage
          numYTicks={!hideCrimeChart ? 2 : 4}
          marginTop={0}
          height={unrestChartHeight}
          type={period}
          data={unrestData}
          isLoading={isUnrestLoading}
          color={theme.palette.chart[4]}
          backgroundColor={theme.palette.background.contrast.dark}
        />
      </ReportChartWrapper>
    );

  if (isCrimeError && isUnrestError) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="periodAverage"
        downloadCSV={downloadCSV}
        copyCSV={copyCSV}
        downloadImage={downloadImage}
        copyImage={copyImage}
        isGTM={isGTM && period === 'month'}
        chart={
          <>
            <PeriodAverage
              numYTicks={4}
              height={crimeChartHeight}
              marginBottom={hideUnrestChart ? 20 : 0}
              hideYOrigin={!hideUnrestChart}
              type={period}
              data={crimeData}
              isLoading={isCrimeLoading}
              color={theme.palette.chart[1]}
            />
            <PeriodAverage
              showBackground={!hideCrimeChart}
              numYTicks={!hideCrimeChart ? 2 : 4}
              marginTop={0}
              height={unrestChartHeight}
              type={period}
              data={unrestData}
              isLoading={isUnrestLoading}
              color={theme.palette.chart[4]}
              backgroundColor={theme.palette.background.contrast.dark}
            />
          </>
        }
        controls={
          <BaseRadioGroup
            dense
            onChange={(value) => setPeriod(value)}
            selected={period}
            options={enabledPeriods}
          />
        }
        settings={{id, period}}
      />
      <BaseLegend
        sx={styles.legend}
        isLoading={isCrimeLoading || isUnrestLoading}
        labels={legend}
      />
    </Box>
  );
}

PeriodAverageSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isReport: PropTypes.bool,
  settings: PropTypes.object,
  isGTM: PropTypes.bool,
};

export default PeriodAverageSection;
