import {useSelector} from 'react-redux';
import {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import {
  getAnalyticsAvailability,
  getDrawer,
  getFiltersVisibility,
  getUserSavingLocationStatus,
} from '@/selectors';

import {
  ANALYTICS_DRAWER_WIDTH,
  FILTER_DRAWER_WIDTH,
  LEFT_NAVBAR_WIDTH,
} from '@/hooks/constants';
import {useRouter} from '@/hooks/useRouter';
import {useMapHandlers} from '@/hooks/map/useMapHandlers';

import {styles} from '@/components/navigation/Navbar.styles';
import DrawerBO from '@/components/common/drawer/DrawerBO';
import Filters from '@/components/filters/Filters';
import Analytics from '@/components/analytics/Analytics';
import Navigation from '@/components/navigation/list/Navigation';
import FilterSavedLocations from '@/components/filters/FilterSavedLocations';
import TopNavigation from '@/components/navigation/list/TopNavigation';

function Navbar({children}) {
  const {isMapRoute, isSavedRoute, isReportRoute, isMyLocationsAnalysisRoute} =
    useRouter();
  const {cancelSaveLocation} = useMapHandlers();

  const {analyticsWidth, analyticsHeight} = useSelector(getDrawer);
  const filtersOpen = useSelector(getFiltersVisibility);
  const isAnalyticsAvailable = useSelector(getAnalyticsAvailability);
  const {analyticsOpen} = useSelector(getDrawer);
  const isAnalyticsOpen = analyticsOpen && isAnalyticsAvailable;
  const isSavingLocation = useSelector(getUserSavingLocationStatus);
  const {savedLocationsFiltersOpen} = useSelector(getDrawer);
  const {isMyLocationsAnalysisFiltersOpen} = useSelector(getDrawer);

  const clickDrawerHandler = useCallback(() => {
    if (isSavingLocation) {
      cancelSaveLocation();
    }
  }, [isSavingLocation, cancelSaveLocation]);

  const drawers = useMemo(
    () => [
      {
        id: 'drawer-analytics',
        variant: 'persistent',
        anchor: 'right',
        width: `${ANALYTICS_DRAWER_WIDTH}px`,
        open: isAnalyticsOpen,
        isRoute: isMapRoute,
        component: Analytics,
      },
      {
        id: 'drawer-map-filters',
        variant: 'persistent',
        anchor: 'left',
        width: `${FILTER_DRAWER_WIDTH}px`,
        open: filtersOpen,
        isRoute: isMapRoute,
        component: Filters,
      },
      {
        id: 'drawer-saved-locations-filters',
        variant: 'persistent',
        anchor: 'left',
        width: `${FILTER_DRAWER_WIDTH}px`,
        open: savedLocationsFiltersOpen,
        isRoute: isSavedRoute,
        component: FilterSavedLocations,
      },
      {
        id: 'drawer-my-locations-analysis-filters',
        variant: 'persistent',
        anchor: 'left',
        width: `${FILTER_DRAWER_WIDTH}px`,
        open: isMyLocationsAnalysisFiltersOpen,
        isRoute: isMyLocationsAnalysisRoute,
        component: FilterSavedLocations,
      },
      {
        id: 'drawer-navigation',
        isMain: true,
        variant: 'persistent',
        anchor: 'left',
        width: `${LEFT_NAVBAR_WIDTH}px`,
        open: !isReportRoute,
        isRoute: !isReportRoute,
        onClick: clickDrawerHandler,
        component: Navigation,
      },
    ],
    [
      analyticsHeight,
      analyticsWidth,
      isMapRoute,
      isAnalyticsOpen,
      filtersOpen,
      isSavedRoute,
      savedLocationsFiltersOpen,
      isMyLocationsAnalysisFiltersOpen,
      isMyLocationsAnalysisRoute,
      clickDrawerHandler,
    ],
  );

  const width = useMemo(() => {
    let width = LEFT_NAVBAR_WIDTH;

    if (filtersOpen && isAnalyticsOpen && isMapRoute) {
      width = FILTER_DRAWER_WIDTH + ANALYTICS_DRAWER_WIDTH;
    } else if (isAnalyticsOpen && isMapRoute) {
      width = LEFT_NAVBAR_WIDTH + ANALYTICS_DRAWER_WIDTH;
    } else if (
      (savedLocationsFiltersOpen && isSavedRoute) ||
      (filtersOpen && isMapRoute) ||
      (isMyLocationsAnalysisFiltersOpen && isMyLocationsAnalysisRoute)
    ) {
      width = FILTER_DRAWER_WIDTH;
    }

    return `calc(100% - ${width}px)`;
  }, [
    filtersOpen,
    isAnalyticsOpen,
    savedLocationsFiltersOpen,
    isMapRoute,
    isSavedRoute,
    isMyLocationsAnalysisFiltersOpen,
    isMyLocationsAnalysisRoute,
  ]);

  const left = useMemo(() => {
    let left;

    if (filtersOpen && isAnalyticsOpen && isMapRoute) {
      left = FILTER_DRAWER_WIDTH;
    } else if (isAnalyticsOpen && isMapRoute) {
      left = LEFT_NAVBAR_WIDTH;
    }

    return left;
  }, [filtersOpen, isAnalyticsOpen, isMapRoute, isSavedRoute]);

  return (
    <>
      <AppBar sx={styles.appBar} elevation={0}>
        <Toolbar sx={styles.toolbar}>
          <TopNavigation />
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={styles.navbar}>
        {drawers.map(
          ({component: Component, isRoute, ...drawer}) =>
            isRoute && (
              <DrawerBO
                key={drawer.id}
                PaperProps={{id: drawer.id}}
                {...drawer}>
                <Component {...drawer} />
              </DrawerBO>
            ),
        )}
      </Box>
      <Box component="main" sx={styles.main} style={{width, left}}>
        {children}
      </Box>
    </>
  );
}

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Navbar;
