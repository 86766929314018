import {useMemo, useState, memo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {difference, uniq} from 'lodash';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {vscDarkPlus} from 'react-syntax-highlighter/dist/cjs/styles/prism';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';

import {
  getLayers,
  getViewType,
  getLoadingNeighborhoods,
  getLocation,
  getDistricts,
  getUnmappedMode,
} from '@/selectors';
import {setUnmappedMode} from '@/store/modules/map/actions';
import {LAYER_TYPE} from '@/components/map/constants';
import BaseSlideModal from '@/components/common/modals/BaseSlideModal';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';

const useStyle = () => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  icon: {
    cursor: 'pointer',
  },
});

function TabPanel({children, value, index, ...props}) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}>
      {value === index && children}
    </Box>
  );
}

TabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.any,
};

function LegendUnmappedRow({leftValue, rightValue, ...props}) {
  return (
    <TableRow {...props}>
      <TableCell>
        <pre style={{textAlign: 'center'}}>
          <code>{leftValue}</code>
        </pre>
      </TableCell>
      <TableCell>
        <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
          {rightValue}
        </SyntaxHighlighter>
      </TableCell>
    </TableRow>
  );
}

LegendUnmappedRow.propTypes = {
  leftValue: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired,
};

function LegendUnmapped() {
  const styles = useStyle();
  const dispatch = useDispatch();
  const {getDistricts: getDistrictsFeatures} = useMapboxGL();

  const viewType = useSelector(getViewType);
  const {districts: isDistrictsLayer} = useSelector(getLayers);
  const {locationDistricts} = useSelector(getDistricts);
  const isLoadingHoods = useSelector(getLoadingNeighborhoods);
  const {id: locationId} = useSelector(getLocation);
  const {name: locationName} = useSelector(getLocation);
  const unmappedMode = useSelector(getUnmappedMode);

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const closePane = () => {
    dispatch(setUnmappedMode(false));
  };

  const formattedQuintiles = useMemo(() => {
    if (locationDistricts && Object.keys(locationDistricts).length > 0) {
      const features = getDistrictsFeatures();

      const quintileArray = Object.keys(locationDistricts);

      const paneData = [];

      for (const quintile of quintileArray) {
        const pairs = features.filter((f) => `${f}` === quintile);

        if (pairs) {
          paneData.push({
            apiResponse: quintile,
            matchMap: pairs,
          });
        }
      }

      const unmmappedCitiesAPI = uniq(difference(quintileArray, features));
      const unmmappedCities = uniq(difference(features, quintileArray));

      return {
        paneData,
        unmmappedCities,
        unmmappedCitiesAPI,
        totalQuitiles: quintileArray.length,
        totalMapFeatures: features.length,
        totalUniqMapFeatures: uniq(features).length,
        totalMissingFeatures: unmmappedCities.length,
        totalMissingAPI: unmmappedCitiesAPI.length,
      };
    }
  }, [getDistrictsFeatures, locationName, locationDistricts]);

  if (!unmappedMode) return null;

  return (
    <BaseSlideModal
      show={
        Boolean(locationId ?? false) &&
        viewType !== LAYER_TYPE.WORLD &&
        isDistrictsLayer
      }
      padding={2}
      style={{overflow: 'auto', maxWidth: '30vw'}}>
      <AppBar position="static">
        <Box sx={styles.iconContainer}>
          <ExpandLess
            onClick={closePane}
            sx={styles.icon}
            style={{color: 'black', fontSize: 28}}
          />
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto">
          <Tab label="Neighborhoods" />
          <Tab label="Map entries with missing data" />
          <Tab label="Data with missing map entries" />
          <Tab label="Information" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableContainer component={Paper} style={{maxHeight: '70vh'}}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">API Response</TableCell>
                <TableCell align="center">Matched Map</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingHoods ? (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                formattedQuintiles && (
                  <>
                    {formattedQuintiles.paneData.map((feature, i) => (
                      <LegendUnmappedRow
                        key={`${feature.apiResponse}-${i}`}
                        leftValue={feature.apiResponse}
                        rightValue={JSON.stringify(feature.matchMap, null, 1)}
                      />
                    ))}
                  </>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1} padding={3}>
        {formattedQuintiles && (
          <TableContainer component={Paper} style={{maxHeight: '70vh'}}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Missing Neighborghoods</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <SyntaxHighlighter
                      language="javascript"
                      style={vscDarkPlus}>
                      {JSON.stringify(
                        formattedQuintiles.unmmappedCities,
                        null,
                        1,
                      )}
                    </SyntaxHighlighter>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>
      <TabPanel value={value} index={2} padding={3}>
        {formattedQuintiles && (
          <TableContainer component={Paper} style={{maxHeight: '70vh'}}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">API missing entries</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <SyntaxHighlighter
                      language="javascript"
                      style={vscDarkPlus}>
                      {JSON.stringify(
                        formattedQuintiles.unmmappedCitiesAPI,
                        null,
                        1,
                      )}
                    </SyntaxHighlighter>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>
      <TabPanel value={value} index={3} padding={3}>
        {formattedQuintiles && (
          <TableContainer component={Paper} style={{maxHeight: '70vh'}}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Useful information</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    {formattedQuintiles && (
                      <Box>
                        <Typography>
                          Total of API entries:{' '}
                          {formattedQuintiles.totalQuitiles}
                        </Typography>
                        <Typography>
                          Total of map entries:{' '}
                          {formattedQuintiles.totalMapFeatures}
                        </Typography>
                        <Typography>
                          Total of unique map entries:{' '}
                          {formattedQuintiles.totalUniqMapFeatures}
                        </Typography>
                        <Typography>
                          Total of map entries with missing data:{' '}
                          {formattedQuintiles.totalMissingFeatures}
                        </Typography>
                        <Typography>
                          Total of data with missing map entries:{' '}
                          {formattedQuintiles.totalMissingAPI}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>
    </BaseSlideModal>
  );
}

export default memo(LegendUnmapped);
