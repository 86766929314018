export const styles = {
  root: {
    width: '100%',
    textAlign: 'center',
  },
  optionHeader: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.light,
  }),
  collapsibleBox: {
    borderRadius: '4px',
  },
  divider: {
    margin: '0px 8px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 2,
  },
  parent: (theme) => ({
    marginBottom: '4px',
    backgroundColor: theme.palette.background.light,
  }),
  parentItem: {
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  childItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '7px 16px',
    paddingRight: '50px',
  },
  compactChildItem: {
    padding: 0,
    margin: 0,
    paddingRight: 0,
  },
  icon: (isDisabled, theme) => ({
    color: isDisabled
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    pointerEvents: isDisabled ? 'none' : 'auto',
  }),
};
