export const styles = {
  chartHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 2,
    justifyContent: 'space-between',
  },
  chartTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  icon: (theme) => ({
    color: theme.palette.secondary.light,
    backgroundColor: 'transparent',
  }),
  gtmBadge: {
    marginLeft: 1,
    height: '16px',
    width: '16px',
  },
};
