import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {CheckCircle} from '@phosphor-icons/react';
import BaseModal from '@/components/common/modals/BaseModal';
import BaseButton from '@/components/common/buttons/BaseButton';
import {styles} from '@/components/feature-access/FeatureTeaserModal.styles.js';
import {useTranslation} from '@/hooks/useTranslation';

function FeatureTeaserModal({
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  status = 'initial',
}) {
  const {getI18N} = useTranslation();
  const {
    contact,
    teaserBullet1,
    teaserBullet2,
    teaserBullet3,
    teaserTitle,
    closeLabel,
    upgradeFeedback,
    thanks,
  } = getI18N('featureAccess');

  return (
    <BaseModal sx={styles.root} open={isOpen} onClose={onClose}>
      <Box sx={styles.content}>
        <Typography variant="h5" sx={styles.title}>
          {teaserTitle}
        </Typography>
        <Box sx={styles.image} />
        <Box sx={styles.textContainer}>
          {status === 'initial' ? (
            <>
              <Box sx={styles.bullets}>
                <CheckCircle size={20} />
                <Typography variant="body2" sx={styles.text}>
                  {teaserBullet1}
                </Typography>
              </Box>
              <Box sx={styles.bullets}>
                <CheckCircle size={20} />
                <Typography variant="body2" sx={styles.text}>
                  {teaserBullet2}
                </Typography>
              </Box>
              <Box sx={styles.bullets}>
                <CheckCircle size={20} />
                <Typography variant="body2" sx={styles.text}>
                  {teaserBullet3}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="body1" sx={styles.thanksText}>
                {thanks}
              </Typography>
              <Typography variant="caption">{upgradeFeedback}</Typography>
            </>
          )}
        </Box>
        <BaseButton onClick={status === 'initial' ? onSubmit : onClose}>
          {status === 'initial' ? contact : closeLabel}
        </BaseButton>
      </Box>
    </BaseModal>
  );
}

FeatureTeaserModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
};

export default FeatureTeaserModal;
