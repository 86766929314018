import {RESET_STORE} from '@store/modules/actionTypes';
import {
  SET_USER,
  SET_NEW_LOCATION_TO_SAVE,
  TOGGLE_LOCATIONS_POPUP_STATUS,
  TOGGLE_ADDING_LOCATION_STATUS,
  SET_USER_MAP_STYLE,
  SET_USER_LANGUAGE,
  SET_INSPECTING_LOCATION_STATUS,
  SET_SAVING_LOCATION_STATUS,
  SET_SELECTED_LOCATION,
  SET_CLICKED_LOCATION_ID,
  SET_THREAT_ANALYTICS_AREA,
  SET_USER_LOADING,
  SET_UNITS,
  SET_USER_SETTINGS,
} from '@/store/modules/user/actionTypes';

export const setUserSettings = (payload) => ({
  type: SET_USER_SETTINGS,
  payload,
});

export const setUnits = (payload) => ({
  type: SET_UNITS,
  payload,
});

export const setThreatAnalyticsArea = (payload) => ({
  type: SET_THREAT_ANALYTICS_AREA,
  payload,
});

export const setUserLoading = (payload) => ({
  type: SET_USER_LOADING,
  payload,
});

export const setUserLanguage = (payload) => ({
  type: SET_USER_LANGUAGE,
  payload,
});

export const setUserMapStyle = (payload) => ({
  type: SET_USER_MAP_STYLE,
  payload,
});

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setNewUserLocation = (payload) => ({
  type: SET_NEW_LOCATION_TO_SAVE,
  payload,
});

export const saveLocationPopupStatus = (payload) => ({
  type: TOGGLE_LOCATIONS_POPUP_STATUS,
  payload,
});

export const toggleAddingLocationStatus = (payload) => ({
  type: TOGGLE_ADDING_LOCATION_STATUS,
  payload,
});

export const setInspectingLocationStatus = (payload) => ({
  type: SET_INSPECTING_LOCATION_STATUS,
  payload,
});

export const setSavingLocationStatus = (payload) => ({
  type: SET_SAVING_LOCATION_STATUS,
  payload,
});

export const setSelectedLocation = (payload) => ({
  type: SET_SELECTED_LOCATION,
  payload,
});

export const setClickedLocation = (payload) => ({
  type: SET_CLICKED_LOCATION_ID,
  payload,
});

export const resetStore = () => ({
  type: RESET_STORE,
});
