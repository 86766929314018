import * as Sentry from '@sentry/react';
import {SENTRY_DSN, SENTRY_ENV} from '@config';
import {useTheme} from '@mui/material';

export const useSentry = () => {
  const theme = useTheme();

  const initializeSentry = () => {
    Sentry.init({
      environment: SENTRY_ENV,
      enabled: SENTRY_ENV !== 'local',
      dsn: SENTRY_DSN,
      normalizeDepth: 6,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
          autoInject: false,
          showBranding: false,
          formTitle: 'Report an Issue',
          messagePlaceholder:
            'Identified an application bug or data anomaly? Requesting data for a location? Please describe the issue or request here.',
          submitButtonLabel: 'Send',
          themeDark: {
            background: theme.palette.background.paper,
            foreground: theme.palette.text.primary,
            accentBackground: theme.palette.primary.main,
            accentForeground: theme.palette.common.black,
            successColor: theme.palette.primary.main,
            errorColor: theme.palette.error.main,
          },
          colorScheme: 'dark',
          onFormClose: () => {
            window.dispatchEvent(new Event('sentryFeedbackFormClosed'));
          },
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0,
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  };

  const setSentryUser = (props) => Sentry.setUser(props ? {...props} : null);

  const isInitialized = () => Sentry.getClient();

  return {
    initializeSentry,
    isInitialized,
    setSentryUser,
    ErrorBoundary: Sentry.ErrorBoundary,
    captureExceptionSentry: Sentry.captureException,
  };
};
