import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import {useTranslation} from '@/hooks/useTranslation';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';

function EventSummary({
  data = {chartData: []},
  isLoading = false,
  type = 'count',
  height,
  width,
}) {
  const {getChartColors} = useChartTheme();
  const {locale, getThreatTypesTranslation, getI18N} = useTranslation();
  const {threatCount, percent} = getI18N('chartSelection');

  const countData = useMemo(
    () =>
      data?.chartData?.map(({categoryId, count}) => ({
        x: getThreatTypesTranslation(categoryId),
        y: count,
      })) || [],
    [data, locale],
  );

  const percentData = useMemo(() => {
    const total = data?.chartData?.reduce((acc, {count}) => acc + count, 0);

    return data?.chartData?.map(({categoryId, count}) => ({
      x: getThreatTypesTranslation(categoryId),
      y: Math.round((count / total) * 1000) / 10,
    }));
  }, [data, locale]);

  const formattedData = useMemo(() => {
    if (type === 'count') {
      return countData;
    }
    if (type === 'percent') {
      return percentData;
    }
  }, [countData, percentData, type]);

  const tooltipLabel = useMemo(() => {
    if (type === 'count') {
      return threatCount;
    }
    if (type === 'percent') {
      return percent;
    }
  }, [type, locale]);

  return (
    <BaseXYChart
      isLoading={isLoading}
      height={height}
      width={width}
      customColors={getChartColors(1)}
      angledTicks
      hideGrid
      marginBottom={110}>
      <BaseBar data={{[tooltipLabel]: formattedData}} />
    </BaseXYChart>
  );
}

EventSummary.propTypes = {
  type: PropTypes.oneOf(['count', 'percent']),
  height: PropTypes.number,
  width: PropTypes.number,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default EventSummary;
