import {
  SET_LOCATION_DISTRICTS,
  SET_SELECTED_DISTRICT,
} from '@/store/modules/districts/actionTypes';

export const setSelectedDistrict = (payload) => ({
  type: SET_SELECTED_DISTRICT,
  payload,
});

export const setLocationDistricts = (payload) => ({
  type: SET_LOCATION_DISTRICTS,
  payload,
});
