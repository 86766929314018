import {Layer, Source} from 'react-map-gl';
import {useSelector} from 'react-redux';

import {MARTIN_SERVICE_URL} from '@config';
import {
  getCityMarkersLayerSettings,
  getCityMarkersTextLayer,
} from '@/selectors';
import {CITY_MARKERS_LAYER} from '@/components/map/layers/constants';

function LayerCityMarkers() {
  const cityMarkersIcons = useSelector(getCityMarkersLayerSettings);
  const cityMarkersText = useSelector(getCityMarkersTextLayer);

  const citySourceSettings = {
    id: 'city-markers-source',
    type: 'vector',
    url: MARTIN_SERVICE_URL + CITY_MARKERS_LAYER,
  };

  return (
    <Source {...citySourceSettings}>
      <Layer {...cityMarkersText} />
      <Layer {...cityMarkersIcons} />
    </Source>
  );
}

export default LayerCityMarkers;
