import {useMemo} from 'react';

import {useCountry} from '@/hooks/api/useCountry';
import {useTranslation} from '@/hooks/useTranslation';

export const useCountryAnalysis = () => {
  const {getI18N} = useTranslation();
  const {countryCategories: labels} = getI18N('filters');
  const {
    countrySummary,
    countryScore,
    isFetchingCountryScore,
    countryQuintiles,
    isFetchingCountryQuintiles,
    countryCategories,
    isFetchingCategories,
  } = useCountry();

  const {
    id,
    name,
    code,
    continent,
    summary,
    recommendations,
    summaryUpdatedAt,
  } = countrySummary ?? {
    id: null,
    name: '',
    code: '',
    continent: '',
    summary: '',
    recommendations: [],
    summaryUpdatedAt: '',
  };

  const getSeverity = (score) => {
    if (score < 20) return 1;
    if (score < 40) return 2;
    if (score < 60) return 3;
    if (score < 80) return 4;
    return 5;
  };

  const formattedQuintiles = useMemo(() => {
    if (!countryCategories || !countryQuintiles) return [];
    if (isFetchingCategories || isFetchingCountryQuintiles) return [];
    return countryQuintiles.map(({categoryId, score}) => {
      const category = countryCategories.find(({id}) => id === categoryId);
      return {
        ...category,
        name: labels[category.id],
        severity: getSeverity(score),
        score,
      };
    });
  }, [
    countryQuintiles,
    isFetchingCountryQuintiles,
    countryCategories,
    isFetchingCategories,
    labels,
  ]);

  return {
    id,
    name,
    code,
    continent,
    summary,
    recommendations,
    summaryUpdatedAt,
    score: countryScore,
    quintiles: formattedQuintiles,
    isFetchingQuintiles: isFetchingCountryQuintiles,
    isFetchingScore: isFetchingCountryScore,
  };
};
