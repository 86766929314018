import isUndefined from 'lodash/isUndefined';
import {
  DISPLAY_SEARCH,
  SET_RECENT_SEARCHES,
  SET_REPORT_DOWNLOAD_MODAL_STATUS,
} from '@/store/modules/modal/actionTypes';
import {RESET_STORE} from '@/store/modules/actionTypes';

export const initialState = {
  isSearchOpen: false,
  recentSearches: [],
  isReportModelOpen: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_SEARCH:
      return {
        ...state,
        isSearchOpen: isUndefined(action.payload)
          ? !state.isSearchOpen
          : action.payload,
      };
    case SET_RECENT_SEARCHES:
      return {
        ...state,
        recentSearches: action.payload,
      };
    case SET_REPORT_DOWNLOAD_MODAL_STATUS: {
      return {
        ...state,
        isReportModelOpen: action.payload,
      };
    }
    case RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default modalReducer;
