import mixpanel from 'mixpanel-browser';

const Mixpanel = {
  initialized: false,
  init: (key) => {
    try {
      mixpanel.init(key);
      Mixpanel.initialized = true;
    } catch (error) {
      console.error('Mixpanel initialization error:', error);
    }
  },
  identify: (id) => {
    if (!Mixpanel.initialized) return;
    mixpanel.identify(id);
  },
  track: (name, props) => {
    if (!Mixpanel.initialized) return;
    mixpanel.track(name, props);
  },
  disable: () => {
    if (!Mixpanel.initialized) return;
    mixpanel.disable();
  },
  people: {
    set: (props) => {
      if (!Mixpanel.initialized) return;
      mixpanel.people.set(props);
    },
  },
};

export default Mixpanel;
