import {useMemo} from 'react';

import {
  API_ROLLING_AVERAGE,
  API_ROLLING_AVERAGE_RADIUS,
} from '@/hooks/api/constants';
import {useMoment} from '@/hooks/useMoment';
import {useChart} from '@/hooks/api/useChart';

export const useRollingAverage = ({
  id = 'rolling-average',
  title = 'Rolling Average',
  granularity,
  aggregation,
  type = 'crime',
}) => {
  const {moment} = useMoment();
  const path = useMemo(
    () =>
      granularity === 'radius'
        ? API_ROLLING_AVERAGE_RADIUS
        : API_ROLLING_AVERAGE,
    [granularity],
  );

  const {
    data: rawData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    granularity,
    path,
    exactDates: false,
    customParams: {
      timeAggregation: aggregation,
      type,
    },
  });

  const formattedRollingAverageData = useMemo(() => {
    const data = rawData?.chartData;
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((data) => ({
      x: moment(data.date).local().toDate(),
      y: data.rollingAverage,
    }));
  }, [rawData, aggregation]);

  return {
    data: formattedRollingAverageData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
