import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  SET_CATEGORIES,
  TOGGLE_CATEGORY,
  TOGGLE_ALL_CATEGORIES,
  TOGGLE_TIMESLICE,
  TOGGLE_ALL_TIMESLICES,
  TOGGLE_MONTH,
  TOGGLE_ALL_MONTH,
  CHANGE_FILTERS_DATERANGE,
  SET_RANGE,
  TOGGLE_INITIAL_LOADING,
  LOADING_NBHD,
  SET_LAODING_TILE,
  SET_LOADING_TIMESTAMP,
  CHANGE_MINMAX_DATERANGE,
  SET_NEIGHBORHOODS_TIMESTAMP,
  SET_TIMEZONE,
  SET_CRIME_FILTERS,
  SET_VIEWPORT_BOUNDING_BOX,
  SET_LOCATION_SETTINGS,
  SET_LOCATION_FILTERS,
  SET_FILTERS_LOADING,
  TOGGLE_QUARTER,
  TOGGLE_ALL_QUARTERS,
  SET_GOOGLE_VALUE,
  SET_INPUT_VALUE,
  SET_SUGGESTIONS,
  SET_SEARCH_VALUE,
  SET_SEARCH_RESULT,
  TOGGLE_SOURCE,
  TOGGLE_ALL_SOURCES,
  SET_SOURCES,
  SET_ACTIVE_CITY,
  UPDATE_FILTERS,
  SET_LOCATION_LOADING,
  SET_GRANULARITY,
  SET_MAP_LAYERS,
  SET_GTM_STATUS,
} from '@/store/modules/filters/actionTypes';
import {RESET_STORE} from '@/store/modules/actionTypes';
import {
  toMoment,
  isOnlyYearsSparseDates,
  isSparseDatesWithMonths,
  isSparseDatesQuarterly,
} from '@/utils/datesUtils';
import {arrayToFilter} from '@/utils/utils';

const state_sources = {
  1: true,
  2: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  19: true,
  20: true,
  21: true,
  22: true,
};

const state_categories = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
  13: true,
  14: true,
  15: true,
  16: true,
  17: true,
  18: true,
  19: true,
  20: true,
  21: true,
  22: true,
  23: true,
  24: true,
  25: true,
  26: true,
  27: true,
  28: true,
  29: true,
  30: true,
  31: true,
  32: true,
  33: true,
  34: true,
  35: true,
  36: true,
  37: true,
  38: true,
  39: true,
  40: true,
  41: true,
  42: true,
  43: true,
  44: true,
  45: true,
  46: true,
  47: true,
  48: true,
  49: true,
  50: true,
  51: true,
  52: true,
  53: true,
  54: true,
};

const state_timeslices = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
};

const state_seasons = {
  fall: true,
  summer: true,
  winter: true,
  spring: true,
};

const state_months = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
};

const state_quarters = {
  1: true,
  2: true,
  3: true,
  4: true,
};

const map_layers = {
  heatmap: false,
  events: false,
  districts: false,
  scores: false,
  population: false,
  countries: true,
  change: false,
};

// initial state
export const initialState = {
  activeCity: null,
  sources: state_sources,
  categories: state_categories,
  timeslices: state_timeslices,
  seasons: state_seasons,
  months: state_months,
  quarters: state_quarters,
  layers: map_layers,
  minDate: toMoment(0),
  maxDate: toMoment(),
  fromDate: toMoment().subtract(1, 'years'),
  toDate: toMoment(),
  range: [0, 5],
  granularity: 'radius',
  isGTM: false,
  prevFilters: {
    activeCity: null,
    sources: state_sources,
    categories: state_categories,
    timeslices: state_timeslices,
    seasons: state_seasons,
    months: state_months,
    quarters: state_quarters,
    layers: map_layers,
    minDate: toMoment(0),
    maxDate: toMoment(),
    fromDate: toMoment().subtract(1, 'years'),
    toDate: toMoment(),
    range: [0, 5],
    locationFilters: {},
  },

  // to loading states or refactor implementation into several hooks
  loading: true,
  loadingNeighborhoods: false,
  styleLoading: false,
  loadingTile: false,
  loadingNeighborhoodsTimestamp: null,
  loadingTimestamp: null,
  loadingTilesetTimestamp: null,

  // to map
  viewportBoundingBox: {},
  selectedNeighborhood: {},

  // to summary
  filtersLoading: false,
  locationFilters: {},

  // to location
  locationLoading: false,
  locationSettings: {},

  // to review
  timezone: '',

  // no need to exists
  sparseDates: false,
  sparseDateType: 1,
  sparseTimes: false,

  // no need to be here but implementation needs improve
  searchResult: {},
  googleValue: null,
  inputValue: '',
  suggestions: [],
  searchValue: '',
};

const toggleMapLayers = (option) => {
  const layers = {...map_layers};
  for (const layer of Object.keys(layers)) {
    layers[layer] = false;
  }
  layers[option] = true;
  return layers;
};

// reducer
const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GTM_STATUS:
      return {
        ...state,
        isGTM: action.payload ?? !state.isGTM,
      };
    case SET_GRANULARITY:
      return {
        ...state,
        granularity: action.payload,
      };
    case SET_LOCATION_LOADING:
      return {
        ...state,
        locationLoading: action.payload,
      };
    case SET_GOOGLE_VALUE:
      return {
        ...state,
        googleValue: action.payload || null,
      };
    case SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload || '',
      };
    case SET_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.payload || [],
      };
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload || '',
      };
    case SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload || {},
      };
    case SET_ACTIVE_CITY: {
      let config = {};
      const newLocation = action.payload;
      const {activeCity: currentLocation} = state;

      if (currentLocation !== newLocation) {
        config = {
          locationSettings: {},
          locationFilters: {},
          sources: state_sources,
          categories: state_categories,
          timeslices: state_timeslices,
          seasons: state_seasons,
          months: state_months,
          quarters: state_quarters,
          minDate: toMoment(0),
          maxDate: toMoment(),
          fromDate: toMoment().subtract(1, 'years'),
          toDate: toMoment(),
          range: [0, 5],
        };
      }
      return {
        ...state,
        ...config,
        activeCity: newLocation,
        prevFilters: {
          ...state.prevFilters,
          activeCity: currentLocation,
        },
      };
    }
    case SET_FILTERS_LOADING:
      return {
        ...state,
        filtersLoading: action.payload,
      };
    case SET_LOCATION_FILTERS:
      return {
        ...state,
        locationFilters: action.payload,
        prevFilters: {
          ...state.prevFilters,
          locationFilters: state.locationFilters,
        },
      };
    case SET_LOCATION_SETTINGS:
      return {
        ...state,
        locationSettings: action.payload,
      };
    case SET_VIEWPORT_BOUNDING_BOX:
      return {
        ...state,
        viewportBoundingBox: action.payload,
      };
    case SET_CRIME_FILTERS: {
      const {
        activeCity,
        locationSettings: location,
        locationFilters: summary,
        prevFilters: {activeCity: prevActiveCity, locationFilters: prevSummary},
      } = state;

      let timezone;
      let sparseDates;
      let sparseDateType;
      let sparseTimes;
      let hasLatLonData;
      let hasNeighborhoodData;
      let hasThreatScore;
      let minDate;
      let maxDate;
      let fromDate;
      let toDate;
      let layers;
      let sourceThreatCategories;

      if (!isEmpty(summary)) {
        sourceThreatCategories = summary.sourceThreatCategories;
        minDate = summary.minDate;
        maxDate = summary.maxDate;
        sparseDates = summary.isSparseDates;
        sparseDateType = summary.sparseDatetimeType;
        sparseTimes = summary.isSparseTimes;
        hasLatLonData = summary.hasLatLonData;
        hasNeighborhoodData = summary.hasNeighborhoodData;
        hasThreatScore = summary.hasThreatScore;
        timezone = location.timezone || state.timezone;
      } else {
        sourceThreatCategories = [];
        minDate = 0;
        maxDate = undefined;
        sparseDates = false;
        sparseDateType = 1;
        sparseTimes = false;
        hasLatLonData = true;
        hasNeighborhoodData = false;
        hasThreatScore = false;
        timezone = state.timezone;
      }

      minDate = toMoment(minDate, timezone);
      maxDate = toMoment(maxDate, timezone);
      const currentDate = toMoment(undefined, timezone);
      const sameSources = isEqual(
        Object.keys(state.sources)?.map(Number).sort(),
        sourceThreatCategories
          ?.map(({sourceCategoryId}) => sourceCategoryId)
          .sort(),
      );

      const setNewDates = () => {
        if (sparseDates) {
          if (isOnlyYearsSparseDates(sparseDateType)) {
            minDate = minDate.clone().startOf('year');
            maxDate = maxDate.clone().endOf('year');
            fromDate = minDate.clone();
          } else if (isSparseDatesWithMonths(sparseDateType)) {
            minDate = minDate.clone().startOf('month');
            maxDate = maxDate.clone().endOf('month');
            fromDate = maxDate.clone().subtract(1, 'years').startOf('month');
          } else if (isSparseDatesQuarterly(sparseDateType)) {
            minDate = minDate.clone().startOf('year');
            maxDate = maxDate.clone().endOf('year');
            fromDate = maxDate.clone().startOf('year');
          } else {
            fromDate = maxDate.clone().subtract(1, 'years');
          }
        } else {
          fromDate = maxDate.clone().subtract(1, 'years');
        }

        if (maxDate > currentDate) {
          maxDate = currentDate.clone();
        }

        if (minDate > fromDate) {
          fromDate = minDate.clone();
        }

        toDate = maxDate.clone();
      };

      if (activeCity) {
        if (activeCity !== prevActiveCity) {
          setNewDates();
        } else {
          fromDate = state.fromDate.clone();
          toDate = state.toDate.clone();

          if (maxDate > currentDate) {
            maxDate = currentDate.clone();
          }

          if (minDate > fromDate) {
            fromDate = minDate.clone();
          }

          if (maxDate < toDate) {
            toDate = maxDate.clone();
          }

          if (fromDate > toDate) {
            fromDate = toDate.clone();
          }
        }
      } else if (isEqual(prevSummary, {})) {
        setNewDates();
      } else {
        fromDate = state.fromDate.clone();
        toDate = state.toDate.clone();

        if (!(fromDate >= minDate && fromDate < maxDate)) {
          fromDate = minDate.clone();
        }

        if (!(toDate > minDate && toDate <= maxDate)) {
          toDate = maxDate.clone();
        }
      }

      const categories = sourceThreatCategories?.reduce(
        (categoryState, {threatCategoryIds}) => {
          threatCategoryIds.forEach((categoryId) => {
            if (!(categoryId in categoryState)) {
              if (sameSources) {
                categoryState[categoryId] = state.categories[categoryId];
              } else if (
                (activeCity === prevActiveCity && !activeCity) ||
                (activeCity !== prevActiveCity && activeCity) ||
                (!isEmpty(summary) && !activeCity)
              ) {
                categoryState[categoryId] = true;
              } else {
                categoryState[categoryId] = false;
              }
            }
          });
          return categoryState;
        },
        {},
      );

      const sources = sourceThreatCategories?.reduce(
        (sourceState, {sourceCategoryId}) => {
          if (sameSources) {
            sourceState[sourceCategoryId] = state.sources[sourceCategoryId];
          } else if (
            (activeCity !== prevActiveCity && activeCity) ||
            (activeCity === prevActiveCity && !activeCity) ||
            (!isEmpty(summary) && !activeCity)
          ) {
            sourceState[sourceCategoryId] = true;
          } else {
            sourceState[sourceCategoryId] = false;
          }
          return sourceState;
        },
        {},
      );

      if (
        (activeCity ||
          (activeCity === prevActiveCity && !activeCity && !prevActiveCity)) &&
        state.layers.countries
      ) {
        if (hasLatLonData) {
          layers = toggleMapLayers('heatmap');
        } else if (hasNeighborhoodData) {
          layers = toggleMapLayers('districts');
        } else if (hasThreatScore) {
          layers = toggleMapLayers('scores');
        } else {
          layers = toggleMapLayers('population');
        }
      } else if (!hasNeighborhoodData && state.layers.districts) {
        if (hasLatLonData) {
          layers = toggleMapLayers('heatmap');
        } else if (hasThreatScore) {
          layers = toggleMapLayers('scores');
        } else {
          layers = toggleMapLayers('population');
        }
      } else if (
        !hasLatLonData &&
        (state.layers.heatmap || state.layers.events)
      ) {
        if (hasNeighborhoodData) {
          layers = toggleMapLayers('districts');
        } else if (hasThreatScore) {
          layers = toggleMapLayers('scores');
        } else {
          layers = toggleMapLayers('population');
        }
      } else if (!hasThreatScore && state.layers.scores) {
        if (hasLatLonData) {
          layers = toggleMapLayers('heatmap');
        } else if (hasNeighborhoodData) {
          layers = toggleMapLayers('districts');
        } else {
          layers = toggleMapLayers('population');
        }
      } else if (!hasNeighborhoodData && !hasLatLonData && !hasThreatScore) {
        layers = toggleMapLayers('population');
      } else {
        layers = {...state.layers};
      }

      return {
        ...state,
        sources,
        categories,
        minDate,
        maxDate,
        fromDate,
        toDate,
        sparseDates,
        sparseDateType,
        sparseTimes,
        layers,
        prevFilters: {
          ...state.prevFilters,
          sources: state.sources,
          categories: state.categories,
          minDate: state.minDate,
          maxDate: state.maxDate,
          fromDate: state.fromDate,
          toDate: state.toDate,
          sparseDates: state.sparseDates,
          sparseDateType: state.sparseDateType,
          sparseTimes: state.sparseTimes,
          layers: state.layers,
        },
      };
    }

    case UPDATE_FILTERS: {
      const {filters} = action;
      return {
        ...state,
        sources: arrayToFilter(filters.sources),
        categories: arrayToFilter(filters.categories),
        months: arrayToFilter(filters.months),
        timeslices: arrayToFilter(filters.daytimes),
        minDate: toMoment(filters.fromDate),
        maxDate: toMoment(filters.toDate),
        fromDate: toMoment(filters.fromDate),
        toDate: toMoment(filters.toDate),
        prevFilters: {
          ...state.prevFilters,
          sources: state.sources,
          categories: state.categories,
          months: state.months,
          timeslices: state.timeslices,
          minDate: state.minDate,
          maxDate: state.maxDate,
          fromDate: state.fromDate,
          toDate: state.toDate,
        },
      };
    }
    case SET_TIMEZONE:
      return {
        ...state,
        timezone: action.timezone,
      };
    case SET_RANGE:
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          range: state.range,
        },
        range: action.range,
      };
    case SET_SOURCES:
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          sources: state.sources,
        },
        sources: Object.keys(state.sources)
          .map(Number)
          .reduce((obj, source) => {
            obj[source] = action.sources.includes(source);
            return obj;
          }, {}),
      };
    case TOGGLE_SOURCE: {
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          sources: state.sources,
        },
        sources: {
          ...state.sources,
          [action.payload]: !state.sources[action.payload],
        },
      };
    }
    case TOGGLE_ALL_SOURCES: {
      const sources = {...state.sources};
      const allSourcesSelected = Object.values(sources).every(Boolean);

      for (const source of Object.keys(sources)) {
        sources[source] = !allSourcesSelected;
      }

      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          sources: state.sources,
        },
        sources,
      };
    }
    case SET_CATEGORIES:
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          categories: state.categories,
        },
        categories: Object.keys(state.categories)
          .map(Number)
          .reduce((obj, category) => {
            obj[category] = action.categories.includes(category);
            return obj;
          }, {}),
      };
    case TOGGLE_CATEGORY:
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          categories: state.categories,
        },
        categories: {
          ...state.categories,
          [action.category]: !state.categories[action.category],
        },
      };
    case TOGGLE_ALL_CATEGORIES: {
      const categories = {...state.categories};
      const allCategoriesSelected = Object.values(categories).every(Boolean);

      for (const category of Object.keys(categories)) {
        categories[category] = !allCategoriesSelected;
      }

      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          categories: state.categories,
        },
        categories,
      };
    }
    case TOGGLE_TIMESLICE:
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          timeslices: state.timeslices,
        },
        timeslices: {
          ...state.timeslices,
          [action.timeslice]: !state.timeslices[action.timeslice],
        },
      };
    case TOGGLE_ALL_TIMESLICES: {
      const timeslices = {...state.timeslices};
      const allTimeslicesSelected = Object.values(timeslices).every(Boolean);

      for (const timeslice of Object.keys(timeslices)) {
        timeslices[timeslice] = !allTimeslicesSelected;
      }

      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          timeslices: state.timeslices,
        },
        timeslices,
      };
    }
    case SET_MAP_LAYERS: {
      return {
        ...state,
        layers: toggleMapLayers(action.payload),
        prevFilters: {
          ...state.prevFilters,
          layers: state.layers,
        },
      };
    }
    case TOGGLE_MONTH:
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          months: state.months,
        },
        months: {
          ...state.months,
          [action.month]: !state.months[action.month],
        },
      };
    case TOGGLE_ALL_MONTH: {
      const months = {...state.months};
      const allMonthsSelected = Object.values(months).every(Boolean);

      for (const month of Object.keys(months)) {
        months[month] = !allMonthsSelected;
      }

      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          months: state.months,
        },
        months,
      };
    }
    case TOGGLE_QUARTER: {
      const {quarters} = state;
      const quarter = action.payload;
      let monthsInQuarter = [];
      switch (quarter) {
        case '1':
          monthsInQuarter = [1, 2, 3];
          break;
        case '2':
          monthsInQuarter = [4, 5, 6];
          break;
        case '3':
          monthsInQuarter = [7, 8, 9];
          break;
        case '4':
          monthsInQuarter = [10, 11, 12];
          break;
        default:
          monthsInQuarter = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          break;
      }
      const months = monthsInQuarter.reduce(
        // eslint-disable-next-line no-return-assign, no-sequences
        (acc, curr) => ((acc[curr] = !quarters[quarter]), acc),
        {},
      );
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          quarters,
          months: state.months,
        },
        quarters: {
          ...quarters,
          [quarter]: !quarters[quarter],
        },
        months: {
          ...state.months,
          ...months,
        },
      };
    }
    case TOGGLE_ALL_QUARTERS: {
      const quarters = {...state.quarters};
      const allQuarterSelected = Object.values(quarters).every(Boolean);

      for (const quarter of Object.keys(quarters)) {
        quarters[quarter] = !allQuarterSelected;
      }

      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          quarters: state.quarters,
        },
        quarters,
      };
    }
    case CHANGE_FILTERS_DATERANGE:
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          fromDate: state.fromDate,
          toDate: state.toDate,
        },
        fromDate: action.fromDate,
        toDate: action.toDate,
      };

    case CHANGE_MINMAX_DATERANGE:
      return {
        ...state,
        prevFilters: {
          ...state.prevFilters,
          maxDate: state.maxDate,
          minDate: state.minDate,
        },
        maxDate: action.maxDate,
        minDate: action.minDate,
      };
    case TOGGLE_INITIAL_LOADING:
      return {
        ...state,
        loading: action.state !== null ? action.state : !state.loading,
      };
    case LOADING_NBHD:
      return {
        ...state,
        loadingNeighborhoods:
          action.loading !== null
            ? action.loading
            : !state.loadingNeighborhoods,
      };
    case SET_LAODING_TILE:
      return {
        ...state,
        loadingTile:
          action.loading !== null ? action.loading : !state.loadingTile,
      };
    case SET_LOADING_TIMESTAMP:
      return {
        ...state,
        loadingTimestamp: action.timestamp,
        loadingTilesetTimestamp: action.tilesetTimestap,
      };
    case SET_NEIGHBORHOODS_TIMESTAMP:
      return {
        ...state,
        loadingNeighborhoodsTimestamp: action.timestamp,
      };
    case RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default filtersReducer;
